import { FeedbackBanner, FeedbackBannerEnum } from '~/components/feedback-banner/feedback-banner';
import { useTranslation } from '~/translates/use-translate';
import { autoSingleStyle } from '~/utils/auto-single-style';

import {
  Container,
  PrimaryButton,
  Row,
  Section,
  SectionTitle,
  SectionWrapper,
  SkeletonBanner,
  SkeletonField,
  Subtitle,
  SubtitleBold,
  TertiaryButton,
  TextField,
} from './quantity.styles';

type SectionType = 'date' | 'quantity';

interface CardState {
  quantity: string;
}

interface ErrorCardState {
  quantity?: string;
}

interface INewCardsQuantity {
  card: CardState;
  error: ErrorCardState;
  year?: number;
  isLoadingExpirationDate: boolean;
  isButtonDisabled: boolean;
  expirationDate?: string;
  handleData: (name: string, value: string) => void;
  onSubmit: () => void;
  onCancel: () => void;
}

export const NewCardsQuantitySection = ({
  card,
  error,
  year,
  isButtonDisabled,
  isLoadingExpirationDate,
  expirationDate,
  handleData,
  onSubmit,
  onCancel,
}: INewCardsQuantity) => {
  const translate = useTranslation('pages.cards.newCards.tabs.quantity');

  const renderSection = (type: SectionType) => {
    if (type === 'date')
      return isLoadingExpirationDate ? (
        <Section>
          <SkeletonField />
          <SkeletonBanner />
        </Section>
      ) : (
        <Section>
          <SectionTitle>
            {translate('fields.date.label', {
              date: new Date(String(expirationDate)).toLocaleDateString('pt-BR', {
                month: '2-digit',
                year: 'numeric',
              }),
            })}
          </SectionTitle>
          <FeedbackBanner feedbackType={FeedbackBannerEnum.FULL}>
            <p>{autoSingleStyle(translate('banner', { year: String(year) }), '*', Subtitle, SubtitleBold)}</p>
          </FeedbackBanner>
        </Section>
      );

    return (
      <Section>
        <SectionTitle>{translate(`fields.${type}.section`)}</SectionTitle>
        <TextField
          label={translate(`fields.${type}.label`)}
          onChange={(value) => handleData(type, value)}
          onClearTextField={() => handleData(type, '')}
          value={card?.[type]}
          placeholder={translate(`fields.${type}.placeholder`)}
          assistiveText={translate(`fields.${type}.assistiveText`)}
          error={error?.[type]}
        />
      </Section>
    );
  };

  const renderButton = () => {
    return (
      <Row>
        <TertiaryButton label={translate('button.cancel')} onClick={onCancel} />
        <PrimaryButton label={translate('button.next')} onClick={onSubmit} isDisabled={isButtonDisabled} />
      </Row>
    );
  };

  return (
    <Container>
      <SectionWrapper>
        {renderSection('quantity')}
        {renderSection('date')}
      </SectionWrapper>
      {renderButton()}
    </Container>
  );
};
