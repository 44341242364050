import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { TemplateEmailEnum } from '~/api/constants';
import {
  useUserServiceUserControllerCreate,
  useUserServiceUserControllerDelete,
  useUserServiceUserControllerGetAllUsers,
  useUserServiceUserControllerResendEmail,
} from '~/api/queries';
import { CreateUserRequestDto, ScopeEnum as ScopesType, UserResponseDto } from '~/api/requests';
import { useOnReachEnd } from '~/hooks/use-reach-end';
import { useUserScopes } from '~/hooks/use-user-scopes';
import { setSuccess } from '~/redux/reducers/application';
import { SuccessEnum } from '~/translates/success/types';
import { unformatDocument } from '~/utils/unformat-document';

import userSchema from './permissions.schema';

type ActionType = 'resend' | 'delete';

interface UserError {
  name?: string;
  document?: string;
  email?: string;
}

interface IPermissionsHook {
  addUserMode: boolean;
  scopes: ScopesType[];
  user: CreateUserRequestDto;
  errors: UserError;
  data?: UserResponseDto[];
  isButtonDisabled: boolean;
  isLoading: boolean;
  onReachEndLoading: boolean;
  onCreateUserLoading: boolean;
  handleSubmit: () => void;
  handleData: (name: string, value: string) => void;
  toggleAddUser: () => void;
  onAction: (type: ActionType, value: string) => void;
  isDeleting: boolean;
  isUserDeleted: boolean;
}

const USERS_PER_PAGE = 10;

const INITIAL_USER_STATE: CreateUserRequestDto = {
  name: '',
  document: '',
  email: '',
  role: 'viewer',
};

const INITIAL_USER_ERROR_STATE: UserError = {
  name: '',
  document: '',
  email: '',
};

const INITIAL_STATE = {
  onReachEndLoading: false,
  addUserMode: false,
  enabled: true,
  permissions: [] as UserResponseDto[],
  page: 1,
  user: INITIAL_USER_STATE,
  errors: INITIAL_USER_ERROR_STATE,
};

export const usePermissions = (): IPermissionsHook => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { scopes } = useUserScopes();

  const [state, setState] = useState(INITIAL_STATE);

  const {
    data: usersData,
    refetch: refetchUser,
    isFetching,
    isPending,
    isLoading,
  } = useUserServiceUserControllerGetAllUsers(
    { page: state.page, perPage: USERS_PER_PAGE },
    ['listUsers', state.page],
    { enabled: state.enabled, staleTime: 0 }
  );

  const resendInvitation = useUserServiceUserControllerResendEmail({
    onSuccess: () => dispatch(setSuccess(SuccessEnum.RESEND_INVITE)),
  });

  const resetToInitialState = () => {
    setState(INITIAL_STATE);
    queryClient.invalidateQueries({ queryKey: ['listUsers'] });
    refetchUser();
  };

  const createUser = useUserServiceUserControllerCreate({
    onSuccess: () => {
      toggleAddUser();
      dispatch(setSuccess(SuccessEnum.ACCESS_CREATED));
    },
  });

  const deleteUser = useUserServiceUserControllerDelete({
    onSuccess: () => {
      dispatch(setSuccess(SuccessEnum.ACCESS_REMOVED));
    },
  });

  const handleData = (name: string, value: string) => {
    setState((prev) => ({ ...prev, user: { ...prev.user, [name]: value } }));
  };

  const handleSubmit = () => {
    const { document, email, name, role } = state.user;
    createUser.mutate(
      {
        requestBody: { name, email, document: unformatDocument(document), role },
      },
      {
        onSuccess: () => {
          resetToInitialState();
        },
      }
    );
  };

  const onAction = (type: ActionType, value: string) => {
    if (type === 'resend')
      resendInvitation.mutate({
        requestBody: { email: value, templateEmail: TemplateEmailEnum.FIRST_ACCESS },
      });
    if (type === 'delete') {
      deleteUser.mutate(
        { id: value },
        {
          onSuccess: () => {
            resetToInitialState();
          },
        }
      );
    }
  };

  const toggleAddUser = () => {
    setState((prev) => ({
      ...prev,
      addUserMode: !prev.addUserMode,
      user: INITIAL_USER_STATE,
      errors: INITIAL_USER_ERROR_STATE,
    }));
  };

  const isButtonDisabled = () => {
    return Object.values(state.errors).some((val) => val) || Object.values(state.user).some((val) => !val);
  };

  useEffect(() => {
    if (usersData) {
      const users = usersData.data;

      setState((prev) => {
        let updatedPermissions = users;

        if (prev.page !== 1) {
          updatedPermissions = [
            ...prev.permissions.filter(
              (existingUser) => !users.some((newUser) => newUser.id === existingUser.id)
            ),
            ...users,
          ];
        }

        return {
          ...prev,
          permissions: updatedPermissions,
          onReachEndLoading: false,
          enabled: users.length >= USERS_PER_PAGE,
          deletedUserId: '',
        };
      });
    }
  }, [usersData, state.page, deleteUser.isSuccess, createUser.isSuccess]);

  useOnReachEnd(() => {
    if (!state.onReachEndLoading && !isLoading && state.permissions.length > 0 && state.enabled) {
      setState((prev) => ({ ...prev, onReachEndLoading: true, page: prev.page + 1 }));
    }
  });

  useEffect(() => {
    const validation = userSchema.safeParse(state.user);
    if (!validation.success) {
      const { email, document, name } = validation.error.flatten().fieldErrors;
      setState((prev) => ({
        ...prev,
        errors: {
          name: state.user.name ? name?.[0] : '',
          email: state.user.email && email?.[0],
          document: state.user.document && document?.[0],
        },
      }));
    } else {
      setState((prev) => ({ ...prev, errors: INITIAL_USER_ERROR_STATE }));
    }
  }, [state.user]);

  return {
    scopes,
    addUserMode: state.addUserMode,
    user: state.user,
    errors: state.errors,
    data: state.permissions,
    isLoading: (isFetching || isPending) && state.page === 1 && state.enabled,
    onReachEndLoading: state.onReachEndLoading,
    onCreateUserLoading: createUser.isPending,
    isButtonDisabled: isButtonDisabled(),
    handleData,
    toggleAddUser,
    handleSubmit,
    onAction,
    isDeleting: deleteUser.isPending,
    isUserDeleted: deleteUser.isSuccess,
  };
};
