import { IconographyNames } from '@vizir-banking/design-system';
import { TableBody, TableHead, TagSizes, TagTypes, TagVariants } from '@vizir-banking/design-system/web';
import { ReactElement } from 'react';

import { OrderStatusEnum, ScopeEnum } from '~/api/constants';
import { Order, OrderStatusEnum as OrderStatusEnumType } from '~/api/requests';
import { Dropdown, IOptions } from '~/components/dropdown/dropdown';
import { DropdownProvider } from '~/components/dropdown/dropdown-context';
import { useUserScopes } from '~/hooks/use-user-scopes';
import { RechargeSteps } from '~/pages/recharge/types';
import { useTranslation } from '~/translates/use-translate';
import { dateFormatter } from '~/utils/format-date';

import {
  CalendarIcon,
  Row,
  Skeleton,
  TableBodyCell,
  TableBodyRow,
  TableBodyTagCell,
  TableContainer,
  TableHeadCell,
  TableHeadRow,
} from './recharge-table.styles';

interface IRechargeTable {
  recharges: Order[];
  isLoading: boolean;
  isOnEndLoading: boolean;
  onChangeStep?: (step: RechargeSteps) => void;
  onSelectOrder?: (order: Order) => void;
  onToggleReleaseOrderModal?: () => void;
}

export const RechargeTable = ({
  recharges,
  isOnEndLoading,
  isLoading,
  onChangeStep,
  onToggleReleaseOrderModal,
  onSelectOrder,
}: IRechargeTable): ReactElement => {
  const translate = useTranslation('components.table.recharge');
  const { hasScope } = useUserScopes();

  const tagProps = (status: OrderStatusEnumType) => {
    const tagTypes = {
      [OrderStatusEnum.PROCESSED]: TagTypes.success,
      [OrderStatusEnum.PARTIAL_PROCESSED]: TagTypes.success,
      [OrderStatusEnum.READY_TO_RELEASE]: TagTypes.info,
      [OrderStatusEnum.PENDING_PAYMENT]: TagTypes.warning,
      [OrderStatusEnum.CANCELED]: TagTypes.negative,
      [OrderStatusEnum.FAILED]: TagTypes.neutral,
      [OrderStatusEnum.PROCESSING]: TagTypes.warning,
      [OrderStatusEnum.PROCESSING_SPREADSHEET]: TagTypes.warning,
      [OrderStatusEnum.SPREADSHEET_FAILURE]: TagTypes.neutral,
      [OrderStatusEnum.UNKNOWN]: TagTypes.neutral,
    };

    const tagsProps = {
      size: TagSizes.large,
      type: tagTypes[status],
      variant: TagVariants.primary,
    };

    return tagsProps;
  };

  const tagMessage = {
    [OrderStatusEnum.PROCESSED]: translate('status.approved'),
    [OrderStatusEnum.PARTIAL_PROCESSED]: translate('status.partial'),
    [OrderStatusEnum.READY_TO_RELEASE]: translate('status.ready'),
    [OrderStatusEnum.PENDING_PAYMENT]: translate('status.pending'),
    [OrderStatusEnum.CANCELED]: translate('status.canceled'),
    [OrderStatusEnum.FAILED]: translate('status.failed'),
    [OrderStatusEnum.PROCESSING]: translate('status.processing'),
    [OrderStatusEnum.PROCESSING_SPREADSHEET]: translate('status.processingSpreadsheet'),
    [OrderStatusEnum.SPREADSHEET_FAILURE]: translate('status.spreadsheetFailure'),
    [OrderStatusEnum.UNKNOWN]: translate('status.unknown'),
  };

  const renderTableHeadRow = () => {
    return (
      <TableHeadRow>
        <TableHeadCell>{translate('table.status')}</TableHeadCell>
        <TableHeadCell>{translate('table.registerDate')}</TableHeadCell>
        <TableHeadCell>{translate('table.amount')}</TableHeadCell>
        {onChangeStep && <TableHeadCell>{translate('table.actions.title')}</TableHeadCell>}
      </TableHeadRow>
    );
  };

  const renderTableBodyRows = (order: Order) => {
    const options: IOptions[] = [];

    if (hasScope(ScopeEnum.VIEW_DETAIL_ORDER))
      options.push({
        label: translate('table.actions.viewDetails'),
        onAction: () => {
          onSelectOrder && onSelectOrder(order);
          onChangeStep && onChangeStep(RechargeSteps.RECHARGE_DETAILS);
        },
      });

    if (order.status === OrderStatusEnum.READY_TO_RELEASE && hasScope(ScopeEnum.SPLIT_ORDER))
      options.push({
        label: translate('table.actions.releaseOrder'),
        onAction: () => {
          onSelectOrder && onSelectOrder(order);
          onToggleReleaseOrderModal && onToggleReleaseOrderModal();
        },
      });

    return (
      <TableBodyRow>
        <TableBodyTagCell
          tagProps={tagProps(order.status)}
          hasTrailingIcon={order.status === OrderStatusEnum.PARTIAL_PROCESSED}
          trailingIconName={IconographyNames.infoCircle}
          cell='status'
        >
          {tagMessage[order.status]}
        </TableBodyTagCell>
        <TableBodyCell cell='date'>
          <Row>
            <CalendarIcon />
            {dateFormatter(new Date(order.createdAt))}
          </Row>
        </TableBodyCell>
        <TableBodyCell cell='amount'>{order.amount}</TableBodyCell>
        {onChangeStep && (
          <TableBodyCell cell='actions'>
            <Dropdown options={options} dropdownId={order.id} />
          </TableBodyCell>
        )}
      </TableBodyRow>
    );
  };

  const renderSkeletonLoadingRows = () => {
    const renderSkeletonCell = () => {
      return (
        <TableBodyCell>
          <Skeleton />
        </TableBodyCell>
      );
    };

    const renderSkeletonRow = () => {
      return (
        <TableBodyRow>
          {renderSkeletonCell()}
          {renderSkeletonCell()}
          {renderSkeletonCell()}
          {onChangeStep && renderSkeletonCell()}
        </TableBodyRow>
      );
    };

    return Array.from({ length: 3 }).map((_) => renderSkeletonRow());
  };

  return (
    <DropdownProvider>
      <TableContainer>
        <TableHead>{renderTableHeadRow()}</TableHead>
        <TableBody>
          {isLoading
            ? renderSkeletonLoadingRows()
            : recharges.map((recharge) => renderTableBodyRows(recharge))}
          {isOnEndLoading && renderSkeletonLoadingRows()}
        </TableBody>
      </TableContainer>
    </DropdownProvider>
  );
};
