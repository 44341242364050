import { noop } from 'lodash';

import { IFileData } from '../recharge.hook';
import { RechargeSteps } from '../types';
import { useNewRecharge } from './new-recharge.hook';
import { NewRechargeLayout } from './new-recharge.layout';

interface INewRecharge {
  onChangeStep: (step: RechargeSteps) => void;
  onChangeFileData?: (data: IFileData) => void;
}

export const NewRechargePage = ({ onChangeStep, onChangeFileData }: INewRecharge) => {
  const props = useNewRecharge(onChangeFileData ?? noop);

  return <NewRechargeLayout {...props} onChangeStep={onChangeStep} />;
};
