import styled from 'styled-components';

import {
  LeadingContentType,
  PageHeader as BasePageHeader,
  TrailingContentType,
} from '~/components/page-header/page-header';

export const PageHeader = styled(BasePageHeader).attrs({
  leadingContentType: LeadingContentType.TEXT,
  trailingContentType: TrailingContentType.BUTTON,
})``;
