import { formatToCPF } from 'brazilian-values';
import { noop } from 'lodash';

import { AddressDto, EmployeeDto } from '~/api/requests';
import { FragmentCol, FragmentRow } from '~/styles/global-styles';
import { useTranslation } from '~/translates/use-translate';

import { CollaboratorsSteps } from '../types';
import {
  Box,
  BoxInfoLabel,
  BoxInfoValue,
  BoxInfoWrapper,
  BoxSkeleton,
  BoxTitle,
  BoxWrapper,
  ButtonRow,
  EditIcon,
  Link,
  PageHeader,
  PrimaryButton,
  Row,
  TertiaryButton,
  TextField,
  TextFieldWrapper,
} from './profile.styles';

type BoxType = 'contact' | 'address';

interface IContactFields {
  email?: string;
  phoneNumber?: string;
  [key: string]: string | undefined;
}

interface ICollaboratorProfile {
  user?: EmployeeDto;
  shouldUpdateCollaborator: boolean;
  isLoading: boolean;
  isUpdateLoading: boolean;
  isContactEditMode: boolean;
  isAddressEditMode: boolean;
  contact?: IContactFields;
  errors?: IContactFields;
  address: AddressDto;
  onChangeStep: (
    step: CollaboratorsSteps,
    personId?: string,
    collaboratorPosition?: {
      index: number;
      page: number;
    }
  ) => void;
  isButtonDisabled: (box: BoxType) => boolean;
  handleEdit: (box: BoxType) => void;
  handleData: (box: BoxType, name: string, value?: string) => void;
  onSubmit: (box: BoxType) => Promise<void>;
  collaboratorPosition: {
    index: number;
    page: number;
  };
}

export const CollaboratorProfileLayout = ({
  shouldUpdateCollaborator,
  address,
  contact,
  errors,
  user,
  isLoading,
  isUpdateLoading,
  isAddressEditMode,
  isContactEditMode,
  isButtonDisabled,
  handleData,
  handleEdit,
  onChangeStep,
  onSubmit,
  collaboratorPosition,
}: ICollaboratorProfile) => {
  const translate = useTranslation('pages.collaborators.profile');

  const renderBoxTitle = (box: string) => {
    return <BoxTitle>{translate(`${box}.title`)}</BoxTitle>;
  };

  const renderEditIcon = (onClick: () => void) => {
    if (shouldUpdateCollaborator) {
      return (
        <Link onClick={isLoading ? noop : onClick}>
          <EditIcon />
        </Link>
      );
    }
  };

  const renderTextField = (box: BoxType, name: string) => {
    const value = {
      contact: contact?.[name],
      // @ts-expect-error - name is AddressDto key
      address: address?.[name],
    };

    const maxLength: { [key: string]: number } = {
      phoneNumber: 16,
      zipCode: 9,
    };

    return (
      <TextFieldWrapper>
        <TextField
          label={translate(`${box}.fields.${name}`)}
          onChange={(value: string) => handleData(box, name, value)}
          onClearTextField={() => handleData(box, name, undefined)}
          value={value[box] ?? ''}
          error={errors?.[name]}
          maxLength={maxLength?.[name]}
        />
      </TextFieldWrapper>
    );
  };

  const renderButton = (box: BoxType, onCancel: () => void, onConfirm: () => Promise<void>) => {
    return (
      <ButtonRow>
        <TertiaryButton label='Cancelar' onClick={onCancel} />
        <PrimaryButton
          label='Confirmar'
          onClick={onConfirm}
          isLoading={isUpdateLoading}
          isDisabled={isButtonDisabled(box)}
        />
      </ButtonRow>
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const renderInfo = (box: string, object: any, type: string) => {
    let value = object?.[type];
    const isCPF = type === 'document';
    const isBirthDate = type === 'birthDate';

    if (isCPF) {
      value = formatToCPF(object?.[type] ?? '');
    }
    if (isBirthDate) {
      value = new Date(object?.[type]).toLocaleDateString('pt-BR');
    }

    return (
      <BoxInfoWrapper key={type}>
        {isLoading ? (
          <BoxSkeleton />
        ) : (
          <FragmentCol style={{ gap: 8 }}>
            <BoxInfoLabel>{translate(`${box}.fields.${type}`)}</BoxInfoLabel>
            <BoxInfoValue>{value ?? ''}</BoxInfoValue>
          </FragmentCol>
        )}
      </BoxInfoWrapper>
    );
  };

  const renderPersonalInfoBox = () => {
    const fields = [
      'name',
      'preferredName',
      'gender',
      'birthDate',
      'document',
      'maritalStatus',
      'motherName',
    ];

    return (
      <Box>
        {renderBoxTitle('personalInfo')}
        <BoxWrapper>{fields.map((field) => renderInfo('personalInfo', user, field))}</BoxWrapper>
      </Box>
    );
  };

  const renderContactBoxContent = () => {
    return (
      <BoxWrapper>
        {isContactEditMode ? (
          <FragmentRow>
            {renderTextField('contact', 'email')}
            {renderTextField('contact', 'phoneNumber')}
          </FragmentRow>
        ) : (
          <FragmentRow>
            {renderInfo('contact', user?.email, 'email')}
            {renderInfo('contact', user?.phone, 'phoneNumber')}
          </FragmentRow>
        )}
      </BoxWrapper>
    );
  };

  const renderAddressBoxContent = () => {
    const fields = ['zipCode', 'street', 'number', 'complement', 'neighborhood', 'city', 'state', 'country'];

    return (
      <BoxWrapper>
        {fields.map((field) =>
          isAddressEditMode ? renderTextField('address', field) : renderInfo('address', user?.address, field)
        )}
      </BoxWrapper>
    );
  };

  const renderContactBox = () => {
    const handleEditMode = () => handleEdit('contact');
    const onConfirm = async () => onSubmit('contact');

    return (
      <Box>
        <Row>
          {renderBoxTitle('contact')}
          {renderEditIcon(handleEditMode)}
        </Row>
        {renderContactBoxContent()}
        {isContactEditMode && renderButton('contact', handleEditMode, onConfirm)}
      </Box>
    );
  };

  const renderAddressBox = () => {
    const handleEditMode = () => handleEdit('address');
    const onConfirm = async () => onSubmit('address');

    return (
      <Box>
        <Row>
          {renderBoxTitle('address')}
          {renderEditIcon(handleEditMode)}
        </Row>
        {renderAddressBoxContent()}
        {isAddressEditMode && renderButton('address', handleEditMode, onConfirm)}
      </Box>
    );
  };

  return (
    <FragmentCol>
      <PageHeader
        isLoading={isLoading}
        title={user?.name ?? ''}
        leadingGoBack={() => {
          onChangeStep(CollaboratorsSteps.VIEW, undefined);
          setTimeout(() => {
            window.scrollTo({
              top: collaboratorPosition.index * 73 + 380,
              behavior: 'smooth',
            });
          }, 100);
        }}
      />
      {renderPersonalInfoBox()}
      {renderContactBox()}
      {renderAddressBox()}
    </FragmentCol>
  );
};
