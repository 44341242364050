import { QueryClient, QueryClientProvider as ReactQueryClientProvider } from '@tanstack/react-query';
import { ReactElement } from 'react';
import { useDispatch } from 'react-redux';

import { ApiError } from '~/api/requests';
import { setError } from '~/redux/reducers/application';

import { Props } from './types';

const queryClient = new QueryClient();

export function QueryClientProvider({ children }: Props): ReactElement {
  const dispatch = useDispatch();

  queryClient.setDefaultOptions({
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
    mutations: {
      onError: (error) => {
        const e = error as ApiError;
        dispatch(setError(e?.body?.code ?? 'generic_error'));
      },
    },
  });

  return <ReactQueryClientProvider client={queryClient}>{children}</ReactQueryClientProvider>;
}
