import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { GetEmployerBasicInfoResponseDto, OpenAPI, UserMeResponseDto } from '~/api/requests';
import { SCREENS } from '~/app/routes/screen-definitions';
import { clearEmployer, selectEmployer } from '~/redux/reducers/employer';
import { clearCurrentUser, selectCurrentUser, selectToken } from '~/redux/reducers/user';

import { SideBarTabs } from '../side-bar/types';

interface Dropdown {
  collaborators: boolean;
  settings: boolean;
}

interface IScreenHook {
  user?: UserMeResponseDto;
  employer?: GetEmployerBasicInfoResponseDto;
  dropdown: Dropdown;
  isActive: (tab: SideBarTabs) => boolean;
  navigateTo: (tab: SideBarTabs) => void;
  logout: () => Promise<void>;
  isMobile: boolean;
  isTablet: boolean;
}

export const useScreenHook = (): IScreenHook => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const queryClient = useQueryClient();

  const token = useSelector(selectToken);
  const user = useSelector(selectCurrentUser);
  const employer = useSelector(selectEmployer);

  const [currentTab, setCurrentTab] = useState<SideBarTabs>();
  const [windowDimensions, setWindowDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [dropdown, setDropdown] = useState({
    collaborators: false,
    settings: false,
  });

  useEffect(() => {
    if (token) {
      OpenAPI.HEADERS = {
        ...OpenAPI.HEADERS,
        authorization: `Bearer ${token}`,
      };
    }
  }, []);

  useEffect(() => {
    const currentPath = location.pathname;
    const matchingTab = Object.keys(SCREENS).find(
      (key) => SCREENS[key as SideBarTabs] === currentPath
    ) as SideBarTabs;
    if (matchingTab) {
      if (matchingTab === SideBarTabs.COLLABORATORS || matchingTab === SideBarTabs.LIST_BATCHES)
        setDropdown({ ...dropdown, collaborators: true });
      if (matchingTab === SideBarTabs.PERMISSIONS || matchingTab === SideBarTabs.BUSINESS_ADDRESS)
        setDropdown({ ...dropdown, settings: true });

      setCurrentTab(matchingTab);
    }
  }, [location]);

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const logout = async () => {
    OpenAPI.HEADERS = undefined;
    dispatch(clearCurrentUser());
    dispatch(clearEmployer());
    queryClient.removeQueries();

    navigate(SCREENS.login);
  };

  const isActive = (tab: SideBarTabs) => {
    return tab === currentTab;
  };

  const navigateTo = (tab: SideBarTabs) => {
    setCurrentTab(tab);
    navigate(SCREENS[tab]);
  };

  return {
    user,
    employer,
    dropdown,
    isActive,
    navigateTo,
    logout,
    isMobile: windowDimensions.width < 968,
    isTablet: windowDimensions.width > 768 && windowDimensions.width < 968,
  };
};
