import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

import { selectToken } from '~/redux/reducers/user';

import { SCREENS } from './screen-definitions';

export const ProtectedRoute = () => {
  const token = useSelector(selectToken);

  if (!token) {
    return <Navigate to={SCREENS.login} replace />;
  }

  return <Outlet />;
};
