import { ReactElement } from 'react';

import { Order, OrderDetails } from '~/api/requests';

import { RechargeSteps } from '../types';
import { UseRechargeDetails } from './recharge-details.hook';
import { RechargeDetailsLayout } from './recharge-details.layout';

interface IRechargeDetails {
  order: Order;
  onChangeStep: (step: RechargeSteps) => void;
  toggleReleaseOrderModal?: () => void;
  data: OrderDetails;
  isLoading: boolean;
}

export const RechargeDetails = ({
  order,
  onChangeStep,
  toggleReleaseOrderModal,
  data,
  isLoading,
}: IRechargeDetails): ReactElement => {
  const props = UseRechargeDetails(order.id);

  return (
    <RechargeDetailsLayout
      {...props}
      order={order}
      onChangeStep={onChangeStep}
      toggleReleaseOrderModal={toggleReleaseOrderModal}
      data={data}
      isLoading={isLoading}
    />
  );
};
