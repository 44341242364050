import { Order, OrderDetails } from '~/api/requests';

import { RechargeSteps } from '../types';
import { useRechargePaymentType } from './payment-type.hook';
import { RechargePaymentTypeLayout } from './payment-type.layout';

interface IPaymentType {
  onChangeStep: (step: RechargeSteps) => void;
  details: OrderDetails;
  order: Order;
}

export const RechargePaymentType = ({ onChangeStep, details, order }: IPaymentType) => {
  const properties = useRechargePaymentType({ onChangeStep });

  return <RechargePaymentTypeLayout {...properties} details={details} order={order} />;
};
