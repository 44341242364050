import { ReactElement } from 'react';

import { useLogin } from './login.hook';
import { LoginLayout } from './login.layout';

export const LoginPage = (): ReactElement => {
  const props = useLogin();

  return <LoginLayout {...props} />;
};
