import '@vizir-banking/design-system/web/fonts/Nunito';
import '@vizir-banking/design-system/web/fonts/Roboto';

import { Fonts } from '@vizir-banking/design-system';

export const designSystemFonts: Fonts = {
  primary: {
    normal: {
      '400': 'Roboto-Light',
      '500': 'Roboto-Regular',
      '600': 'Roboto-Medium',
      '700': 'Roboto-Bold',
    },
    italic: {
      '400': 'Roboto-LightItalic',
      '500': 'Roboto-Italic',
      '600': 'Roboto-MediumItalic',
      '700': 'Roboto-BoldItalic',
    },
  },
  secondary: {
    normal: {
      '400': 'Nunito-Light',
      '500': 'Nunito-Regular',
      '600': 'Nunito-SemiBold',
      '700': 'Nunito-Bold',
    },
    italic: {
      '400': 'Nunito-LightItalic',
      '500': 'Nunito-Italic',
      '600': 'Nunito-SemiBoldItalic',
      '700': 'Nunito-BoldItalic',
    },
  },
};
