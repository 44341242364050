import { ScopeEnum } from '~/api/constants';
import { GetProposalBatches, ScopeEnum as ScopeType } from '~/api/requests';
import { BatchesTable } from '~/components/tables/batches-table/batches-table';
import { FragmentCol } from '~/styles/global-styles';
import { useTranslation } from '~/translates/use-translate';

import { PageHeader } from './batches-view.styles';

interface IBatchesViewLayout {
  goToAddCollaborators: () => void;
  scopes: ScopeType[];
  batches: GetProposalBatches[];
  isLoading: boolean;
  handleBatchClick: (id: string) => void;
  onReachEndLoading: boolean;
}

export const BatchesViewLayout = ({
  goToAddCollaborators,
  batches,
  isLoading,
  handleBatchClick,
  onReachEndLoading,
}: IBatchesViewLayout) => {
  const translate = useTranslation('pages.listBatches');

  return (
    <FragmentCol>
      <PageHeader
        leadingText={translate('title')}
        title={translate('title')}
        verifyScope={{
          scope: ScopeEnum.ADD_EMPLOYEE,
        }}
        trailingButton={{
          label: translate('addCollaborators'),
          onClick: goToAddCollaborators,
        }}
      />

      <BatchesTable
        batches={batches}
        handleBatchClick={handleBatchClick}
        isLoading={isLoading}
        onReachEndLoading={onReachEndLoading}
      />
    </FragmentCol>
  );
};
