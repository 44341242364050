import { noop } from 'lodash';

import { SimplifiedEmployees } from '~/api/requests';
import { CollaboratorTable } from '~/components/tables/collaborators-table/collaborator-table';
import { useTranslation } from '~/translates/use-translate';

import { CollaboratorsSteps } from '../types';
import { Container, ContentArea, PageHeader, Search } from './collaborators-view.styles';

interface ICollaboratorsLayout {
  document: string;
  shouldViewCollaboratorProfile: boolean;
  error: string;
  isLoading: boolean;
  onReachEndLoading: boolean;
  collaborators?: SimplifiedEmployees[];
  onChangeStep: (step: CollaboratorsSteps) => void;
  onSelectCollaborator: (id: string) => void;
  onChangeDocument: (value: string) => void;
}

export const CollaboratorsViewLayout = ({
  document,
  shouldViewCollaboratorProfile,
  error,
  isLoading,
  onReachEndLoading,
  collaborators,
  onSelectCollaborator,
  onChangeDocument,
}: ICollaboratorsLayout) => {
  const translate = useTranslation('pages.collaborators.view');

  return (
    <Container>
      <ContentArea>
        <PageHeader
          leadingText={translate('pageHeader.currentStep')}
          title={translate('pageHeader.subtitle')}
        />
        <Search
          onChange={onChangeDocument}
          onClear={() => onChangeDocument('')}
          value={document}
          placeholder='Buscar por CPF'
          onClickSearch={noop}
          error={error}
        />
        <CollaboratorTable
          isLoading={isLoading}
          onReachEndLoading={onReachEndLoading}
          collaborators={collaborators}
          onClick={shouldViewCollaboratorProfile ? onSelectCollaborator : undefined}
        />
      </ContentArea>
    </Container>
  );
};
