import { useEmployerServiceEmployerControllerGetAccountThatWillReceivePayments } from '~/api/queries';
import { EmployerControllerGetAccountThatWillReceivePaymentsResponse } from '~/api/requests';

import { RechargeSteps } from '../types';

interface IRechargePayment {
  info?: EmployerControllerGetAccountThatWillReceivePaymentsResponse;
  isLoading: boolean;
  goBackToOrders: () => void;
  goBack: () => void;
}

interface IRechargeSteps {
  onChangeStep: (step: RechargeSteps) => void;
}

export const useRechargePaymentHook = ({ onChangeStep }: IRechargeSteps): IRechargePayment => {
  const useAccount = useEmployerServiceEmployerControllerGetAccountThatWillReceivePayments();

  const goBackToOrders = () => onChangeStep(RechargeSteps.BALANCE);

  const goBack = () => onChangeStep(RechargeSteps.PAYMENT_TYPE);

  return {
    info: useAccount?.data,
    isLoading: useAccount.isPending,
    goBackToOrders,
    goBack,
  };
};
