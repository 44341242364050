import { ReactElement } from 'react';

import { useForgotPassword } from './forgot-password.hook';
import { ForgotPasswordLayout } from './forgot-password.layout';

export const ForgotPasswordPage = (): ReactElement => {
  const props = useForgotPassword();

  return <ForgotPasswordLayout {...props} />;
};
