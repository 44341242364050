import { IconographyNames } from '@vizir-banking/design-system/src/enums/iconography-names';
import { Link, TypographyVariants } from '@vizir-banking/design-system/web';
import { ReactElement } from 'react';

import { FragmentRow } from '~/styles/global-styles';

import { Icon, Label, Wrapper } from './notification.styles';

export enum NotificationLeadingContentType {
  ICON = 'icon',
  TEXT = 'text',
  ELEMENT = 'element',
}

export enum NotificationTrailingContentType {
  ICON = 'icon',
  CLOSE = 'close',
}

interface LabelProps {
  label?: string;
  labelColor?: string;
  variant?: TypographyVariants;
}

interface IconProps {
  iconName: IconographyNames;
  iconColor?: string;
  iconSize?: number;
}

interface NotificationProps {
  leadingContentType?: NotificationLeadingContentType;
  trailingContentType?: NotificationTrailingContentType;
  showLeadingIcon?: boolean;
  leadingIcon?: IconProps;
  leadingText?: LabelProps;
  leadingElement?: ReactElement;
  trailingIcon?: IconProps;
  trailingOnClose?: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  containerProps?: any;
}

export const Notification = ({
  containerProps,
  leadingContentType,
  showLeadingIcon = leadingContentType === NotificationLeadingContentType.ICON,
  leadingIcon,
  leadingText,
  leadingElement,
  trailingContentType,
  trailingIcon,
  trailingOnClose,
}: NotificationProps): ReactElement => {
  const renderLeading = () => {
    if (leadingContentType === NotificationLeadingContentType.ICON) {
      const { iconName, iconColor, iconSize } = leadingIcon ?? {};
      const { label, variant } = leadingText ?? {};

      return (
        <FragmentRow style={{ gap: 8 }}>
          {showLeadingIcon && <Icon iconName={iconName} color={iconColor} size={iconSize ?? 24} />}
          <Label color={iconColor} variant={variant}>
            {label}
          </Label>
        </FragmentRow>
      );
    }

    if (leadingContentType === NotificationLeadingContentType.TEXT) {
      const { label, labelColor, variant } = leadingText ?? {};

      return (
        <Label color={labelColor} variant={variant}>
          {label}
        </Label>
      );
    }

    if (leadingContentType === NotificationLeadingContentType.ELEMENT) {
      return leadingElement;
    }
  };

  const renderTrailing = () => {
    if (trailingContentType === NotificationTrailingContentType.ICON) {
      const { iconName, iconColor, iconSize } = trailingIcon ?? {};

      return <Icon iconName={iconName} color={iconColor} size={iconSize ?? 16} />;
    }

    if (trailingContentType === NotificationTrailingContentType.CLOSE && trailingOnClose) {
      <Link onClick={trailingOnClose}>
        <Icon iconName={IconographyNames.close} size={16} />
      </Link>;
    }
  };

  return (
    <Wrapper {...containerProps}>
      {renderLeading()}
      {renderTrailing()}
    </Wrapper>
  );
};
