import { ReactElement } from 'react';
import { RouterProvider } from 'react-router-dom';

import { GlobalInterceptor } from '~/api/interceptors/global-interceptor';
import { Fragment } from '~/styles/global-styles';

import { WithContextProviders } from '../providers/with-context-providers';
import { initializeI18n } from '../translates';
import { routes } from './routes/register-routes';

initializeI18n();

export const App = (): ReactElement => (
  <WithContextProviders>
    <GlobalInterceptor>
      <Fragment>
        <RouterProvider router={routes} />
      </Fragment>
    </GlobalInterceptor>
  </WithContextProviders>
);
