import { RechargeSteps } from '../types';
import { useRechargePaymentHook } from './payment.hook';
import { RechargePaymentLayout } from './payment.layout';

interface IRechargePayment {
  onChangeStep: (step: RechargeSteps) => void;
  totalBalance: string;
}

export const RechargePayment = ({ onChangeStep, totalBalance }: IRechargePayment) => {
  const properties = useRechargePaymentHook({ onChangeStep });

  return <RechargePaymentLayout {...properties} totalBalance={totalBalance} />;
};
