import { CardBatchStatusEnum } from '~/api/constants';

export const enUsComponentTranslations = {
  components: {
    sideBar: {
      home: 'Home',
      listCollaborators: 'Collaborators list',
      collaborators: 'Collaborators',
      listBatches: 'Registrations',
      recharge: 'Balance recharge',
      permissions: 'Permissions',
      cards: 'Request cards',
      settings: 'Settings',
      address: 'Addresses',
    },
    topBar: {
      help: 'Help',
      dockPaymentSolutions: 'Dock Payment Solutions',
      admin: 'Admin',
      operator: 'Operator',
      viewer: 'Viewer',
    },
    verificationCode: {
      title: 'Enter the code we sent to your registered email',
      resendCode: 'Resend code in {{time}} seconds',
      resend: 'Resend',
      error: 'Failed to send, ',
      tryAgain: 'try again!',
    },
    table: {
      collaborator: {
        table: {
          name: 'Fullname',
          document: 'Document',
          email: 'Email',
        },
      },
      permission: {
        table: {
          status: 'Status',
          name: 'Full name',
          document: 'CPF',
          email: 'Email',
          role: 'Permission',
          actions: 'Actions',
        },
        status: { active: 'Active', pending: 'Pending' },
        action: {
          removeAccess: 'Remove access',
          resendInvitation: 'Resend invitation',
        },
        role: {
          admin: 'Admin',
          operator: 'Operator',
          viewer: 'Viewer',
        },
        modal: {
          confirmDelete: 'Are you sure you want to remove the access of',
          confirmDeleteSelf:
            'Are you sure you want to remove your own access? You will be immediately logged out of the portal.',
          cancel: 'Cancel',
          removedAccess: 'Access removed successfully!',
          invitationSent: 'Invitation sent successfully to',
        },
      },
      batches: {
        table: {
          status: 'Status',
          statusCodes: {
            processed: 'Processed',
            processing: 'Processing',
            failed: 'Failed',
            partial_processed: 'Partial processed',
          },
          batch: 'Batch',
          date: 'Submission Date',
          dateAt: 'at',
          actions: 'Actions',
          actionsCodes: {
            viewDetails: 'View details',
          },
        },
      },
      cards: {
        table: {
          status: 'Status',
          quantity: 'Quantity',
          date: 'Request date',
          id: 'Identifier',
        },
        status: {
          [CardBatchStatusEnum.ERROR]: 'Error',
          [CardBatchStatusEnum.PROCESSED]: 'Processed',
          [CardBatchStatusEnum.PROCESSING]: 'Processing',
          [CardBatchStatusEnum.ABORTED]: 'Aborted',
          [CardBatchStatusEnum.PENDING]: 'Pending',
        },
      },
      recharge: {
        table: {
          status: 'Status',
          registerDate: 'Registration date',
          amount: 'Amount',
          actions: {
            title: 'Actions',
            viewDetails: 'View details',
            payOrder: 'Pay order',
            releaseOrder: 'Release order',
          },
        },
        status: {
          approved: 'Approved',
          partial: 'Partial approved',
          ready: 'Ready to release',
          pending: 'Pending payment',
          canceled: 'Canceled',
          failed: 'Failed release',
          processing: 'Processing order',
          processingSpreadsheet: 'Processing spreadsheet',
          spreadsheetFailure: 'Processing failure',
          unknown: 'Unknown error',
        },
      },
    },
    screen: {
      toLogin: 'To log in to the platform,',
      useDesktop: 'look for a desktop device.',
      goBack: 'Back',
    },
    proposalsTable: {
      line: 'Line',
      status: 'Status',
      name: 'Full name',
      document: 'CPF',
      email: 'Email',
      birthDate: 'Birth date',
      phone: 'Phone',
      postalCode: 'Postal code',
      street: 'Street',
      number: 'Number',
      addressComplement: 'Address complement',
      neighborhood: 'Neighborhood',
      city: 'City',
      state: 'Administrative area code',
      country: 'Country code',
      motherName: 'Mother name',
      preferredName: 'Preferred name',
      gender: 'Gender',
      emancipatedMinor: 'Emancipated minor',
      maritalStatus: 'Marital status',
      statusCodes: {
        processing: 'Processing',
        approved: 'Approved',
        declined: 'Declined',
        not_processed: 'Not processed',
      },
    },
    pageHeader: {
      goBack: 'Go back',
    },
    version: {
      qa: 'Portal Benefícios - {{front}} /v{{back}}',
      prod: 'Portal Benefícios  - {{front}} /v{{back}}',
    },
  },
};
