import {
  Accordion as BaseAccordion,
  Icon as BaseIcon,
  Link as BaseLink,
  ListItem as BaseListItem,
  ListItemLeadingTypes,
  Typography,
  TypographyVariants,
} from '@vizir-banking/design-system/web';
import styled from 'styled-components';

import { getColor } from '~/styles/theme/get-theme';

const getActiveColor = ({ isActive }: { isActive: boolean }) =>
  isActive ? getColor('neutral', 'darkest') : getColor('neutral', 'lightest');

const getActiveBackgroundColor = ({ isActive }: { isActive: boolean }) =>
  isActive ? getColor('primary', 'regular') : 'transparent';

export const Icon = styled(BaseIcon).attrs(({ isActive }) => ({
  color: getActiveColor({ isActive }),
}))<{ isActive: boolean }>`
  width: 18px;
  height: 18px;
`;

export const Label = styled(Typography).attrs({
  variant: TypographyVariants.HEADING_M,
})<{ isActive: boolean }>`
  color: ${({ isActive }) => getActiveColor({ isActive })};
`;

export const Image = styled.img`
  width: min-content;
  height: 108px;
  object-fit: cover;
  margin-bottom: 48px;
`;

export const Container = styled.div`
  z-index: 100;
  width: 20vw;
  height: 100vh;
  background-color: ${({ theme }) => theme.designSystem.palette.secondary.regular};
  padding: 24px;
  position: sticky;
  top: 0;
  left: 0;
  overflow-y: auto;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const DropdownRow = styled(BaseLink)`
  width: 100%;
  display: flex;
  padding: 8px;
  border-radius: 8px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;

  &:hover {
    background-color: ${getColor('neutral', 'medium')};
  }
`;

export const ListItem = styled(BaseListItem).attrs(({ isActive }: { isActive: boolean }) => ({
  leadingIconColor: getActiveColor({ isActive }),
  trailingIconColor: getActiveColor({ isActive }),
  leadingType: ListItemLeadingTypes.smallIcon,
  textContainerProps: {
    style: {
      fontSize: 18,
    },
  },
}))<{ isActive: boolean; hasDropdown?: boolean }>`
  padding: 8px ${({ hasDropdown }) => hasDropdown && '24px'};
  margin-top: ${({ hasDropdown }) => (hasDropdown ? '16px' : '24px')};
  width: 100%;
  gap: 16px;

  text-decoration: none;
  border-radius: 8px;
  align-items: center;

  color: ${({ isActive }) => getActiveColor({ isActive })};
  background-color: ${({ isActive }) => getActiveBackgroundColor({ isActive })};

  &:hover {
    background-color: ${({ isActive }) => !isActive && getColor('neutral', 'medium')};
  }

  & div {
    & > span {
      color: ${({ isActive }) => getActiveColor({ isActive })};
    }
  }
`;

export const Accordion = styled(BaseAccordion).attrs({
  headerContainerProps: {
    style: {
      width: '100%',
      paddingTop: 8,
      paddingBottom: 8,
    },
  },
  containerProps: {
    style: {
      width: '100%',
      flexDirection: 'column',
    },
  },
  detailsPositionalContainer: {
    style: {
      flexDirection: 'column',
      width: '100%',
      height: 'max-content',
      zIndex: 1,
    },
  },
})`
  width: 100%;
  margin-top: 16px;

  cursor: pointer;
`;
