import { IconographyNames } from '@vizir-banking/design-system';
import {
  Button as BaseButton,
  ButtonSizes,
  ButtonTypes,
  Divider as BaseDivider,
  Link as BaseLink,
  LinkVariants,
  Typography,
  TypographyVariants,
} from '@vizir-banking/design-system/web';
import styled from 'styled-components';

import { getColor } from '~/styles/theme/get-theme';

import { PaymentOptions } from './types';

const getByType = (type: PaymentOptions, first: string, second: string) => {
  return type === PaymentOptions.TED ? first : second;
};

export const SummaryContainer = styled.div`
  width: 100%;
  padding: 16px 32px 24px;
  border: 1px solid ${getColor('neutral', 'light')};
  border-radius: 8px;
  margin: 32px 0 24px;
`;

export const Column = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 16px;
`;

export const Divider = styled(BaseDivider)`
  flex: 1;
`;

export const Title = styled(Typography).attrs({
  variant: TypographyVariants.SUBTITLE,
})`
  font-weight: 700;
  color: ${getColor('neutral', 'darkest')};
  margin: 8px 0;
`;

export const Label = styled(Typography).attrs({
  variant: TypographyVariants.PARAGRAPH_S,
})`
  color: ${getColor('neutral', 'darker')};
`;

export const Dialog = styled(Typography).attrs({
  variant: TypographyVariants.DIALOG,
})`
  color: ${getColor('neutral', 'darkest')};
  font-weight: 700;
`;

export const Value = styled(Typography).attrs({
  variant: TypographyVariants.DIALOG,
})`
  color: ${getColor('neutral', 'darkest')};
`;

export const ValueBold = styled(Typography).attrs({
  variant: TypographyVariants.HEADING_L,
})`
  color: ${getColor('neutral', 'darkest')};
  font-weight: 700;
`;

export const OptionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 24px;
`;

export const OptionWrapper = styled.div<{ type: PaymentOptions; isDisabled: boolean }>`
  width: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  gap: 24px;
  padding: 16px 24px;
  border: 2px solid
    ${({ isDisabled }) => (isDisabled ? getColor('neutral', 'lighter') : getColor('neutral', 'light'))};
  border-radius: 8px;
  background-color: ${getColor('neutral', 'lighter')};
  cursor: ${({ type }) => getByType(type, 'pointer', 'not-allowed')};
  opacity: ${({ type }) => getByType(type, '1', '0.5')};
`;

export const OptionTitle = styled(Typography).attrs({
  variant: TypographyVariants.DIALOG,
})<{ isDisabled?: boolean }>`
  color: ${({ isDisabled }) => (isDisabled ? getColor('neutral', 'medium') : getColor('neutral', 'darkest'))};
`;

export const OptionSubtitle = styled(Typography).attrs({
  variant: TypographyVariants.PARAGRAPH_XS,
})<{ isDisabled?: boolean }>`
  color: ${({ isDisabled }) => (isDisabled ? getColor('neutral', 'light') : getColor('neutral', 'dark'))};
`;

export const ButtonWrapper = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
`;

export const Link = styled(BaseLink).attrs({
  linkVariant: LinkVariants.s,
})`
  color: ${getColor('error', 'medium')};
  text-decoration: none;
`;

export const PrimaryButton = styled(BaseButton).attrs(() => ({
  buttonType: ButtonTypes.primary,
  buttonSize: ButtonSizes.medium,
  withTrailingIcon: true,
  trailingIconName: IconographyNames.chevronRight,
  trailingIconColor: getColor('neutral', 'darkest'),
}))`
  margin-left: auto;
`;
