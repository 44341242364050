import { Props as BaseButtonProps } from '@vizir-banking/design-system/web/components/button/types';
import { ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { clearError, error } from '~/redux/reducers/application';
import { ErrorsEnum } from '~/translates/error/types';
import { hasTranslate, useTranslation } from '~/translates/use-translate';

import { Description, Dialog, Wrapper } from './error-dialog.styles';

type ButtonProps = Omit<BaseButtonProps, 'buttonSize' | 'buttonType'>;

export type Props = {
  closeOnClickOutside?: boolean;
  title?: string;
  description?: string;
  primaryButtonProps?: ButtonProps;
  secondaryButtonProps?: ButtonProps;
  isTokenExpiration?: boolean;
};

export function ErrorDialog({
  title,
  description,
  primaryButtonProps,
  secondaryButtonProps,
  closeOnClickOutside = true,
  isTokenExpiration = false,
}: Props): ReactElement {
  const dispatch = useDispatch();
  const translate = useTranslation('errors');

  const errorCode = useSelector(error);
  const key = hasTranslate(`errors.${errorCode}`) ? errorCode : 'generic_error';

  const closeModalProps = {
    label: translate(`${key}.button`),
    onClick: () => dispatch(clearError()),
  };

  const isVisible = isTokenExpiration
    ? errorCode === ErrorsEnum.EXPIRED_TOKEN
    : errorCode !== '' && errorCode !== ErrorsEnum.CANCELED_REQUEST;

  return (
    <Dialog
      isVisible={isVisible}
      title={title ?? translate(`${key}.title`)}
      onOutsideClick={() => closeOnClickOutside && dispatch(clearError())}
      primaryButtonProps={primaryButtonProps}
      secondaryButtonProps={secondaryButtonProps ?? closeModalProps}
    >
      <Wrapper>
        <Description>{description ?? translate(`${key}.description`)}</Description>
      </Wrapper>
    </Dialog>
  );
}
