import { ReactNode } from 'react';

import { GetEmployerBasicInfoResponseDto, UserMeResponseDto } from '~/api/requests';
import { LoginImage } from '~/assets/images-mapping';
import { Fragment } from '~/styles/global-styles';
import { useTranslation } from '~/translates/use-translate';

import { PortalVersion } from '../portal-version/portal-version';
import { SideBar } from '../side-bar/side-bar';
import { SideBarTabs } from '../side-bar/types';
import { TopBar } from '../top-bar/top-bar';
import {
  ArrowLeft,
  Container,
  GoBack,
  Image,
  InfoIcon,
  LoggedChildren,
  LoginChildren,
  LoginContainer,
  LoginWrapper,
  MainParagraph,
  MobileContainer,
  MobileWrapper,
  NoEmphasys,
  SimpleChildren,
  Wrapper,
} from './screen.styles';
import { ScreenTypes } from './types';

interface Dropdown {
  collaborators: boolean;
  settings: boolean;
}

interface IScreenLayout {
  children: ReactNode;
  type: ScreenTypes;
  dropdown: Dropdown;
  user?: UserMeResponseDto;
  employer?: GetEmployerBasicInfoResponseDto;
  isMobile: boolean;
  isTablet: boolean;
  isActive: (tab: SideBarTabs) => boolean;
  navigateTo: (tab: SideBarTabs) => void;
  logout: () => Promise<void>;
  goBack?: () => void;
}

export const ScreenLayout = ({
  children,
  dropdown,
  type,
  user,
  employer,
  isMobile,
  isTablet,
  isActive,
  navigateTo,
  logout,
  goBack,
}: IScreenLayout) => {
  const translate = useTranslation('components.screen');
  const renderSimple = () => {
    return <SimpleChildren>{children}</SimpleChildren>;
  };

  const renderGoBack = () =>
    goBack && (
      <GoBack onClick={goBack}>
        <ArrowLeft />
        {translate('goBack')}
      </GoBack>
    );

  const renderLogin = () => {
    return (
      <Fragment>
        <Image src={LoginImage} />
        <LoginContainer>
          <LoginWrapper>
            {renderGoBack()}
            <LoginChildren>
              {children}
              <PortalVersion />
            </LoginChildren>
          </LoginWrapper>
        </LoginContainer>
      </Fragment>
    );
  };

  const renderLogged = () => {
    return (
      <Fragment>
        <SideBar dropdown={dropdown} isActive={isActive} navigateTo={navigateTo} />
        <Wrapper>
          <TopBar logout={logout} user={user} employer={employer} />
          <LoggedChildren>{children}</LoggedChildren>
        </Wrapper>
      </Fragment>
    );
  };

  const renderMobile = () => {
    return (
      <MobileContainer>
        <MobileWrapper>
          <InfoIcon />
          <MainParagraph isTablet={isTablet}>
            {translate('toLogin')}
            <NoEmphasys>{translate('useDesktop')}</NoEmphasys>
          </MainParagraph>
        </MobileWrapper>
      </MobileContainer>
    );
  };

  const renderChildren = () => {
    const types: { [type in ScreenTypes]: JSX.Element } = {
      [ScreenTypes.SIMPLE]: renderSimple(),
      [ScreenTypes.LOGIN]: renderLogin(),
      [ScreenTypes.LOGGED]: renderLogged(),
    };

    return types[type];
  };

  return isMobile ? renderMobile() : <Container>{renderChildren()}</Container>;
};
