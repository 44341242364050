import { formatToCEP, formatToCPF, formatToDate, formatToPhone } from 'brazilian-values';
import { ReactElement } from 'react';

import { GetSpecificBatchDetailsResponseDto, ProposalDetailed } from '~/api/requests';
import { useTranslation } from '~/translates/use-translate';

import {
  BaseTableCell,
  BaseTableHeadCell,
  Container,
  Skeleton,
  SkeletonDiv,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableStatusCell,
} from './proposals-table.styles';

export const ProposalsTable = ({
  batchData,
  isBatchLoading,
}: {
  batchData: GetSpecificBatchDetailsResponseDto | undefined;
  isBatchLoading: boolean;
}): ReactElement => {
  const translate = useTranslation('components.proposalsTable');

  const sanitizeColumnName = (column: string): string => {
    return column.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
  };

  const getError = (proposal: ProposalDetailed, columnName: string, index: number) => {
    for (let i = 0; i < proposal.errors.length; i++) {
      if (
        sanitizeColumnName(proposal.errors[i].column) === sanitizeColumnName(columnName) &&
        proposal.errors[i].line === index + 1
      ) {
        return true;
      }
    }
    return false;
  };

  const formatValue = (value: string | number | undefined, type: string): string => {
    switch (type) {
      case 'birthDate':
        return formatToDate(new Date(value as string));
      case 'phone':
        const phoneInfos = (value as string).split('-');
        return `+${phoneInfos[0]} ${formatToPhone(phoneInfos[1])}`;
      case 'cpf':
        return formatToCPF(value as string);
      case 'addressPostalCode':
        return formatToCEP(value as string);
      default:
        return value as string;
    }
  };

  const renderRows = () => {
    return batchData?.preview.map((proposal, index) => {
      return (
        <TableRow key={index}>
          <BaseTableCell>{proposal.line}</BaseTableCell>
          <TableStatusCell currentStatus={proposal.status}>
            {translate(`statusCodes.${proposal.status}`)}
          </TableStatusCell>
          <BaseTableCell hasError={getError(proposal, 'personFullName', index)}>
            {formatValue(proposal.proposal.personFullName, 'personFullName')}
          </BaseTableCell>
          <BaseTableCell hasError={getError(proposal, 'cpf', index)}>
            {formatValue(proposal.proposal.cpf, 'cpf')}
          </BaseTableCell>
          <BaseTableCell hasError={getError(proposal, 'email', index)}>
            {formatValue(proposal.proposal.email, 'email')}
          </BaseTableCell>
          <BaseTableCell hasError={getError(proposal, 'birthDate', index)}>
            {formatValue(proposal.proposal.birthDate, 'birthDate')}
          </BaseTableCell>
          <BaseTableCell hasError={getError(proposal, 'phone', index)}>
            {formatValue(
              `${proposal.proposal.phoneDiallingCode}-${proposal.proposal.phoneAreaCode}${proposal.proposal.phoneNumber}`,
              'phone'
            )}
          </BaseTableCell>
          <BaseTableCell hasError={getError(proposal, 'addressPostalCode', index)}>
            {formatValue(proposal.proposal.addressPostalCode, 'addressPostalCode')}
          </BaseTableCell>
          <BaseTableCell hasError={getError(proposal, 'addressStreet', index)}>
            {formatValue(proposal.proposal.addressStreet, 'addressStreet')}
          </BaseTableCell>
          <BaseTableCell hasError={getError(proposal, 'addressNumber', index)}>
            {formatValue(proposal.proposal.addressNumber, 'addressNumber')}
          </BaseTableCell>
          <BaseTableCell hasError={getError(proposal, 'addressComplement', index)}>
            {formatValue(proposal.proposal.addressComplement || '-', 'addressComplement')}
          </BaseTableCell>
          <BaseTableCell hasError={getError(proposal, 'addressNeighborhood', index)}>
            {formatValue(proposal.proposal.addressNeighborhood, 'addressNeighborhood')}
          </BaseTableCell>
          <BaseTableCell hasError={getError(proposal, 'addressCity', index)}>
            {formatValue(proposal.proposal.addressCity, 'addressCity')}
          </BaseTableCell>
          <BaseTableCell hasError={getError(proposal, 'addressAdministrativeAreaCode', index)}>
            {formatValue(proposal.proposal.addressAdministrativeAreaCode, 'addressAdministrativeAreaCode')}
          </BaseTableCell>
          <BaseTableCell hasError={getError(proposal, 'addressCountryCode', index)}>
            {formatValue(proposal.proposal.addressCountryCode, 'addressCountryCode')}
          </BaseTableCell>
          <BaseTableCell hasError={getError(proposal, 'motherName', index)}>
            {formatValue(proposal.proposal.motherName, 'motherName')}
          </BaseTableCell>
          <BaseTableCell hasError={getError(proposal, 'preferredName', index)}>
            {formatValue(proposal.proposal.preferredName || '-', 'preferredName')}
          </BaseTableCell>
          <BaseTableCell hasError={getError(proposal, 'gender', index)}>
            {formatValue(proposal.proposal.gender || '-', 'gender')}
          </BaseTableCell>
          <BaseTableCell hasError={getError(proposal, 'emancipatedMinor', index)}>
            {formatValue(proposal.proposal.emancipatedMinor || '-', 'emancipatedMinor')}
          </BaseTableCell>
          <BaseTableCell hasError={getError(proposal, 'maritalStatus', index)}>
            {formatValue(proposal.proposal.maritalStatus || '-', 'maritalStatus')}
          </BaseTableCell>
        </TableRow>
      );
    });
  };

  const renderSkeleton = () => {
    return Array.from({ length: 3 }).map((_) => {
      return (
        <TableRow>
          {Array.from({ length: 14 }).map((_, index) => (
            <BaseTableCell key={index}>
              <Skeleton>
                <SkeletonDiv />
              </Skeleton>
            </BaseTableCell>
          ))}
        </TableRow>
      );
    });
  };

  return (
    <Container>
      <TableContainer>
        <TableHead>
          <TableRow>
            <BaseTableHeadCell>{translate('line')}</BaseTableHeadCell>
            <BaseTableHeadCell>{translate('status')}</BaseTableHeadCell>
            <BaseTableHeadCell>{translate('name')}</BaseTableHeadCell>
            <BaseTableHeadCell>{translate('document')}</BaseTableHeadCell>
            <BaseTableHeadCell>{translate('email')}</BaseTableHeadCell>
            <BaseTableHeadCell>{translate('birthDate')}</BaseTableHeadCell>
            <BaseTableHeadCell>{translate('phone')}</BaseTableHeadCell>
            <BaseTableHeadCell>{translate('postalCode')}</BaseTableHeadCell>
            <BaseTableHeadCell>{translate('street')}</BaseTableHeadCell>
            <BaseTableHeadCell>{translate('number')}</BaseTableHeadCell>
            <BaseTableHeadCell>{translate('addressComplement')}</BaseTableHeadCell>
            <BaseTableHeadCell>{translate('neighborhood')}</BaseTableHeadCell>
            <BaseTableHeadCell>{translate('city')}</BaseTableHeadCell>
            <BaseTableHeadCell>{translate('state')}</BaseTableHeadCell>
            <BaseTableHeadCell>{translate('country')}</BaseTableHeadCell>
            <BaseTableHeadCell>{translate('motherName')}</BaseTableHeadCell>
            <BaseTableHeadCell>{translate('preferredName')}</BaseTableHeadCell>
            <BaseTableHeadCell>{translate('gender')}</BaseTableHeadCell>
            <BaseTableHeadCell>{translate('emancipatedMinor')}</BaseTableHeadCell>
            <BaseTableHeadCell>{translate('maritalStatus')}</BaseTableHeadCell>
          </TableRow>
        </TableHead>

        <TableBody>{isBatchLoading ? renderSkeleton() : renderRows()}</TableBody>
      </TableContainer>
    </Container>
  );
};
