import { getInitialLetter } from '~/utils/get-initial-letter';

import { Container, Initial } from './user-avatar.styles';

interface IUserAvatar {
  name: string;
  size?: number;
}

export const UserAvatar = ({ name, size = 48 }: IUserAvatar) => {
  return (
    <Container size={size}>
      <Initial>{getInitialLetter(name)}</Initial>
    </Container>
  );
};
