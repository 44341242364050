import { UseQueryResult } from '@tanstack/react-query';
import { ReactElement } from 'react';

import { ListEmployeesResponseDto, SimplifiedEmployees } from '~/api/requests';
import { ErrorDialog } from '~/components/dialogs/error-dialog/error-dialog';
import { ButtonProps, SuccessDialog } from '~/components/dialogs/success-dialog/success-dialog';

import { Screen } from './collaborators.styles';
import { CollaboratorsViewPage } from './collaborators-view/collaborators-view';
import { CollaboratorProfilePage } from './profile/profile';
import { CollaboratorsSteps } from './types';

interface ICollaborators {
  personId: string;
  currentStep: CollaboratorsSteps;
  closeModalProps?: ButtonProps;
  onChangeStep: (
    step: CollaboratorsSteps,
    id?: string,
    collaboratorPosition?: {
      index: number;
      page: number;
    }
  ) => void;
  allCollaborators: SimplifiedEmployees[];
  updateCollaborators: (collaborators: SimplifiedEmployees[], page: number) => void;
  getCollaborators: UseQueryResult<ListEmployeesResponseDto, unknown>;
  page: number;
  updatePage: () => void;
  collaboratorPosition: {
    index: number;
    page: number;
  };
  document: string;
  setDocument: (document: string) => void;
  documentError: string;
  setDocumentError: (error: string) => void;
  enabled: boolean;
  setEnabled: (enabled: boolean) => void;
  USERS_PER_PAGE: number;
}

export const CollaboratorsLayout = ({
  personId,
  currentStep,
  closeModalProps,
  onChangeStep,
  allCollaborators,
  updateCollaborators,
  document,
  setDocument,
  documentError,
  setDocumentError,
  enabled,
  setEnabled,
  page,
  updatePage,
  collaboratorPosition,
  USERS_PER_PAGE,
  getCollaborators,
}: ICollaborators) => {
  const renderCollaboratorsViewer = () => {
    return (
      <CollaboratorsViewPage
        onChangeStep={onChangeStep}
        allCollaborators={allCollaborators}
        updateCollaborators={updateCollaborators}
        document={document}
        setDocument={setDocument}
        documentError={documentError}
        setDocumentError={setDocumentError}
        enabled={enabled}
        setEnabled={setEnabled}
        page={page}
        updatePage={updatePage}
        USERS_PER_PAGE={USERS_PER_PAGE}
        getCollaborators={getCollaborators}
      />
    );
  };

  const renderCollaboratorProfile = () => {
    return (
      <CollaboratorProfilePage
        onChangeStep={onChangeStep}
        personId={personId}
        collaboratorPosition={collaboratorPosition}
      />
    );
  };

  const renderSteps = (): ReactElement => {
    const steps = {
      [CollaboratorsSteps.VIEW]: renderCollaboratorsViewer(),
      [CollaboratorsSteps.PROFILE]: renderCollaboratorProfile(),
    };

    return steps[currentStep];
  };

  return (
    <Screen>
      {renderSteps()}
      <SuccessDialog secondaryButtonProps={closeModalProps} />
      <ErrorDialog />
    </Screen>
  );
};
