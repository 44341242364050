import { IconographyNames } from '@vizir-banking/design-system';
import {
  Icon as BaseIcon,
  Skeleton as BaseSkeleton,
  SkeletonVariants,
  Table as BaseTable,
  TableCell as BaseTableCell,
  TableHeadCell as BaseTableHeadCell,
  TableRow as BaseTableRow,
  TableTagCell as BaseTableTagCell,
} from '@vizir-banking/design-system/web';
import styled from 'styled-components';

import { getColor } from '~/styles/theme/get-theme';

export const TableContainer = styled(BaseTable)`
  overflow-y: visible;
  height: auto;
  margin-top: 24px;
`;

export const TableHeadRow = styled(BaseTableRow)`
  background-color: ${getColor('neutral', 'lighter')};
`;

export const TableBodyRow = styled(BaseTableRow)``;

export const TableHeadCell = styled(BaseTableHeadCell)`
  border: 1px solid ${getColor('neutral', 'light')};
  text-align: start;
`;

export const TableBodyCell = styled(BaseTableCell)`
  border: 1px solid ${getColor('neutral', 'lighter')};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const TableBodyTagCell = styled(BaseTableTagCell)`
  border: 1px solid ${getColor('neutral', 'lighter')};
`;

export const Skeleton = styled(BaseSkeleton).attrs({
  variant: SkeletonVariants.tag,
})`
  height: 32px;
  width: 120px;
`;

export const DateWrapper = styled.div`
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
`;

export const CalendarIcon = styled(BaseIcon).attrs(({ theme }) => ({
  iconName: IconographyNames.calendar,
  color: theme.designSystem.palette.neutral.darkest,
}))`
  height: 24px;
  width: 24px;
`;
