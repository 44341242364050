import { Theme as DesignSystemTheme } from '@vizir-banking/design-system';
import get from 'lodash/get';

type StyleValue = string | number;
type NullableStyleValue = StyleValue | null | undefined;

export function getColor<T extends keyof DesignSystemTheme['designSystem']['palette']>(
  colorType: T,
  colorIntensity: keyof DesignSystemTheme['designSystem']['palette'][T]
) {
  return function (props: { theme: DesignSystemTheme }): NullableStyleValue {
    return get(props.theme.designSystem.palette, `${colorType}.${colorIntensity as string}`, '') as string;
  };
}

export const getOpacityColor = (color: string, opacity: number) => {
  return `rgba(${parseInt(color.slice(1, 3), 16)}, ${parseInt(color.slice(3, 5), 16)}, ${parseInt(
    color.slice(5, 7),
    16
  )}, ${opacity})`;
};
