import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ScopeEnum } from '~/api/constants';
import {
  useEmployerServiceEmployerControllerGetEmployerBasicInfo,
  useLoadOrderServiceOrderControllerListOrders,
  useUserServiceUserControllerGetUser,
} from '~/api/queries';
import { ApiError, OpenAPI, Order, UserMeResponseDto } from '~/api/requests';
import { SCREENS } from '~/app/routes/screen-definitions';
import { useUserScopes } from '~/hooks/use-user-scopes';
import { setError } from '~/redux/reducers/application';
import { clearEmployer, setEmployer } from '~/redux/reducers/employer';
import { clearCurrentUser, selectCurrentUser, setCurrentUser } from '~/redux/reducers/user';

interface IHome {
  user?: UserMeResponseDto;
  orders: Order[];
  isLoading: boolean;
  shouldCreateCollaborator: boolean;
  goToLogin: () => void;
  goToCollaborators: () => void;
  goToRecharge: () => void;
}

const ORDERS_PER_PAGE = 6;

export const useHome = (): IHome => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { hasScope } = useUserScopes();

  const orders = useLoadOrderServiceOrderControllerListOrders({
    page: 1,
    perPage: ORDERS_PER_PAGE,
  });

  const user = useSelector(selectCurrentUser);
  const getUser = useUserServiceUserControllerGetUser();
  const getEmployer = useEmployerServiceEmployerControllerGetEmployerBasicInfo();

  const goToCollaborators = () => {
    navigate(SCREENS.listCollaborators);
  };

  const goToRecharge = () => {
    navigate(SCREENS.recharge);
  };

  const goToLogin = () => {
    OpenAPI.HEADERS = undefined;
    dispatch(clearCurrentUser());
    dispatch(clearEmployer());
    dispatch(setError(''));
    navigate(SCREENS.login);
  };

  useEffect(() => {
    getUser.data && dispatch(setCurrentUser(getUser.data));
    getEmployer.data && dispatch(setEmployer(getEmployer.data));
  }, [getUser.data, getEmployer.data]);

  useEffect(() => {
    if (getUser.isError) {
      const e = getUser.error as ApiError;
      dispatch(setError(e?.body?.code ?? 'generic_error'));
    }
  }, [getUser.isError, getEmployer.isError]);

  return {
    user,
    orders: orders?.data?.data ?? [],
    isLoading: orders.isPending,
    shouldCreateCollaborator: hasScope(ScopeEnum.ADD_EMPLOYEE),
    goToLogin,
    goToCollaborators,
    goToRecharge,
  };
};
