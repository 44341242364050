import { IconographyNames, Theme } from '@vizir-banking/design-system';
import { Icon as BaseIcon, Typography, TypographyVariants } from '@vizir-banking/design-system/web';
import styled from 'styled-components';

import { PasswordStatus } from './types';

const getBackgroundColor = (isCorrect: boolean, theme: Theme) => {
  if (isCorrect) {
    const color = theme.designSystem.palette.success.lightest;
    return `rgba(${parseInt(color.slice(1, 3), 16)}, ${parseInt(color.slice(3, 5), 16)}, ${parseInt(
      color.slice(5, 7),
      16
    )}, 0.15)`;
  }
  return theme.designSystem.palette.neutral.lighter;
};

const getBorderColor = (isCorrect: boolean, theme: Theme) => {
  return isCorrect ? theme.designSystem.palette.success.darkest : theme.designSystem.palette.neutral.darker;
};

export const Container = styled.div<{ isCorrect: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 16px;
  border: 2px solid ${({ theme, isCorrect }) => getBorderColor(isCorrect, theme)};
  border-radius: 8px;
  background-color: ${({ theme, isCorrect }) => getBackgroundColor(isCorrect, theme)};
  gap: 8px;
`;

export const Wrapper = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const Label = styled(Typography).attrs({
  variant: TypographyVariants.CAPTION,
})<{ status: PasswordStatus }>`
  color: ${({ theme, status }) => {
    switch (status) {
      case PasswordStatus.CORRECT:
        return theme.designSystem.palette.success.medium;
      case PasswordStatus.WRONG:
        return theme.designSystem.palette.error.medium;
      default:
        return theme.designSystem.palette.secondary.regular;
    }
  }};
`;

export const Icon = styled(BaseIcon).attrs(({ status, theme }: { status: PasswordStatus; theme: Theme }) => {
  switch (status) {
    case PasswordStatus.CORRECT:
      return { iconName: IconographyNames.checkCircle, color: theme.designSystem.palette.success.medium };
    case PasswordStatus.WRONG:
      return { iconName: IconographyNames.errorCircle, color: theme.designSystem.palette.error.medium };
    default:
      return { iconName: IconographyNames.infoCircle, color: theme.designSystem.palette.secondary.regular };
  }
})`
  width: 16px;
  height: 16px;
`;
