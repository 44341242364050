import { IconographyNames } from '@vizir-banking/design-system';
import {
  Banner as BaseBanner,
  BannerTypes,
  Icon as BaseIcon,
  Skeleton as BaseSkeleton,
  SkeletonVariants,
  Typography,
  TypographyVariants,
} from '@vizir-banking/design-system/web';
import styled from 'styled-components';

import { getColor } from '~/styles/theme/get-theme';

export const Container = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
  height: 40px;
`;

export const Banner = styled(BaseBanner).attrs(({ type, title }) => ({
  type,
  title,
}))<{ type?: BannerTypes }>`
  padding: 8px;
  padding-top: 16px;
  height: 100%;
  justify-content: center;
  background-color: ${({ type }) => {
    switch (type) {
      case BannerTypes.success:
        return getColor('success', 'lightest');
      case BannerTypes.error:
        return getColor('error', 'lightest');
      case BannerTypes.warning:
        return getColor('warning', 'lightest');
      default:
        return getColor('neutral', 'lightest');
    }
  }};
  border-color: ${({ type }) => {
    switch (type) {
      case BannerTypes.success:
        return getColor('success', 'light');
      case BannerTypes.error:
        return getColor('error', 'light');
      case BannerTypes.warning:
        return getColor('warning', 'lightest');
      default:
        return getColor('neutral', 'light');
    }
  }};
`;

export const FeedbackBar = styled.div`
  gap: 8px;
  width: 100%;
  height: 2rem;
  border-radius: 4px;
  padding: 4px 8px;
`;

export const Skeleton = styled(BaseSkeleton).attrs({
  variant: SkeletonVariants.custom,
})`
  width: 100%;
`;

export const Line = styled.div`
  height: 100px;
  width: 10px;
  background-color: ${getColor('info', 'medium')};
`;

export const FullBanner = styled.div`
  flex: 1;
  max-height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
  border: 1px solid ${getColor('info', 'light')};
  overflow: hidden;
`;

export const FullBannerContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 16px;
  gap: 8px;
`;

export const InfoCircle = styled(BaseIcon).attrs(() => ({
  iconName: IconographyNames.infoCircle,
  color: getColor('info', 'medium'),
}))`
  width: 24px;
  height: 24px;
`;

export const Subtitle = styled(Typography).attrs({
  variant: TypographyVariants.SUBTITLE,
})`
  color: ${getColor('neutral', 'darkest')};
`;
