import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  useLoadOrderServiceOrderControllerCancelOrder,
  useLoadOrderServiceOrderControllerGetOrderDetailsViaEmail,
} from '~/api/queries';
import { ApiError } from '~/api/requests';
import { setError } from '~/redux/reducers/application';

interface IUseRechargeDetails {
  isSendEmailModalVisible: boolean;
  toggleSendEmailModal: () => void;
  isEmailLoading: boolean;
  toggleSendEmail: () => void;
  isSuccessModalVisible: boolean;
  toggleSuccessModal: () => void;
  isCanceledModalVisible: boolean;
  toggleCanceledModal: () => void;
  isCancelOrderLoading: boolean;
  handleCancelOrder: () => void;
}

export const UseRechargeDetails = (orderId: string): IUseRechargeDetails => {
  const dispatch = useDispatch();
  const [isSendEmailModalVisible, setIsSendEmailModalVisible] = useState(false);
  const [isSendEmailEnabled, setIsSendEmailEnabled] = useState(false);
  const [isSendEmailHookEnabled, setIsSendEmailHookEnabled] = useState(false);
  const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false);
  const [isEmailLoading, setIsEmailLoading] = useState(false);
  const [isCanceledModalVisible, setIsCanceledModalVisible] = useState(false);
  const [isCancelOrderLoading, setIsCancelOrderLoading] = useState(false);

  const sendEmail = useLoadOrderServiceOrderControllerGetOrderDetailsViaEmail(
    {
      id: orderId,
    },
    undefined,
    {
      enabled: isSendEmailHookEnabled,
    }
  );

  const cancelOrder = useLoadOrderServiceOrderControllerCancelOrder({
    onSuccess: () => {
      setIsCanceledModalVisible(true);
      setIsCancelOrderLoading(false);
    },
    onError: (e: ApiError) => {
      dispatch(setError(e.message));
      setIsCancelOrderLoading(false);
    },
    onMutate: () => {
      setIsCancelOrderLoading(true);
    },
  });

  const handleCancelOrder = () => {
    cancelOrder.mutate({ id: orderId });
  };

  useEffect(() => {
    const handleRefetch = async () => {
      await sendEmail.refetch().then(() => {
        if (sendEmail.isSuccess) {
          setIsSendEmailModalVisible(false);
          setIsSuccessModalVisible(true);
        }
        setIsEmailLoading(false);
      });
    };

    if (isSendEmailEnabled && isSendEmailHookEnabled) {
      handleRefetch();
      setIsSendEmailEnabled(false);
    }
  }, [isEmailLoading]);

  const toggleSendEmail = () => {
    setIsEmailLoading(true);
    if (!isSendEmailHookEnabled) {
      setIsSendEmailHookEnabled(true);
      setIsSendEmailModalVisible(false);
      setIsSuccessModalVisible(true);
      setIsEmailLoading(false);

      return;
    }
    setIsSendEmailEnabled(true);
  };

  return {
    isSendEmailModalVisible,
    toggleSendEmailModal: () => setIsSendEmailModalVisible(!isSendEmailModalVisible),
    isEmailLoading,
    toggleSendEmail,
    isSuccessModalVisible,
    toggleSuccessModal: () => setIsSuccessModalVisible(!isSuccessModalVisible),
    isCanceledModalVisible,
    toggleCanceledModal: () => setIsCanceledModalVisible(!isCanceledModalVisible),
    isCancelOrderLoading,
    handleCancelOrder,
  };
};
