import { ButtonProps as BaseButtonProps } from '@vizir-banking/design-system/web';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { AddressDto } from '~/api/requests';
import { clearError, clearSuccess } from '~/redux/reducers/application';
import { useTranslation } from '~/translates/use-translate';

import { CardsSteps, ICardsSteps } from '../types';

type ButtonType = 'error' | 'success';
type ButtonProps = Omit<BaseButtonProps, 'buttonSize' | 'buttonType'>;

interface CardState {
  quantity: string;
  date: string;
}

interface INewCards {
  card: CardState;
  currentTab: number;
  address: AddressDto;
  buttonProps: (type: ButtonType) => ButtonProps;
  onChangeTab: (tab: number) => void;
  goBack: () => void;
  onCancel: () => void;
  handleData: (name: string, value: string) => void;
  onChangeSelectAddress: (address: AddressDto) => void;
}

const INITIAL_CARD_STATE = {
  quantity: '',
  date: '',
};

const INITIAL_ADDRESS_STATE: AddressDto = {
  id: '',
  city: '',
  country: '',
  neighborhood: '',
  number: '',
  state: '',
  street: '',
  zipCode: '',
  complement: '',
};

export const useNewCardsHook = ({ onChangeStep }: ICardsSteps): INewCards => {
  const translate = useTranslation('pages.cards.newCards');
  const dispatch = useDispatch();

  const [card, setCard] = useState(INITIAL_CARD_STATE);
  const [address, setAddress] = useState<AddressDto>(INITIAL_ADDRESS_STATE);
  const [currentTab, setCurrentTab] = useState(0);

  const handleData = (name: string, value: string) => {
    setCard({ ...card, [name]: value });
  };

  const onChangeSelectAddress = (address: AddressDto) => {
    setAddress(address);
  };

  const onChangeTab = (step: number) => {
    setCurrentTab(step);
  };

  const goBack = () => {
    if (currentTab === 0) onChangeStep(CardsSteps.CARD_LIST);
    else onChangeTab(currentTab - 1);
  };

  const onCancel = () => {
    onChangeStep(CardsSteps.CARD_LIST);
  };

  const onErrorClick = () => {
    dispatch(clearError());
    onChangeStep(CardsSteps.CARD_LIST);
  };

  const onSuccessClick = () => {
    dispatch(clearSuccess());
    onChangeStep(CardsSteps.CARD_LIST);
  };

  const buttonProps = (type: ButtonType) => {
    return {
      label: type === 'error' ? translate('button.error') : translate('button.success'),
      onClick: type === 'error' ? onErrorClick : onSuccessClick,
    };
  };

  return {
    card,
    currentTab,
    address,
    onChangeTab,
    goBack,
    onCancel,
    handleData,
    onChangeSelectAddress,
    buttonProps,
  };
};
