import { ICollaboratorProfile } from '../types';
import { useCollaboratorProfile } from './profile.hook';
import { CollaboratorProfileLayout } from './profile.layout';

export const CollaboratorProfilePage = ({
  onChangeStep,
  personId,
  collaboratorPosition,
}: ICollaboratorProfile) => {
  const props = useCollaboratorProfile({ personId, onChangeStep });

  return (
    <CollaboratorProfileLayout
      {...props}
      onChangeStep={onChangeStep}
      collaboratorPosition={collaboratorPosition}
    />
  );
};
