import { useApplicationServiceApplicationControllerGetVersions } from '~/api/queries';
import { env } from '~/config/env';
import { useTranslation } from '~/translates/use-translate';

import { Skeleton, Version, Wrapper } from './portal-version.styles';

export const PortalVersion = () => {
  const translate = useTranslation('components.version');
  const { data, isPending } = useApplicationServiceApplicationControllerGetVersions();

  const front = env.REACT_APP_BUILD_VERSION;
  const back = data?.backend?.latest ?? '';
  const isDev = ['.qa', '.staging', '.dev'].some((i) => env.REACT_APP_BASE_URL.includes(i));

  if (isPending) {
    return (
      <Wrapper>
        <Skeleton />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Version>
        {translate(isDev ? 'qa' : 'prod', {
          front,
          back,
        })}
      </Version>
    </Wrapper>
  );
};
