import { Link } from '@vizir-banking/design-system/web';
import { formatToCNPJ } from 'brazilian-values';

import { EmployerControllerGetAccountThatWillReceivePaymentsResponse } from '~/api/requests';
import { LeadingContentType, PageHeader, TrailingContentType } from '~/components/page-header/page-header';
import { FragmentCol } from '~/styles/global-styles';
import { useTranslation } from '~/translates/use-translate';
import { autoSingleStyle } from '~/utils/auto-single-style';

import {
  Column,
  Container,
  Content,
  Divider,
  Header,
  HeaderSubtitle,
  HeaderTitle,
  HeaderTitleBold,
  Icon,
  ItemWrapper,
  Label,
  Row,
  SecondaryButton,
  Skeleton,
  Title,
  Value,
} from './payment.styles';

enum PaymentItems {
  AGENCY = 'agency',
  ACCOUNT = 'account',
  DOCUMENT = 'cnpj',
  BANK = 'bankNumber',
  SOCIAL = 'legalName',
}

interface IRechargePaymentLayout {
  info?: EmployerControllerGetAccountThatWillReceivePaymentsResponse;
  totalBalance: string;
  isLoading: boolean;
  goBackToOrders: () => void;
  goBack: () => void;
}

export const RechargePaymentLayout = ({
  info,
  isLoading,
  totalBalance,
  goBackToOrders,
  goBack,
}: IRechargePaymentLayout) => {
  const translate = useTranslation('pages.recharge.newRecharge.tabs.payment');

  const renderPageHeader = () => {
    return (
      <PageHeader
        title={translate('title')}
        leadingContentType={LeadingContentType.GO_BACK}
        leadingGoBack={goBack}
        trailingContentType={TrailingContentType.TEXT}
      />
    );
  };

  const renderHeader = () => {
    return (
      <Header>
        {autoSingleStyle(
          translate('header.title', {
            currency: totalBalance,
            method: 'TED',
          }),
          '*',
          HeaderTitleBold,
          HeaderTitle
        )}
        <HeaderSubtitle>{translate('header.subtitle')}</HeaderSubtitle>
      </Header>
    );
  };

  const renderItem = (type: PaymentItems) => {
    let value = info?.[type] ?? '';
    if (type === PaymentItems.DOCUMENT) value = formatToCNPJ(value);

    return (
      <Row>
        <Column>
          <Label>{translate(`items.${type}`)}</Label>
          {isLoading ? <Skeleton /> : <Value>{value}</Value>}
        </Column>
        <Link onClick={async () => navigator.clipboard.writeText(value)}>
          <Icon />
        </Link>
      </Row>
    );
  };

  const renderContent = () => {
    return (
      <Content>
        <Title>{translate('items.title')}</Title>

        <Divider />

        <ItemWrapper>
          {renderItem(PaymentItems.DOCUMENT)}
          {renderItem(PaymentItems.SOCIAL)}
          {renderItem(PaymentItems.AGENCY)}
          {renderItem(PaymentItems.ACCOUNT)}
          {renderItem(PaymentItems.BANK)}
        </ItemWrapper>

        <SecondaryButton label='Ver meus pedidos' onClick={goBackToOrders} />
      </Content>
    );
  };

  return (
    <FragmentCol>
      {renderPageHeader()}
      <Container>
        {renderHeader()}
        {renderContent()}
      </Container>
    </FragmentCol>
  );
};
