import { IconographyNames } from '@vizir-banking/design-system';
import {
  Button as BaseButton,
  ButtonSizes,
  ButtonTypes,
  Divider as BaseDivider,
  Icon as BaseIcon,
  Typography,
  TypographyVariants,
} from '@vizir-banking/design-system/web';
import styled from 'styled-components';

import { getColor } from '~/styles/theme/get-theme';

export const Container = styled.div`
  flex-direction: column;
`;

export const SheetModelWrapper = styled.div`
  width: 100%;
  margin: 24px 0;
`;

export const PrimaryButton = styled(BaseButton).attrs(() => ({
  buttonType: ButtonTypes.primary,
  buttonSize: ButtonSizes.medium,
  withLeadingIcon: true,
  leadingIconName: IconographyNames.download,
  leadingIconColor: getColor('neutral', 'darkest'),
}))`
  width: fit-content;
`;

export const DividerWrapper = styled.div`
  width: 100%;
  align-items: center;
  gap: 16px;
`;

export const Divider = styled(BaseDivider)`
  flex: 1;
  color: ${getColor('neutral', 'light')};
`;

export const DividerText = styled(Typography).attrs({
  variant: TypographyVariants.DIALOG,
})`
  color: ${getColor('neutral', 'dark')};
`;

export const Dialog = styled(Typography).attrs({
  variant: TypographyVariants.PARAGRAPH_S,
})`
  text-align: justify;
  color: ${getColor('neutral', 'darkest')};
`;

export const FileDragArea = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  flex: 1;

  padding: 64px 0;
  margin: 24px auto;
  gap: 16px;

  border: 2px dashed ${getColor('neutral', 'light')};
  background-color: ${getColor('neutral', 'lighter')};
  border-radius: 8px;
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
`;

export const Title = styled(Typography).attrs({
  variant: TypographyVariants.HEADING_XL,
})``;

export const SecondaryButton = styled(BaseButton).attrs({
  buttonType: ButtonTypes.primary,
  buttonSize: ButtonSizes.medium,
})``;

export const UploadIcon = styled(BaseIcon).attrs({
  color: getColor('neutral', 'darkest'),
  iconName: IconographyNames.upload,
})`
  width: 24px;
  height: 24px;
`;

export const Paragraph = styled(Typography).attrs({
  variant: TypographyVariants.PARAGRAPH_XS,
})`
  color: ${getColor('neutral', 'darker')};
`;

export const Emphasis = styled.span`
  color: ${getColor('neutral', 'darkest')};
  font-weight: bold;
  text-decoration: underline;
`;
