import { Typography, TypographyVariants } from '@vizir-banking/design-system/web';
import styled from 'styled-components';

import { Screen as BaseScreen } from '~/components/screen/screen';
import { ScreenTypes } from '~/components/screen/types';

export const Screen = styled(BaseScreen).attrs({
  type: ScreenTypes.LOGGED,
})``;

export const Title = styled(Typography).attrs({
  variant: TypographyVariants.HEADING_XL,
})``;
