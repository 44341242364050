import { Masks } from '@vizir-banking/design-system/web/components/text-field/types';
import { isEmpty, noop } from 'lodash';
import { ReactElement } from 'react';

import { Column, Container, LinkArea, Notification, SearchIcon, TextField } from './search.styles';

interface ISearch {
  label: string;
  placeholder: string;
  value: string;
  onChange: (value: string) => void;
  onClear: () => void;
  onClickSearch?: () => void;
  mask?: Masks;
  error?: string;
}

export const Search = ({
  label,
  placeholder,
  value,
  mask,
  onChange,
  onClear,
  onClickSearch = noop,
  error,
}: ISearch): ReactElement => {
  return (
    <Column>
      <Container>
        <LinkArea onClick={onClickSearch}>
          <SearchIcon />
        </LinkArea>
        <TextField
          label={label}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          onClearTextField={onClear}
          isFocused
          mask={mask}
          isMasked={!!mask}
        />
      </Container>
      <Notification
        leadingText={{
          label: error,
        }}
        showLeadingIcon={!isEmpty(error)}
      />
    </Column>
  );
};
