import { ButtonProps as BaseButtonProps } from '@vizir-banking/design-system/web';
import { ReactElement } from 'react';

import { Order, OrderDetails } from '~/api/requests';
import { useTranslation } from '~/translates/use-translate';

import { BalanceRechargePage } from './balance-recharge/balance-recharge';
import { NewRechargePage } from './new-recharge/new-recharge';
import { RechargePayment } from './payment/payment';
import { RechargePaymentType } from './payment-type/payment-type';
import { ReleaseOrderDialog, Screen } from './recharge.styles';
import { RechargeDetails } from './recharge-details/recharge-details';
import { RechargeSteps } from './types';

interface IRecharge {
  order: Order;
  currentStep: RechargeSteps;
  currentBalanceTabIndex: number;
  isSplittingOrder: boolean;
  isReleaseOrderModalVisible: boolean;
  data: OrderDetails;
  isDetailsLoading: boolean;
  onSplitOrderSubmit: () => void;
  toggleReleaseOrderModal: () => void;
  onSelectOrder: (order: Order) => void;
  onChangeStep: (step: RechargeSteps) => void;
  onChangeBalanceTabIndex: (tabIndex: number) => void;
}
type ButtonProps = Omit<BaseButtonProps, 'buttonSize' | 'buttonType'>;

export const RechargeLayout = ({
  order,
  currentStep,
  currentBalanceTabIndex,
  isReleaseOrderModalVisible,
  data,
  isSplittingOrder,
  isDetailsLoading,
  toggleReleaseOrderModal,
  onChangeStep,
  onChangeBalanceTabIndex,
  onSplitOrderSubmit,
  onSelectOrder,
}: IRecharge) => {
  const translate = useTranslation('pages.recharge');

  const primaryButtonProps: ButtonProps = {
    label: translate('releaseOrderModal.confirm'),
    onClick: onSplitOrderSubmit,
    isLoading: isSplittingOrder,
  };

  const tertiaryButtonProps: ButtonProps = {
    label: translate('releaseOrderModal.cancel'),
    onClick: toggleReleaseOrderModal,
  };

  const renderBalanceRecharge = () => {
    return (
      <BalanceRechargePage
        onChangeStep={onChangeStep}
        onSelectOrder={onSelectOrder}
        onToggleReleaseOrderModal={toggleReleaseOrderModal}
        currentBalanceTabIndex={currentBalanceTabIndex}
        onChangeBalanceTabIndex={onChangeBalanceTabIndex}
      />
    );
  };

  const renderNewRecharge = () => {
    return <NewRechargePage onChangeStep={onChangeStep} />;
  };

  const renderRechargeDetails = () => {
    return (
      <RechargeDetails
        onChangeStep={onChangeStep}
        data={data}
        isLoading={isDetailsLoading}
        order={order}
        toggleReleaseOrderModal={() => toggleReleaseOrderModal()}
      />
    );
  };

  const renderRechargePaymentType = () => {
    return <RechargePaymentType onChangeStep={onChangeStep} details={data} order={order} />;
  };

  const renderRechargePayment = () => {
    return <RechargePayment onChangeStep={onChangeStep} totalBalance={data.balances?.totalBalance} />;
  };

  const renderSteps = (): ReactElement => {
    const steps = {
      [RechargeSteps.BALANCE]: renderBalanceRecharge(),
      [RechargeSteps.NEW_RECHARGE]: renderNewRecharge(),
      [RechargeSteps.RECHARGE_DETAILS]: renderRechargeDetails(),
      [RechargeSteps.PAYMENT]: renderRechargePayment(),
      [RechargeSteps.PAYMENT_TYPE]: renderRechargePaymentType(),
    };

    return steps[currentStep];
  };

  return (
    <Screen>
      {renderSteps()}

      <ReleaseOrderDialog
        title={translate('releaseOrderModal.title')}
        isVisible={isReleaseOrderModalVisible}
        onOutsideClick={() => {
          toggleReleaseOrderModal();
        }}
        primaryButtonProps={primaryButtonProps}
        tertiaryButtonProps={tertiaryButtonProps}
      >
        {translate('releaseOrderModal.content')}
      </ReleaseOrderDialog>
    </Screen>
  );
};
