import { useEffect, useState } from 'react';

import { IFileData } from '../recharge.hook';
import { CreateOrderErrorMessageEnum } from './send/send.hook';

interface INewRecharge {
  currentTab: number;
  onChangeTab: (tab: number) => void;
  file: File | null;
  onChangeFile: (file: File) => void;
  stepHasError: boolean;
  onSetStepHasError: (hasError: boolean) => void;
  onChangeFileData: (data: IFileData) => void;
  fileErrors: { line: number; column: string; message: CreateOrderErrorMessageEnum }[];
  onChangeFileErrors: (
    errors: { line: number; column: string; message: CreateOrderErrorMessageEnum }[]
  ) => void;
}

export const useNewRecharge = (onChangeFileData: (data: IFileData) => void): INewRecharge => {
  const [currentTab, setCurrentTab] = useState(0);
  const [file, setFile] = useState<File | null>(null);
  const [fileErrors, setFileErrors] = useState<
    { line: number; column: string; message: CreateOrderErrorMessageEnum }[]
  >([]);
  const [stepHasError, setStepHasError] = useState(false);

  const onChangeTab = (step: number) => {
    setCurrentTab(step);
  };

  const onChangeFile = (file: File) => {
    setFile(file);
  };

  const onChangeFileErrors = (
    errors: { line: number; column: string; message: CreateOrderErrorMessageEnum }[]
  ) => {
    setFileErrors(errors);
  };

  useEffect(() => {
    if (fileErrors) {
      setStepHasError(true);
    }
  }, [fileErrors]);

  return {
    file,
    currentTab,
    stepHasError,
    onChangeTab,
    onChangeFile,
    onSetStepHasError: setStepHasError,
    onChangeFileData,
    fileErrors,
    onChangeFileErrors,
  };
};
