import { ReactElement } from 'react';

import { AddressDto } from '~/api/requests';

import { useNewCardsReviewHook } from './review.hook';
import { NewCardsReviewSection } from './review.layout';

interface CardState {
  quantity: string;
  date: string;
}

interface INewCards {
  card: CardState;
  address: AddressDto;
  onCancel: () => void;
}

export const NewCardsReview = ({ card, address, onCancel }: INewCards): ReactElement => {
  const props = useNewCardsReviewHook({ card, address });

  return <NewCardsReviewSection {...props} onCancel={onCancel} />;
};
