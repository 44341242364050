import { Link } from '@vizir-banking/design-system/web';
import { ReactElement } from 'react';

import { GetEmployerBasicInfoResponseDto, UserMeResponseDto } from '~/api/requests';
import { useTranslation } from '~/translates/use-translate';

import { EmployerInfo } from '../employer-info/employer-info';
import { UserAvatar } from '../user-avatar/user-avatar';
import {
  Column,
  Container,
  CustomSkeleton,
  Icon,
  Name,
  RightWrapper,
  Role,
  Row,
  Skeleton,
} from './top-bar.styles';

interface ITopBar {
  user?: UserMeResponseDto;
  employer?: GetEmployerBasicInfoResponseDto;
  logout: () => Promise<void>;
}

export const TopBar = ({ user, employer, logout }: ITopBar): ReactElement => {
  const translate = useTranslation('components.topBar');
  const key = user?.role ?? '';

  const isLoading = !(user && employer && employer.fullName && employer.cnpj);

  const renderLoading = (): ReactElement => {
    return (
      <Row>
        <Skeleton>
          <CustomSkeleton />
        </Skeleton>

        <Skeleton>
          <CustomSkeleton />
        </Skeleton>
      </Row>
    );
  };

  const renderUser = (): ReactElement => {
    return (
      <Row>
        <EmployerInfo document={employer?.cnpj} name={employer?.fullName} />
        <RightWrapper>
          <UserAvatar name={user?.name ?? ''} />
          <Column>
            <Name>{user?.name}</Name>
            <Role>{translate(key)}</Role>
          </Column>
          <Link onClick={logout}>
            <Icon />
          </Link>
        </RightWrapper>
      </Row>
    );
  };

  return <Container>{isLoading ? renderLoading() : renderUser()}</Container>;
};
