import { IconographyNames } from '@vizir-banking/design-system';
import {
  Button as BaseButton,
  ButtonSizes,
  ButtonTypes,
  Icon,
  Modal as BaseModal,
  ModalSizes,
  Skeleton as BaseSkeleton,
  SkeletonVariants,
  Tag as BaseTags,
  TagSizes,
  TagTypes,
  TagVariants,
  TextField as BaseTextField,
  Typography,
  TypographyVariants,
} from '@vizir-banking/design-system/web';
import styled from 'styled-components';

import { LeadingContentType, PageHeader as BasePageHeader } from '~/components/page-header/page-header';
import { Screen as BaseScreen } from '~/components/screen/screen';
import { ScreenTypes } from '~/components/screen/types';
import { getColor } from '~/styles/theme/get-theme';

const BaseIcon = styled(Icon)`
  width: 24px;
  height: 24px;
`;

const getWidth = (key: string) => {
  if (key === 'full') return 100;
  if (key === 'zipCode') return 40;
  if (key === 'street') return 60;
  if (key === 'city' || key === 'state') return 50;

  return 33;
};

export const Screen = styled(BaseScreen).attrs({
  type: ScreenTypes.LOGGED,
})``;

export const PageHeader = styled(BasePageHeader).attrs({
  leadingContentType: LeadingContentType.TEXT,
})``;

export const Section = styled.div`
  width: 100%;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  border: 1px solid ${getColor('neutral', 'medium')};
  border-radius: 8px;
  padding: 24px;
  gap: 16px;
  margin-top: 24px;
`;

export const SectionTitle = styled(Typography).attrs({
  variant: TypographyVariants.HEADING_XL,
})`
  color: ${getColor('neutral', 'darkest')};
  margin-bottom: 16px;
`;

export const AddressInfo = styled.div`
  width: 100%;
  display: flex;
  border: 2px solid ${getColor('neutral', 'light')};
  background-color: ${getColor('neutral', 'lighter')};
  padding: 16px 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
`;

export const AddressTitle = styled(Typography).attrs({
  variant: TypographyVariants.DIALOG,
})`
  color: ${getColor('neutral', 'darkest')};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Edit = styled(BaseIcon).attrs({
  iconName: IconographyNames.edit,
  color: getColor('neutral', 'darkest'),
})``;

export const SecondaryButton = styled(BaseButton).attrs(() => ({
  buttonType: ButtonTypes.secondary,
  buttonSize: ButtonSizes.medium,
  leadingIconName: IconographyNames.add,
  leadingIconColor: getColor('neutral', 'darkest'),
  withLeadingIcon: true,
}))``;

export const PrimaryButton = styled(BaseButton).attrs(() => ({
  buttonType: ButtonTypes.primary,
  buttonSize: ButtonSizes.medium,
}))``;

export const TertiaryButton = styled(BaseButton).attrs(() => ({
  buttonType: ButtonTypes.tertiary,
  buttonSize: ButtonSizes.small,
}))`
  color: ${getColor('error', 'medium')};
`;

export const Modal = styled(BaseModal).attrs({
  size: ModalSizes.large,
})`
  z-index: 1001;
  background-color: rgba(0, 0, 0, 0.5);
`;

export const TextField = styled(BaseTextField)<{ type: string }>`
  width: ${({ type }) => getWidth(type)}%;
  margin-bottom: 32px;
`;

export const FormTitle = styled(Typography).attrs({
  variant: TypographyVariants.HEADING_XXL,
})`
  color: ${getColor('neutral', 'darkest')};
`;

export const FormRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 16px;
`;

export const FormContent = styled.div`
  width: 100%;
  flex-direction: column;
`;

export const TextFieldSection = styled.div`
  width: 100%;
  flex-direction: column;
  margin-top: 48px;
`;

export const MainTag = styled(BaseTags).attrs({
  size: TagSizes.medium,
  type: TagTypes.neutral,
  variant: TagVariants.primary,
})``;

export const TagWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

export const Skeleton = styled(BaseSkeleton).attrs({
  variant: SkeletonVariants.textField,
})<{ type: string; hasMargin?: boolean }>`
  min-height: 56px;
  width: ${({ type }) => getWidth(type)}%;
  margin-bottom: ${({ hasMargin }) => (hasMargin ? '32px' : '0')};
`;

export const Heading = styled(Typography).attrs({
  variant: TypographyVariants.HEADING_M,
})`
  color: ${getColor('neutral', 'darkest')};
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 20px;
`;
