import { ReactElement } from 'react';

import { OrderBalances, OrderDetails } from '~/api/requests';
import { OrderBalancesEnum } from '~/pages/recharge/recharge-details/types';
import { FragmentCol } from '~/styles/global-styles';
import { useTranslation } from '~/translates/use-translate';

import { DotLeader } from '../dot-leader/dot-leader';
import { Divider, InnerSkeleton, Skeleton } from './order-summary.styles';

interface IOrderSummary {
  isLoading: boolean;
  data: OrderDetails;
}

export const OrderSummary = ({ isLoading, data }: IOrderSummary) => {
  const translate = useTranslation('pages.recharge.rechargeDetails');
  const renderSkeleton = () => {
    return (
      <Skeleton>
        <InnerSkeleton />
      </Skeleton>
    );
  };

  if (isLoading) return Array.from({ length: 3 }).map((_) => renderSkeleton());

  const renderBalances = (): ReactElement[] => {
    const returnData: OrderBalancesEnum[] = [];

    for (const [key] of Object.entries(OrderBalancesEnum)) {
      if (data.balances[key as keyof OrderBalances]) returnData.push(key as OrderBalancesEnum);
    }
    return returnData.map(
      (item): ReactElement => (
        <DotLeader
          leadingText={translate(`orderSummary.${item}`)}
          trailingText={data.balances[item as keyof OrderBalances]}
        />
      )
    );
  };
  return (
    <FragmentCol style={{ gap: 16 }}>
      <Divider />
      <DotLeader
        leadingText={translate('orderSummary.registeredCollaborators')}
        trailingText={data.employeesQty}
      />
      <Divider />
      {renderBalances()}
      <DotLeader
        leadingText={translate('orderSummary.totalBalance')}
        trailingText={data.balances.totalBalance}
        hasEmphasys
      />
    </FragmentCol>
  );
};
