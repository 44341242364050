import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useBatchProposalServiceBatchProposalControllerGetProposalBatches } from '~/api/queries';
import { GetProposalBatches, OpenAPI } from '~/api/requests';
import { SCREENS } from '~/app/routes/screen-definitions';
import { useOnReachEnd } from '~/hooks/use-reach-end';
import { useUserScopes } from '~/hooks/use-user-scopes';
import { selectToken } from '~/redux/reducers/user';

import { ListBatchesSteps } from '../types';

export const useBatchesView = (onChangeStep: (step: ListBatchesSteps, batchId?: string) => void) => {
  const token = useSelector(selectToken);
  const navigate = useNavigate();
  const { scopes } = useUserScopes();

  useOnReachEnd(() => {
    if (!onReachEndLoading && !listBatches.isPending && batches.length > 0) {
      setOnReachEndLoading(true);
      setCurrentPage((prev) => prev + 1);
    }
  });

  useEffect(() => {
    OpenAPI.HEADERS = {
      ...OpenAPI.HEADERS,
      authorization: `Bearer ${token}`,
    };
  }, [token]);

  const [batches, setBatches] = useState<GetProposalBatches[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [onReachEndLoading, setOnReachEndLoading] = useState(false);
  const [enabled, setEnabled] = useState(true);

  const goToAddCollaborators = () => {
    navigate(SCREENS.addBatchCollaborators);
  };

  const listBatches = useBatchProposalServiceBatchProposalControllerGetProposalBatches(
    {
      page: currentPage,
    },
    [currentPage],
    { enabled }
  );

  useEffect(() => {
    if (listBatches.data) {
      const newBatches = listBatches.data?.data;
      currentPage === 1 ? setBatches(newBatches) : setBatches((prev) => [...prev, ...newBatches]);
    }

    setOnReachEndLoading(false);

    if (listBatches.isError || listBatches.data?.data.length === 0) {
      setEnabled(false);
    }
  }, [listBatches.data, currentPage]);

  useEffect(() => {
    const { isPending } = listBatches;

    setOnReachEndLoading(isPending && currentPage > 1 && enabled);
  }, [listBatches.isPending, currentPage]);

  const handleBatchClick = (batchId: string) => {
    onChangeStep(ListBatchesSteps.DETAILS, batchId);
    setBatches([]);
  };

  return {
    goToAddCollaborators,
    batches,
    scopes,
    isLoading: listBatches.isPending && currentPage === 1 && enabled,
    handleBatchClick,
    onReachEndLoading,
  };
};
