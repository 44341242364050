import { IconographyNames } from '@vizir-banking/design-system';
import { AccordionDetailsProps } from '@vizir-banking/design-system/web/components/accordion/types';
import { ReactElement } from 'react';
import { useSelector } from 'react-redux';

import { ScopeEnum } from '~/api/constants';
import { ScopeEnum as ScopeTypes } from '~/api/requests';
import { SideBarLogo } from '~/assets/images-mapping';
import { useUserScopes } from '~/hooks/use-user-scopes';
import { selectCurrentUser } from '~/redux/reducers/user';
import { useTranslation } from '~/translates/use-translate';

import { Accordion, Container, Image, ListItem, Wrapper } from './side-bar.styles';
import { SideBarDropdown, SideBarTabs } from './types';

interface Dropdown {
  collaborators: boolean;
  settings: boolean;
}

interface ISideBar {
  scopes?: ScopeTypes[];
  dropdown: Dropdown;
  isActive: (tab: SideBarTabs) => boolean;
  navigateTo: (tab: SideBarTabs) => void;
}

export const SideBar = ({ dropdown, isActive, navigateTo }: ISideBar): ReactElement => {
  const translate = useTranslation('components.sideBar');
  const { hasScope } = useUserScopes();

  const user = useSelector(selectCurrentUser);

  const getIcon = (key: SideBarTabs): IconographyNames => {
    const icons: { [key in SideBarTabs]: IconographyNames } = {
      [SideBarTabs.HOME]: IconographyNames.house,
      [SideBarTabs.COLLABORATORS]: IconographyNames.menu,
      [SideBarTabs.LIST_BATCHES]: IconographyNames.add,
      [SideBarTabs.RECHARGE]: IconographyNames.coin,
      [SideBarTabs.PERMISSIONS]: IconographyNames.lock,
      [SideBarTabs.CARDS]: IconographyNames.creditCard,
      [SideBarTabs.BUSINESS_ADDRESS]: IconographyNames.bus,
    };

    return icons[key];
  };

  const getDropdownIcon = (key: SideBarDropdown) => {
    const icons: { [key in SideBarDropdown]: IconographyNames } = {
      [SideBarDropdown.COLLABORATORS]: IconographyNames.user,
      [SideBarDropdown.SETTINGS]: IconographyNames.settings,
    };

    return icons[key];
  };

  const getDropdownActive = (key: SideBarDropdown): boolean => {
    const { collaborators, settings } = dropdown;
    const dropdowns: { [key in SideBarDropdown]: boolean } = {
      [SideBarDropdown.COLLABORATORS]: collaborators,
      [SideBarDropdown.SETTINGS]: settings,
    };

    return dropdowns[key];
  };

  const renderOption = (key: SideBarTabs, hasDropdown?: boolean): ReactElement => {
    return (
      <ListItem
        leadingIconName={getIcon(key)}
        primaryText={translate(`${key}`)}
        hasDropdown={hasDropdown}
        isActive={isActive(key)}
        onClick={() => navigateTo(key)}
      />
    );
  };

  const renderOptions = (options: ReactElement | ReactElement[]): AccordionDetailsProps[] => {
    const sanitizedOptions = Array.isArray(options) ? options : [options];
    return sanitizedOptions.map((option) => {
      return {
        hasContentPadding: false,
        renderContent: () => option,
        customBorderColor: 'transparent',
        customBackgroundColor: 'transparent',
      };
    });
  };

  const renderDropdown = (key: SideBarDropdown, options: ReactElement | ReactElement[]) => {
    return (
      <Accordion
        accordionContentProps={{
          accordionHeaderProps: {
            hasLeadingIcon: true,
            leadingIconName: getDropdownIcon(key),
            summaryLabel: translate(`${key}`),
            summaryProps: {
              children: translate(`${key}`),
              paragraphProps: {
                style: {
                  color: 'white',
                },
              },
            },
            iconColor: 'white',
            containerProps: {
              style: {
                width: '100%',
              },
            },
            summaryContainerProps: {
              style: {
                alignSelf: 'strecht',
                padding: 0,
                paddingLeft: 8,
              },
            },
            iconContainer: {
              style: {
                padding: 0,
                paddingLeft: 24,
                paddingRight: 24,
              },
            },
            customBorderColor: 'transparent',
            customBackgroundColor: 'transparent',
          },
          accordionDetailsProps: renderOptions(options),
        }}
        isActive={getDropdownActive(key) ? true : undefined}
      />
    );
  };

  const settingsOptions = (): ReactElement[] => {
    const opt: ReactElement[] = [];

    if (hasScope(ScopeEnum.LIST_USER)) opt.push(renderOption(SideBarTabs.PERMISSIONS, true));
    if (hasScope(ScopeEnum.LIST_ADDRESS_EMPLOYER)) opt.push(renderOption(SideBarTabs.BUSINESS_ADDRESS, true));

    return opt;
  };

  return (
    <Container>
      {user && (
        <Wrapper>
          <Image src={SideBarLogo} />
          {renderOption(SideBarTabs.HOME)}
          {hasScope(ScopeEnum.LIST_EMPLOYEE) &&
            renderDropdown(SideBarDropdown.COLLABORATORS, [
              renderOption(SideBarTabs.COLLABORATORS, true),
              renderOption(SideBarTabs.LIST_BATCHES, true),
            ])}
          {hasScope(ScopeEnum.LIST_ORDER) && renderOption(SideBarTabs.RECHARGE)}
          {hasScope(ScopeEnum.LIST_REQUEST_CARDS) && renderOption(SideBarTabs.CARDS)}
          {renderDropdown(SideBarDropdown.SETTINGS, settingsOptions())}
        </Wrapper>
      )}
    </Container>
  );
};
