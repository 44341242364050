import { UseQueryResult } from '@tanstack/react-query';
import { isCPF } from 'brazilian-values';
import { isEmpty } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useEmployeeServiceEmployeeControllerListEmployees } from '~/api/queries';
import { ListEmployeesResponseDto, SimplifiedEmployees } from '~/api/requests';
import { ButtonProps } from '~/components/dialogs/success-dialog/success-dialog';
import { clearSuccess, success } from '~/redux/reducers/application';
import { hasTranslate, useTranslation } from '~/translates/use-translate';
import { unformatDocument } from '~/utils/unformat-document';

import { CollaboratorsSteps } from './types';

interface ICollaborators {
  currentStep: CollaboratorsSteps;
  personId: string;
  closeModalProps?: ButtonProps;
  onChangeStep: (
    step: CollaboratorsSteps,
    id?: string,
    collaboratorPosition?: {
      index: number;
      page: number;
    }
  ) => void;
  allCollaborators: SimplifiedEmployees[];
  updateCollaborators: (collaborators: SimplifiedEmployees[], page: number) => void;
  getCollaborators: UseQueryResult<ListEmployeesResponseDto, unknown>;
  page: number;
  updatePage: () => void;
  collaboratorPosition: {
    index: number;
    page: number;
  };
  document: string;
  setDocument: (document: string) => void;
  documentError: string;
  setDocumentError: (error: string) => void;
  enabled: boolean;
  setEnabled: (enabled: boolean) => void;
  USERS_PER_PAGE: number;
}

export const USERS_PER_PAGE = 20;

export const useCollaborators = (): ICollaborators => {
  const dispatch = useDispatch();
  const translate = useTranslation('success');

  const successCode = useSelector(success);
  const key = hasTranslate(`success.${successCode}`) ? successCode : 'generic_success';
  const [currentStep, setCurrentStep] = useState(CollaboratorsSteps.VIEW);
  const [personId, setPersonId] = useState('');
  const [allCollaborators, setAllCollaborators] = useState<SimplifiedEmployees[]>([]);
  const [documentError, setDocumentError] = useState('');
  const [document, setDocument] = useState<string>('');
  const [page, setPage] = useState(1);
  const [enabled, setEnabled] = useState(true);
  const [collaboratorPosition, setCollaboratorPosition] = useState<{
    index: number;
    page: number;
  }>({
    index: 0,
    page: 1,
  });

  const getDocument = useCallback(() => {
    return document.length === 14 && isEmpty(documentError) ? unformatDocument(document) : undefined;
  }, [document]);

  const getCollaborators = useEmployeeServiceEmployeeControllerListEmployees(
    {
      page,
      perPage: USERS_PER_PAGE,
      document: getDocument(),
    },
    ['listEmployees', page, document],
    { enabled }
  );

  const onChangeStep = (
    step: CollaboratorsSteps,
    id?: string,
    collaboratorPosition?: {
      index: number;
      page: number;
    }
  ) => {
    setCurrentStep(step);
    setPersonId(id ?? '');
    setCollaboratorPosition(collaboratorPosition ?? { index: 0, page: 1 });
  };

  const onClick = async (): Promise<void> => {
    dispatch(clearSuccess());
    onChangeStep(CollaboratorsSteps.VIEW);
  };

  const closeModalProps: ButtonProps = {
    label: translate(`${key}.button`),
    onClick,
  };

  const updateCollaborators = (newCollaborators: SimplifiedEmployees[], page: number): void => {
    setAllCollaborators((prev) => {
      const filteredPrev = prev.filter(
        (existing) => !newCollaborators.some((collaborator) => collaborator.personId === existing.personId)
      );
      return page === 1 ? newCollaborators : [...filteredPrev, ...newCollaborators];
    });
  };

  const updatePage = (): void => {
    setPage((prev) => prev + 1);
  };

  useEffect(() => {
    setEnabled((document?.length === 14 || document?.length === 0) && (!document || isCPF(document)));

    if (document.length === 14) {
      if (!isCPF(document)) {
        setDocumentError(translate('error.document'));
      } else {
        setPage(1);
        if (setAllCollaborators) setAllCollaborators([]);
      }
    }
  }, [document]);
  return {
    currentStep,
    personId,
    closeModalProps,
    onChangeStep,
    allCollaborators,
    updateCollaborators,
    document,
    setDocument,
    documentError,
    setDocumentError,
    enabled,
    setEnabled,
    page,
    updatePage,
    collaboratorPosition,
    getCollaborators,
    USERS_PER_PAGE,
  };
};
