import { IconographyNames } from '@vizir-banking/design-system';
import {
  Button as BaseButton,
  ButtonSizes,
  ButtonTypes,
  Skeleton as BaseSkeleton,
  SkeletonVariants,
  TextField as BaseTextField,
  Typography,
  TypographyVariants,
} from '@vizir-banking/design-system/web';
import styled from 'styled-components';

import { getColor } from '~/styles/theme/get-theme';

export const Container = styled.div`
  flex: 1;
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 8px;
`;

export const SectionTitle = styled(Typography).attrs({
  variant: TypographyVariants.SUBTITLE,
})`
  color: ${getColor('neutral', 'darkest')};
  font-weight: 800;
  margin-bottom: 8px;
`;

export const TextField = styled(BaseTextField)`
  width: 40%;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TertiaryButton = styled(BaseButton).attrs({
  buttonType: ButtonTypes.tertiary,
  buttonSize: ButtonSizes.small,
})`
  color: ${getColor('error', 'medium')};
`;

export const PrimaryButton = styled(BaseButton).attrs(() => ({
  buttonType: ButtonTypes.primary,
  buttonSize: ButtonSizes.medium,
  trailingIconName: IconographyNames.chevronRight,
  trailingIconColor: getColor('neutral', 'darkest'),
  withTrailingIcon: true,
}))``;

export const Subtitle = styled(Typography).attrs({
  variant: TypographyVariants.SUBTITLE,
})`
  color: ${getColor('neutral', 'darkest')};
  display: inline;
`;

export const SubtitleBold = styled(Subtitle)`
  font-weight: 800;
`;

export const SkeletonField = styled(BaseSkeleton).attrs({
  variant: SkeletonVariants.textField,
})`
  max-height: 25px;
`;

export const SkeletonBanner = styled(BaseSkeleton).attrs({
  variant: SkeletonVariants.button,
})`
  width: 100%;
  border-radius: 8px;
`;
