import { ScopeEnum } from '~/api/constants';
import { Order } from '~/api/requests';
import { ErrorDialog } from '~/components/dialogs/error-dialog/error-dialog';
import { SuccessDialog } from '~/components/dialogs/success-dialog/success-dialog';
import { RechargeTable } from '~/components/tables/recharge-table/recharge-table';
import { FragmentCol } from '~/styles/global-styles';
import { useTranslation } from '~/translates/use-translate';

import { RechargeSteps } from '../types';
import { PageHeader, Tabs } from './balance-recharge.styles';

interface IBalanceRecharge {
  recharges: Order[];
  isLoading: boolean;
  isOnEndLoading: boolean;
  onChangeTab: (tabIndex: number) => void;
  onSelectOrder?: (order: Order) => void;
  onChangeStep: (step: RechargeSteps) => void;
  onToggleReleaseOrderModal?: (id?: string) => void;
  currentBalanceTabIndex: number;
}

export const BalanceRechargeLayout = ({
  recharges,
  isLoading,
  isOnEndLoading,
  onChangeTab,
  onChangeStep,
  onSelectOrder,
  onToggleReleaseOrderModal,
  currentBalanceTabIndex,
}: IBalanceRecharge) => {
  const translate = useTranslation('pages.recharge.balanceRecharge');
  const tabLabels = ['all', 'done', 'pending', 'ready', 'cancelled'];

  const renderHeader = () => {
    return (
      <PageHeader
        title={translate('subtitle')}
        verifyScope={{
          scope: ScopeEnum.CREATE_ORDER,
        }}
        trailingButton={{
          label: translate('button'),
          onClick: () => onChangeStep(RechargeSteps.NEW_RECHARGE),
        }}
      />
    );
  };

  const renderContent = () => {
    return (
      <RechargeTable
        recharges={recharges}
        isLoading={isLoading}
        isOnEndLoading={isOnEndLoading}
        onChangeStep={onChangeStep}
        onSelectOrder={onSelectOrder}
        onToggleReleaseOrderModal={onToggleReleaseOrderModal}
      />
    );
  };

  const renderTabs = tabLabels.map((label) => ({
    label: translate(`tabs.${label}`),
    element: renderContent(),
  }));

  return (
    <FragmentCol>
      {renderHeader()}
      <Tabs onChange={onChangeTab} items={renderTabs} activeTabIndex={currentBalanceTabIndex} />
      <SuccessDialog />
      <ErrorDialog />
    </FragmentCol>
  );
};
