import { ReactElement } from 'react';

import { BatchDetails } from './batch-details/batch-details';
import { BatchesView } from './batches-view/batches-view';
import { ListBatchesProps } from './list-batches.hook';
import { Screen } from './list-batches.styles';
import { ListBatchesSteps } from './types';

export const ListBatchesLayout = ({ currentStep, onChangeStep, detailedBatchId }: ListBatchesProps) => {
  const renderListBatchesViewer = (): ReactElement => {
    return <BatchesView onChangeStep={onChangeStep} />;
  };

  const renderBatchDetails = (): ReactElement => {
    return <BatchDetails onChangeStep={onChangeStep} batchId={detailedBatchId} />;
  };

  const renderSteps = (): ReactElement => {
    const steps = {
      [ListBatchesSteps.VIEW]: renderListBatchesViewer(),
      [ListBatchesSteps.DETAILS]: renderBatchDetails(),
    };

    return steps[currentStep];
  };

  return <Screen>{renderSteps()}</Screen>;
};
