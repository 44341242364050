import { IconographyNames } from '@vizir-banking/design-system';
import {
  Button,
  ButtonSizes,
  ButtonTypes,
  Card as BaseCard,
  Dialog,
  DialogVariants,
  FeedbackType,
  ModalSizes,
  Typography,
  TypographyVariants,
} from '@vizir-banking/design-system/web';
import styled from 'styled-components';

import { getColor } from '~/styles/theme/get-theme';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const Card = styled(BaseCard)`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const Title = styled(Typography).attrs({
  variant: TypographyVariants.HEADING_XL,
})``;

export const MainButton = styled(Button).attrs(({}) => ({
  buttonSize: ButtonSizes.large,
  buttonType: ButtonTypes.primary,
  withLeadingIcon: true,
  leadingIconName: IconographyNames.email,
  leadingIconColor: getColor('neutral', 'darkest'),
}))``;

export const SideButton = styled(Button).attrs({
  buttonSize: ButtonSizes.large,
  buttonType: ButtonTypes.secondary,
})`
  color: ${getColor('neutral', 'darkest')};
  border-color: ${getColor('neutral', 'darkest')};
`;

export const ButtonWrapper = styled.div<{ hasBoth: boolean }>`
  display: flex;
  justify-content: ${({ hasBoth }) => (hasBoth ? 'space-between' : 'flex-end')};
`;

export const ConfirmationDialog = styled(Dialog).attrs({
  size: ModalSizes.small,
  variant: DialogVariants.feedback,
  feedbackType: FeedbackType.warning,
})``;

export const SuccessDialog = styled(Dialog).attrs({
  size: ModalSizes.small,
  variant: DialogVariants.feedback,
  feedbackType: FeedbackType.success,
})``;
