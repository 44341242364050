import { IconographyNames } from '@vizir-banking/design-system';
import {
  Button as BaseButton,
  ButtonSizes,
  ButtonTypes,
  Typography,
  TypographyVariants,
} from '@vizir-banking/design-system/web';
import styled from 'styled-components';

import { getColor } from '~/styles/theme/get-theme';

export const Container = styled.div`
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
`;

export const Title = styled(Typography).attrs({
  variant: TypographyVariants.SUBTITLE,
})`
  color: ${getColor('neutral', 'darkest')};
  font-weight: 800;
`;

export const Section = styled.div`
  width: 100%;
  margin-top: 24px;
  gap: 24px;
  flex-direction: column;
`;

export const SectionWrapper = styled.div`
  width: 100%;
  flex-direction: column;
  border: 1px solid ${getColor('neutral', 'light')};
  padding: 16px 32px;
  border-radius: 8px;
  gap: 16px;
`;

export const Paragraph = styled(Typography).attrs({
  variant: TypographyVariants.PARAGRAPH_XS,
})`
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const Dialog = styled(Typography).attrs({
  variant: TypographyVariants.DIALOG,
})``;

export const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
`;

export const TertiaryButton = styled(BaseButton).attrs({
  buttonType: ButtonTypes.tertiary,
  buttonSize: ButtonSizes.small,
})`
  color: ${getColor('error', 'medium')};
`;

export const PrimaryButton = styled(BaseButton).attrs(() => ({
  buttonType: ButtonTypes.primary,
  buttonSize: ButtonSizes.medium,
  trailingIconName: IconographyNames.chevronRight,
  trailingIconColor: getColor('neutral', 'darkest'),
  withTrailingIcon: true,
}))``;
