import { useNewCardsQuantityHook } from './quantity.hook';
import { NewCardsQuantitySection } from './quantity.layout';

interface CardState {
  quantity: string;
  date: string;
}

interface INewCards {
  card: CardState;
  onCancel: () => void;
  handleData: (name: string, value: string) => void;
  onChangeTab: (tab: number) => void;
}

export const NewCardsQuantity = ({ card, onCancel, onChangeTab, handleData }: INewCards) => {
  const props = useNewCardsQuantityHook({ card, handleData, onChangeTab });

  return <NewCardsQuantitySection {...props} onCancel={onCancel} />;
};
