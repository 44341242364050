import { useEffect, useState } from 'react';

import { OrderStatusEnum } from '~/api/constants';
import { useLoadOrderServiceOrderControllerListOrders } from '~/api/queries';
import { Order, OrderStatusEnum as OrderStatusEnumType } from '~/api/requests';
import { useOnReachEnd } from '~/hooks/use-reach-end';

interface IBalanceRecharge {
  recharges: Order[];
  isLoading: boolean;
  isOnEndLoading: boolean;
  onChangeTab: (tabIndex: number) => void;
}
const ITEMS_PER_PAGE = 10;

const INITIAL_ORDER_STATE: Order = {
  id: '',
  status: OrderStatusEnum.UNKNOWN,
  amount: '',
  createdAt: '',
  splitMode: 'manual',
  splitScheduleDate: '',
};

export const useBalanceRecharge = ({
  currentBalanceTabIndex,
  onChangeBalanceTabIndex,
}: {
  currentBalanceTabIndex: number;
  onChangeBalanceTabIndex: (tabIndex: number) => void;
}): IBalanceRecharge => {
  const getStatus = (tabIndex: number): OrderStatusEnumType | undefined => {
    const statusMap: { [key: number]: OrderStatusEnumType | undefined } = {
      0: undefined,
      1: OrderStatusEnum.PROCESSED,
      2: OrderStatusEnum.PENDING_PAYMENT,
      3: OrderStatusEnum.READY_TO_RELEASE,
      4: OrderStatusEnum.CANCELED,
    };

    return statusMap[tabIndex];
  };

  const INITIAL_STATE = {
    onReachEndLoading: false,
    enabled: true,
    orders: [] as Order[],
    page: 1,
    order: INITIAL_ORDER_STATE,
    status: getStatus(currentBalanceTabIndex),
  };

  const [state, setState] = useState(INITIAL_STATE);

  const {
    data: ordersData,
    refetch: refetchOrders,
    isLoading,
    isPending,
    isFetching,
  } = useLoadOrderServiceOrderControllerListOrders(
    {
      page: state.page,
      perPage: ITEMS_PER_PAGE,
      status: state.status,
    },
    ['listOrders', state.page, state.status],
    { enabled: state.enabled, staleTime: 0 }
  );

  const filterRecharges = (tabIndex: number): Order[] => {
    const statusMap: { [key: number]: OrderStatusEnumType[] } = {
      1: [OrderStatusEnum.PROCESSED, OrderStatusEnum.PARTIAL_PROCESSED],
      2: [OrderStatusEnum.PENDING_PAYMENT],
      3: [OrderStatusEnum.READY_TO_RELEASE],
      4: [OrderStatusEnum.CANCELED, OrderStatusEnum.FAILED],
    };

    if (statusMap[tabIndex]) {
      return ordersData?.data.filter((recharge) => statusMap[tabIndex].includes(recharge.status)) ?? [];
    }
    return ordersData?.data ?? [];
  };

  const recharges = filterRecharges(currentBalanceTabIndex);

  const onChangeTab = (tabIndex: number) => {
    onChangeBalanceTabIndex(tabIndex);
    setState((prev) => ({ ...prev, page: 1, orders: [], status: getStatus(tabIndex) }));
    refetchOrders();
  };

  useEffect(() => {
    if (ordersData || state.page === 1) {
      const newOrders = recharges;

      setState((prev) => {
        let updatedOrders = newOrders;

        if (prev.page !== 1) {
          updatedOrders = [
            ...prev.orders.filter(
              (existingOrder) => !newOrders.some((newOrder) => newOrder.id === existingOrder.id)
            ),
            ...newOrders,
          ];
        }

        return {
          ...prev,
          orders: updatedOrders,
          onReachEndLoading: false,
          enabled: newOrders.length >= ITEMS_PER_PAGE,
        };
      });
    }
  }, [ordersData, state.page, state.status]);

  useOnReachEnd(() => {
    if (!state.onReachEndLoading && !isLoading && state.orders.length > 0 && state.enabled) {
      setState((prev) => ({ ...prev, onReachEndLoading: true, page: prev.page + 1 }));
    }
  });

  return {
    recharges: state.orders,
    isLoading: (isPending || isFetching) && state.page === 1 && state.enabled,
    isOnEndLoading: state.onReachEndLoading,
    onChangeTab,
  };
};
