import { Dialog, DialogVariants, FeedbackType, ModalSizes } from '@vizir-banking/design-system/web';
import styled from 'styled-components';

import { Screen as BaseScreen } from '~/components/screen/screen';
import { ScreenTypes } from '~/components/screen/types';

export const Screen = styled(BaseScreen).attrs({
  type: ScreenTypes.LOGGED,
})``;

export const ReleaseOrderDialog = styled(Dialog).attrs(
  ({ theme, primaryButtonProps, tertiaryButtonProps }) => ({
    size: ModalSizes.small,
    variant: DialogVariants.feedback,
    feedbackType: FeedbackType.warning,
    primaryButtonProps: {
      ...primaryButtonProps,
      containerProps: {
        style: {
          width: '100%',
        },
      },
    },
    tertiaryButtonProps: {
      ...tertiaryButtonProps,
      containerProps: {
        style: {
          color: theme.designSystem.palette.error.medium,
          width: '100%',
        },
      },
    },
  })
)``;
