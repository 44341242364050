import { TableBody, TableHead, TagSizes, TagTypes, TagVariants } from '@vizir-banking/design-system/web';

import { BatchProposalStatusEnum } from '~/api/constants';
import { GetProposalBatches } from '~/api/requests';
import { IOptions } from '~/components/dropdown/dropdown';
import { DropdownProvider } from '~/components/dropdown/dropdown-context';
import { useTranslation } from '~/translates/use-translate';
import { dateFormatter } from '~/utils/format-date';

import {
  CalendarIcon,
  DateWrapper,
  InnerSkeleton,
  Skeleton,
  TableCell,
  TableContainer,
  TableHeadCell,
  TableHeadRow,
  TableRow,
  TableTagCell,
} from './batches-table.styles';

interface IBatchesTable {
  batches: GetProposalBatches[];
  isLoading: boolean;
  handleBatchClick: (id: string) => void;
  onReachEndLoading: boolean;
}

export const BatchesTable = ({ batches, isLoading, handleBatchClick, onReachEndLoading }: IBatchesTable) => {
  const translate = useTranslation('components.table.batches');

  const renderTableHead = () => {
    return (
      <TableHeadRow>
        <TableHeadCell>{translate('table.status')}</TableHeadCell>
        <TableHeadCell>{translate('table.date')}</TableHeadCell>
      </TableHeadRow>
    );
  };

  const renderTableBody = (batches: GetProposalBatches[]) => {
    return batches.map((batch) => {
      const options: IOptions[] = [];
      if (batch.status !== 'processing') {
        options.push({
          label: translate('table.actionsCodes.viewDetails'),
          onAction: () => {
            handleBatchClick(batch.id);
          },
        });
      }

      const tagStatus: { [key: string]: TagTypes } = {
        [BatchProposalStatusEnum.FAILED]: TagTypes.negative,
        [BatchProposalStatusEnum.PARTIAL_PROCESSED]: TagTypes.warning,
        [BatchProposalStatusEnum.PROCESSED]: TagTypes.success,
        [BatchProposalStatusEnum.PROCESSING]: TagTypes.warning,
      };

      const tagProps = {
        size: TagSizes.large,
        type: tagStatus[batch.status],
        variant: TagVariants.primary,
      };

      return (
        <TableRow
          onClick={() => {
            if (batch.status === 'processing') return;
            handleBatchClick(batch.id);
          }}
        >
          <TableTagCell tagProps={tagProps} status={batch.status}>
            {translate(`table.statusCodes.${batch.status}`)}
          </TableTagCell>
          <TableCell status={batch.status}>
            <DateWrapper>
              <CalendarIcon />
              {dateFormatter(new Date(batch.createdAt))}
            </DateWrapper>
          </TableCell>
        </TableRow>
      );
    });
  };

  const renderLoading = () => {
    const renderSkeletonCell = () => {
      return (
        <TableCell>
          <Skeleton>
            <InnerSkeleton />
          </Skeleton>
        </TableCell>
      );
    };

    const renderSkeletonRow = () => {
      return (
        <TableRow>
          {renderSkeletonCell()}
          {renderSkeletonCell()}
        </TableRow>
      );
    };

    return Array.from({ length: 3 }).map((_) => renderSkeletonRow());
  };

  return (
    <DropdownProvider>
      <TableContainer>
        <TableHead>{renderTableHead()}</TableHead>
        <TableBody>
          {isLoading ? renderLoading() : renderTableBody(batches)}
          {onReachEndLoading && renderLoading()}
        </TableBody>
      </TableContainer>
    </DropdownProvider>
  );
};
