import { Tabs as BaseTabs } from '@vizir-banking/design-system/web';
import styled from 'styled-components';

import { PageHeader as BasePageHeader, TrailingContentType } from '~/components/page-header/page-header';

export const Tabs = styled(BaseTabs)`
  margin-top: 24px;
`;

export const PageHeader = styled(BasePageHeader).attrs({
  trailingContentType: TrailingContentType.BUTTON,
})``;
