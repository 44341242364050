import { useQueryClient } from '@tanstack/react-query';
import { formatToCEP, formatToPhone } from 'brazilian-values';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { ScopeEnum } from '~/api/constants';
import {
  useEmployeeServiceEmployeeControllerGetEmployee,
  useEmployeeServiceEmployeeControllerUpdateEmployee,
} from '~/api/queries';
import { AddressDto, ApiError, EmailDto, EmployeeDto, PhoneDto } from '~/api/requests';
import { useUserScopes } from '~/hooks/use-user-scopes';
import { setError, setSuccess } from '~/redux/reducers/application';
import { SuccessEnum } from '~/translates/success/types';
import { formatPhone } from '~/utils/format-phone';

import { ICollaboratorsSteps } from '../types';
import userSchema from './profile.schema';

type BoxType = 'contact' | 'address';

interface IContactFields {
  email?: string;
  phoneNumber?: string;
  [key: string]: string | undefined;
}

interface ICollaboratorProfile {
  user?: EmployeeDto;
  shouldUpdateCollaborator: boolean;
  isLoading: boolean;
  isUpdateLoading: boolean;
  isContactEditMode: boolean;
  isAddressEditMode: boolean;
  contact?: IContactFields;
  errors?: IContactFields;
  address: AddressDto;
  isButtonDisabled: (box: BoxType) => boolean;
  handleEdit: (box: BoxType) => void;
  handleData: (box: BoxType, name: string, value?: string) => void;
  onSubmit: (box: BoxType) => Promise<void>;
}

const ADDRESS_INITIAL_STATE: AddressDto = {
  id: '',
  city: '',
  complement: '',
  country: '',
  neighborhood: '',
  number: '',
  state: '',
  street: '',
  zipCode: '',
};

export const useCollaboratorProfile = ({ personId }: ICollaboratorsSteps): ICollaboratorProfile => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { hasScope } = useUserScopes();

  const [isContactEditMode, setIsContactEditMode] = useState(false);
  const [isAddressEditMode, setIsAddressEditMode] = useState(false);
  const [enabled, setEnabled] = useState(true);
  const [contact, setContact] = useState<IContactFields>();
  const [address, setAddress] = useState<AddressDto>(ADDRESS_INITIAL_STATE);
  const [errors, setErrors] = useState<IContactFields>({});

  const collaborator = useEmployeeServiceEmployeeControllerGetEmployee(
    { id: personId ?? '' },
    ['getEmployee', personId],
    { enabled }
  );

  const updateCollaborator = useEmployeeServiceEmployeeControllerUpdateEmployee({
    onSuccess: () => {
      dispatch(setSuccess(SuccessEnum.COLLABORATOR_UPDATED));
      setEnabled(true);
      queryClient.invalidateQueries({
        queryKey: ['listEmployees', 'getEmployee'],
      });
    },
    onError: (err: ApiError) => {
      dispatch(setError(err.body.code));
    },
  });

  useEffect(() => {
    if (collaborator.data?.phone && collaborator.data?.email) {
      const { email, phone } = collaborator.data ?? {};

      setContact({
        email: email?.email,
        phoneNumber: formatToPhone(phone?.phoneNumber.slice(3, phone?.phoneNumber.length)),
      });
    }

    if (collaborator?.data?.address) {
      setAddress(collaborator.data.address);
    }

    setEnabled(false);
  }, [collaborator.data]);

  useEffect(() => {
    const validation = userSchema.safeParse(contact);

    if (!validation.success) {
      const { email, phoneNumber } = validation.error.flatten().fieldErrors;
      setErrors({
        email: contact?.email && email?.[0],
        phoneNumber: contact?.phoneNumber && phoneNumber?.[0],
      });
    } else {
      setErrors({});
    }
  }, [contact]);

  const handleContactData = useCallback((name: string, value?: string) => {
    setContact((prev) => ({
      ...prev,
      [name]: name === 'phoneNumber' && value ? formatToPhone(value) : value,
    }));
  }, []);

  const handleAddressData = useCallback((name: string, value?: string) => {
    setAddress((prev) => ({ ...prev, [name]: value }));
  }, []);

  const handleData = useCallback(
    (box: BoxType, name: string, value?: string) => {
      if (name === 'number') value = value?.replace(/\D/g, '');
      if (name === 'state') value = value?.replace(/[^a-zA-Z\s\.]/g, '');
      if (name === 'country') value = value?.replace(/[^a-zA-Z\s\.]/g, '');
      if (name === 'zipCode') value = formatToCEP(value ?? '');

      if (box === 'contact') handleContactData(name, value);
      if (box === 'address') handleAddressData(name, value);
    },
    [handleContactData, handleAddressData]
  );

  const handleEdit = useCallback((box: BoxType) => {
    if (box === 'contact') {
      setIsContactEditMode((prev) => !prev);
    } else if (box === 'address') {
      setIsAddressEditMode((prev) => !prev);
    }
  }, []);

  const isButtonDisabled = useCallback(
    (box: BoxType): boolean => {
      if (box === 'contact') {
        const hasErrors = !!errors.email || !!errors.phoneNumber;
        const hasAtLeastOneFieldFilled = !!contact?.email || !!contact?.phoneNumber;
        return !hasAtLeastOneFieldFilled || hasErrors;
      }

      if (box === 'address') {
        const { zipCode, street, country, neighborhood, number, city, state } = address;
        return [zipCode, street, country, neighborhood, number, city, state].some((field) => !field);
      }

      return false;
    },
    [errors, contact, address]
  );

  const onUpdateCollaborator = useCallback(
    async (box: BoxType): Promise<void> => {
      const emailRequest: EmailDto | undefined = contact?.email
        ? { id: collaborator.data?.email?.id ?? '', email: contact.email }
        : undefined;

      const phoneRequest: PhoneDto | undefined = contact?.phoneNumber
        ? { id: collaborator.data?.phone?.id ?? '', phoneNumber: formatPhone(contact.phoneNumber) }
        : undefined;

      const addressRequest: AddressDto | undefined = box === 'address' ? address : undefined;

      updateCollaborator.mutate({
        personId: personId ?? '',
        requestBody: {
          email: box === 'contact' ? emailRequest : undefined,
          phone: box === 'contact' ? phoneRequest : undefined,
          address: addressRequest,
        },
      });
    },
    [contact, address, collaborator.data, dispatch, personId, updateCollaborator]
  );

  return {
    user: collaborator.data,
    shouldUpdateCollaborator: hasScope(ScopeEnum.UPDATE_EMPLOYEE),
    isLoading: collaborator.isPending,
    isUpdateLoading: updateCollaborator.isPending,
    isContactEditMode,
    isAddressEditMode,
    contact,
    errors,
    address,
    isButtonDisabled,
    handleEdit,
    handleData,
    onSubmit: onUpdateCollaborator,
  };
};
