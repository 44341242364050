import { isNil } from 'lodash';
import { z } from 'zod';

import { PasswordStatus } from '~/components/password-tick/types';

export const passwordSchema = z.object({
  password: z
    .string()
    .min(8, { message: 'minChar' })
    .regex(/[A-Z]/, { message: 'uppercase' })
    .regex(/[a-z]/, { message: 'lowercase' })
    .regex(/[0-9]/, { message: 'number' })
    .regex(/[@#$%^&*(),.?":{}|<>]/, { message: 'specialChar' }),
  confirmPassword: z.string(),
});

export const checkPasswordRule = (value: string, rule: RegExp, minLength?: number): PasswordStatus => {
  if (!isNil(minLength)) {
    return value.length >= minLength ? PasswordStatus.CORRECT : PasswordStatus.DEFAULT;
  }
  return rule.test(value) ? PasswordStatus.CORRECT : PasswordStatus.DEFAULT;
};
