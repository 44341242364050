import { ReactElement } from 'react';

import { useCreatePassword } from './create-password.hook';
import { CreatePasswordLayout } from './create-password.layout';

export const CreatePasswordPage = (): ReactElement => {
  const props = useCreatePassword();

  return <CreatePasswordLayout {...props} />;
};
