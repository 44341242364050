import { TabOverrides } from '@vizir-banking/design-system/web/components/tabs/tab/types';

export const tabOverrides: TabOverrides = {
  tab: {
    container: {
      flexDirection: 'column',
      flex: 'auto',
    },
    label: {
      paddingRight: '16px',
      paddingLeft: '16x',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
};
