import { ReactElement } from 'react';

import { LogoWithName } from '~/assets/images-mapping';
import { ErrorDialog } from '~/components/dialogs/error-dialog/error-dialog';
import { SuccessDialog } from '~/components/dialogs/success-dialog/success-dialog';
import { useTranslation } from '~/translates/use-translate';

import { ForgotPasswordProps } from './forgot-password.hook';
import { Bold, Button, Dialog, Emphasys, Image, Link, Screen, TextInput } from './forgot-password.styles';

export const ForgotPasswordLayout = ({
  email,
  isLoading,
  handleData,
  isEmailError,
  isDisabled,
  isFirstAccess,
  onForgotPassword,
  goToLogin,
}: ForgotPasswordProps): ReactElement => {
  const translate = useTranslation('pages.forgotPassword');

  return (
    <Screen goBack={goToLogin}>
      <Image src={LogoWithName} />
      <Emphasys>
        {translate('insert')}
        <Bold>{translate('yourEmail')}</Bold>
        {translate(isFirstAccess ? 'create' : 'forgot')}
      </Emphasys>

      <TextInput
        label={translate('email')}
        value={email}
        onChange={handleData}
        onClearTextField={() => handleData('')}
        error={isEmailError ? translate('invalidEmail') : ''}
      />
      <Button
        label={translate('button')}
        onClick={onForgotPassword}
        isDisabled={isDisabled}
        isLoading={isLoading}
      />

      <Dialog>
        {translate('alreadyAccount')}
        <Link onClick={goToLogin}>{translate('yourLogin')}</Link>
      </Dialog>

      <ErrorDialog />
      <SuccessDialog />
    </Screen>
  );
};
