import { IconographyNames } from '@vizir-banking/design-system';
import { Icon, Typography, TypographyVariants } from '@vizir-banking/design-system/web';
import styled from 'styled-components';

import { getColor } from '~/styles/theme/get-theme';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.designSystem.palette.neutral.light};
  padding: 4px 16px;
`;

export const RightWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;

export const CompanyIcon = styled(Icon).attrs({
  iconName: IconographyNames.companyBuilding,
  color: getColor('primary', 'regular'),
})`
  width: 24px;
  height: 24px;
`;

export const Name = styled(Typography).attrs({
  variant: TypographyVariants.HEADING_M,
})``;

export const Document = styled(Typography).attrs({
  variant: TypographyVariants.CAPTION,
})``;
