import { IFileData } from '../../recharge.hook';
import { RechargeSteps } from '../../types';
import { CreateOrderErrorMessageEnum, useRechargeSend } from './send.hook';
import { RechargeSendLayout } from './send.layout';

interface IRechargeSend {
  file: File | null;
  onChangeFile: (file: File) => void;
  onChangeTab: (tab: number) => void;
  onChangeNewRechargeStep: (step: RechargeSteps) => void;
  onChangeFileData: (data: IFileData) => void;
  onChangeFileErrors: (
    errors: { line: number; column: string; message: CreateOrderErrorMessageEnum }[]
  ) => void;
}

export const RechargeSend = ({
  onChangeNewRechargeStep,
  onChangeTab,
  file,
  onChangeFile,
  onChangeFileData,
  onChangeFileErrors,
}: IRechargeSend) => {
  const props = useRechargeSend({
    onChangeFile,
    file,
    onChangeFileData,
    onChangeFileErrors,
  });

  return (
    <RechargeSendLayout
      file={file}
      onChangeTab={onChangeTab}
      onChangeNewRechargeStep={onChangeNewRechargeStep}
      {...props}
    />
  );
};
