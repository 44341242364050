import { FragmentCol } from '~/styles/global-styles';
import { useTranslation } from '~/translates/use-translate';

import { IFileData } from '../recharge.hook';
import { RechargeSteps } from '../types';
import { PageHeader } from './new-recharge.styles';
import { RechargeReview } from './review/review';
import { RechargeSend } from './send/send';
import { CreateOrderErrorMessageEnum } from './send/send.hook';

interface INewRecharge {
  currentTab: number;
  onChangeTab: (tab: number) => void;
  onChangeStep: (step: RechargeSteps) => void;
  file: File | null;
  onChangeFile: (file: File) => void;
  stepHasError: boolean;
  onSetStepHasError: (hasError: boolean) => void;
  onChangeFileData: (data: IFileData) => void;
  fileErrors: { line: number; column: string; message: CreateOrderErrorMessageEnum }[];
  onChangeFileErrors: (
    errors: { line: number; column: string; message: CreateOrderErrorMessageEnum }[]
  ) => void;
}

export const NewRechargeLayout = ({
  currentTab,
  onChangeTab,
  onChangeStep,
  file,
  onChangeFile,
  stepHasError,
  onSetStepHasError,
  onChangeFileData,
  fileErrors,
  onChangeFileErrors,
}: INewRecharge) => {
  const translate = useTranslation('pages.recharge.newRecharge');

  const renderHeader = () => {
    const goBack = () => {
      return currentTab > 0 ? onChangeTab(currentTab - 1) : onChangeStep(RechargeSteps.BALANCE);
    };

    return <PageHeader leadingGoBack={goBack} title={translate('pageHeader.subtitle')} />;
  };

  const renderRechargeSend = () => {
    return (
      <RechargeSend
        file={file}
        onChangeFile={onChangeFile}
        onChangeNewRechargeStep={onChangeStep}
        onChangeTab={onChangeTab}
        onChangeFileData={onChangeFileData}
        onChangeFileErrors={onChangeFileErrors}
      />
    );
  };

  const renderRechargeReview = () => {
    return (
      <RechargeReview
        file={file}
        fileErrors={fileErrors}
        onChangeTab={onChangeTab}
        onChangeNewRechargeStep={onChangeStep}
        onSetStepHasError={onSetStepHasError}
      />
    );
  };

  return (
    <FragmentCol style={{ flex: 1 }}>
      {renderHeader()}
      {stepHasError ? renderRechargeReview() : renderRechargeSend()}
    </FragmentCol>
  );
};
