import { ButtonOverrides } from '@vizir-banking/design-system/web';

export const buttonOverrides: ButtonOverrides = {
  button: {
    primary: {
      container: {
        color: '#000',
      },
    },
  },
};
