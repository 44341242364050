import { ButtonProps as BaseButtonProps } from '@vizir-banking/design-system/web';
import { ReactElement } from 'react';

import { AddressDto } from '~/api/requests';
import { ErrorDialog } from '~/components/dialogs/error-dialog/error-dialog';
import { SuccessDialog } from '~/components/dialogs/success-dialog/success-dialog';
import { FragmentCol } from '~/styles/global-styles';
import { useTranslation } from '~/translates/use-translate';

import { NewCardsAddress } from './address/address';
import { PageHeader, Tabs } from './new-cards.styles';
import { NewCardsQuantity } from './quantity/quantity';
import { NewCardsReview } from './review/review';

type ButtonType = 'error' | 'success';
type ButtonProps = Omit<BaseButtonProps, 'buttonSize' | 'buttonType'>;

interface CardState {
  quantity: string;
  date: string;
}

interface INewCards {
  card: CardState;
  currentTab: number;
  address: AddressDto;
  buttonProps: (type: ButtonType) => ButtonProps;
  onChangeTab: (tab: number) => void;
  goBack: () => void;
  onCancel: () => void;
  handleData: (name: string, value: string) => void;
  onChangeSelectAddress: (address: AddressDto) => void;
}

export const NewCardsLayout = ({
  currentTab,
  card,
  address,
  buttonProps,
  onChangeTab,
  goBack,
  onCancel,
  handleData,
  onChangeSelectAddress,
}: INewCards) => {
  const translate = useTranslation('pages.cards.newCards');
  const tabs = ['quantity', 'address', 'review'];
  const renderHeader = () => {
    return <PageHeader leadingGoBack={goBack} title={translate('pageHeader.title')} />;
  };

  const renderNewCardsQuantity = () => {
    return (
      <NewCardsQuantity card={card} onCancel={onCancel} onChangeTab={onChangeTab} handleData={handleData} />
    );
  };
  const renderNewCardsAddress = () => {
    return (
      <NewCardsAddress
        address={address}
        onChangeSelectAddress={onChangeSelectAddress}
        onCancel={onCancel}
        onChangeTab={onChangeTab}
      />
    );
  };
  const renderNewCardsReview = () => {
    return <NewCardsReview address={address} card={card} onCancel={onCancel} />;
  };

  const renderContent = (key: number) => {
    const content: { [key: number]: ReactElement } = {
      0: renderNewCardsQuantity(),
      1: renderNewCardsAddress(),
      2: renderNewCardsReview(),
    };

    return content[key];
  };

  const renderItems = tabs.map((tab, index) => ({
    label: translate(`tabs.${tab}.title`),
    element: renderContent(index),
  }));

  const renderTabs = () => {
    return <Tabs items={renderItems} activeTabIndex={currentTab} onChange={onChangeTab} />;
  };

  return (
    <FragmentCol style={{ flex: 1 }}>
      {renderHeader()}
      {renderTabs()}
      <ErrorDialog secondaryButtonProps={buttonProps('error')} />
      <SuccessDialog secondaryButtonProps={buttonProps('success')} />
    </FragmentCol>
  );
};
