import {
  Skeleton as BaseSkeleton,
  SkeletonVariants,
  Typography,
  TypographyVariants,
} from '@vizir-banking/design-system/web';
import styled from 'styled-components';

import { getColor } from '~/styles/theme/get-theme';

export const Wrapper = styled.div`
  position: absolute;
  bottom: -60px;
`;

export const Version = styled(Typography).attrs({
  variant: TypographyVariants.DIALOG,
})`
  display: inline;
  color: ${getColor('neutral', 'medium')};
`;

export const Skeleton = styled(BaseSkeleton).attrs({
  variant: SkeletonVariants.tag,
})`
  width: 160px;
`;
