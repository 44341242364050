import { IconographyNames } from '@vizir-banking/design-system';
import { Icon as BaseIcon, Typography, TypographyVariants } from '@vizir-banking/design-system/web';
import styled from 'styled-components';

import { getColor } from '~/styles/theme/get-theme';

interface Position {
  top?: number;
  left?: number;
  right?: number;
  bottom?: number;
}

export const Container = styled.div<{ position?: Position }>`
  width: 150px;
  flex-direction: column;
  position: absolute;
  background-color: ${getColor('neutral', 'lightest')};
  padding: 12px 0;
  top: ${({ position }) => position?.top || 'auto'};
  left: ${({ position }) => position?.left || 'auto'};
  right: ${({ position }) => position?.right || 'auto'};
  bottom: ${({ position }) => position?.bottom || 'auto'};
  border: 1px solid ${getColor('neutral', 'light')};
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  z-index: 100;
`;

export const Icon = styled(BaseIcon)`
  width: 24px;
  height: 24px;
`;

export const Option = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  padding: 14px 18px;
  gap: 8px;

  &:hover {
    background-color: ${getColor('neutral', 'light')};
    cursor: pointer;
  }
`;

export const OptionLabel = styled(Typography).attrs({
  variant: TypographyVariants.PARAGRAPH_XS,
})<{ color?: string }>`
  display: inline;
  white-space: nowrap;
  color: ${({ color }) => color || getColor('neutral', 'darkest')};
  width: 100%;
`;

export const MenuIcon = styled(BaseIcon).attrs({
  iconName: IconographyNames.menuHorizontal,
  color: getColor('neutral', 'darkest'),
})`
  width: 24px;
  height: 24px;
`;
