import { BannerTypes, TagSizes, TagTypes, TagVariants } from '@vizir-banking/design-system/web';
import { CSVLink } from 'react-csv';

import {
  BatchProposalStatusEnum as BatchProposalStatusEnumType,
  GetSpecificBatchDetailsResponseDto,
} from '~/api/requests';
import { FeedbackBanner } from '~/components/feedback-banner/feedback-banner';
import { LeadingContentType, PageHeader, TrailingContentType } from '~/components/page-header/page-header';
import { ProposalsTable } from '~/components/tables/proposals-table/proposals-table';
import { FragmentCol } from '~/styles/global-styles';
import { useTranslation } from '~/translates/use-translate';

import { ListBatchesSteps } from '../types';
import { ButtonWrapper, Container, DownloadButton, Head, Paragraph } from './batch-details.styles';

interface IBatchDetailsLayout {
  batch: GetSpecificBatchDetailsResponseDto | undefined;
  batchStatus?: BatchProposalStatusEnumType;
  translateParent: (key: string) => string;
  isBatchLoading: boolean;
  isBatchStatusLoading: boolean;
  csvWithDeclinedProposals: string;
  tagType: TagTypes;
  onChangeStep: (step: ListBatchesSteps) => void;
  notFound: boolean;
}

export const BatchDetailsLayout = ({
  batch,
  batchStatus,
  translateParent,
  isBatchLoading,
  isBatchStatusLoading,
  csvWithDeclinedProposals,
  tagType,
  onChangeStep,
  notFound,
}: IBatchDetailsLayout) => {
  const translate = useTranslation('pages.listBatches.batchDetails');
  const renderSkeletons = () => {
    return Array.from({ length: 3 }).map((_) => {
      return <FeedbackBanner isSkeleton />;
    });
  };
  return (
    <Container>
      <Head>
        <PageHeader
          title={`${translate('batchNumber')}: ${isBatchLoading ? '...' : batch?.proposalBatchId ?? 'N/A'}`}
          leadingContentType={LeadingContentType.GO_BACK}
          leadingGoBack={() => onChangeStep(ListBatchesSteps.VIEW)}
          trailingContentType={TrailingContentType.TAG}
          trailingTag={{
            tagProps: {
              label:
                isBatchLoading || isBatchStatusLoading
                  ? '...'
                  : batchStatus && batch
                  ? translateParent(batchStatus)
                  : translate('batchNotFound'),
              size: TagSizes.large,
              type: !isBatchLoading && batchStatus && batch ? tagType : TagTypes.neutral,
              variant: TagVariants.primary,
            },
          }}
        />

        {isBatchLoading && renderSkeletons()}
        {notFound && <Paragraph>{translate('batchNotFound')}</Paragraph>}
        {!isBatchLoading && !notFound && (
          <FragmentCol style={{ gap: 16 }}>
            <FeedbackBanner
              type={BannerTypes.success}
              title={`${batch?.proposalsAccepted} ${translate('proposalsAccepted')}.`}
            />
            <FeedbackBanner
              type={BannerTypes.error}
              title={`${batch?.proposalsDeclined} ${translate('proposalsWithErrors')}.`}
            />
            <FeedbackBanner
              type={BannerTypes.warning}
              title={`${batch?.proposalsWithErrors} ${translate('proposalsDeclined')}.`}
            />
          </FragmentCol>
        )}
      </Head>

      {!notFound && (
        <FragmentCol style={{ gap: 24 }}>
          <ProposalsTable batchData={batch} isBatchLoading={isBatchLoading} />

          <ButtonWrapper>
            <CSVLink
              data={csvWithDeclinedProposals}
              filename='propostas_recusadas.csv'
              style={{ textDecoration: 'none' }}
            >
              <DownloadButton
                onClick={() => {}}
                label={translate('downloadRejectedSpreadsheet')}
                isLoading={isBatchLoading}
                isDisabled={isBatchLoading}
              />
            </CSVLink>
          </ButtonWrapper>
        </FragmentCol>
      )}
    </Container>
  );
};
