export const SCREENS = {
  root: '/',
  login: '/login',
  createPassword: '/create-password',
  forgotPassword: '/forgot-password',
  home: '/home',
  listCollaborators: '/list-collaborators',
  addBatchCollaborators: '/add-batch-collaborators',
  listBatches: '/list-batches',
  recharge: '/recharge',
  permissions: '/permissions',
  cards: '/cards',
  settings: '/settings',
  address: '/address',
};
