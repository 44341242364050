import { TableBody, TableHead, TagSizes, TagTypes, TagVariants } from '@vizir-banking/design-system/web';
import { ReactElement } from 'react';

import { CardBatchStatusEnum } from '~/api/constants';
import { CardBatch } from '~/api/requests';
import { DropdownProvider } from '~/components/dropdown/dropdown-context';
import { useTranslation } from '~/translates/use-translate';
import { dateFormatter } from '~/utils/format-date';

import {
  CalendarIcon,
  DateWrapper,
  Skeleton,
  TableBodyCell,
  TableBodyRow,
  TableBodyTagCell,
  TableContainer,
  TableHeadCell,
  TableHeadRow,
} from './cards-table.styles';

interface ICardsTable {
  cards?: CardBatch[];
  isLoading: boolean;
  onReachEndLoading: boolean;
}

export const CardsTable = ({ cards, isLoading, onReachEndLoading }: ICardsTable): ReactElement => {
  const translate = useTranslation('components.table.cards');

  const renderTableHeadRow = () => {
    return (
      <TableHeadRow>
        <TableHeadCell>{translate('table.status')}</TableHeadCell>
        <TableHeadCell>{translate('table.quantity')}</TableHeadCell>
        <TableHeadCell>{translate('table.date')}</TableHeadCell>
        <TableHeadCell>{translate('table.id')}</TableHeadCell>
      </TableHeadRow>
    );
  };

  const renderTableBodyRows = ({ id, status, quantity, createdAt }: CardBatch) => {
    const getStatus = (index: string): TagTypes => {
      const status: { [key: string]: TagTypes } = {
        [CardBatchStatusEnum.PROCESSED]: TagTypes.success,
        [CardBatchStatusEnum.PENDING]: TagTypes.warning,
        [CardBatchStatusEnum.ERROR]: TagTypes.neutral,
        [CardBatchStatusEnum.PROCESSING]: TagTypes.info,
        [CardBatchStatusEnum.ABORTED]: TagTypes.negative,
      };

      return status[index];
    };

    const tagProps = {
      size: TagSizes.large,
      variant: TagVariants.primary,
      type: getStatus(status),
    };

    return (
      <TableBodyRow>
        <TableBodyTagCell tagProps={tagProps}>{translate(`status.${status}`)}</TableBodyTagCell>
        <TableBodyCell>{quantity}</TableBodyCell>
        <TableBodyCell>
          <DateWrapper>
            <CalendarIcon />
            {dateFormatter(new Date(createdAt))}
          </DateWrapper>
        </TableBodyCell>
        <TableBodyCell>{id}</TableBodyCell>
      </TableBodyRow>
    );
  };

  const renderSkeletonLoadingRows = () => {
    const renderSkeletonCell = () => {
      return (
        <TableBodyCell>
          <Skeleton />
        </TableBodyCell>
      );
    };

    const renderSkeletonRow = () => {
      return (
        <TableBodyRow>
          {renderSkeletonCell()}
          {renderSkeletonCell()}
          {renderSkeletonCell()}
          {renderSkeletonCell()}
        </TableBodyRow>
      );
    };

    return Array.from({ length: 3 }).map((_) => renderSkeletonRow());
  };

  return (
    <DropdownProvider>
      <TableContainer>
        <TableHead>{renderTableHeadRow()}</TableHead>
        <TableBody>
          {isLoading ? renderSkeletonLoadingRows() : cards?.map((card) => renderTableBodyRows(card))}
          {onReachEndLoading && renderSkeletonLoadingRows()}
        </TableBody>
      </TableContainer>
    </DropdownProvider>
  );
};
