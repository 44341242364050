import { useState } from 'react';

import { ListBatchesSteps } from './types';

export type ListBatchesProps = {
  onChangeStep: (step: ListBatchesSteps) => void;
  currentStep: ListBatchesSteps;
  detailedBatchId: string;
};

export const useListBatches = (): ListBatchesProps => {
  const [currentStep, setCurrentStep] = useState<ListBatchesSteps>(ListBatchesSteps.VIEW);
  const [detailedBatchId, setDetailedBatchId] = useState<string>('');

  const onChangeStep = (step: ListBatchesSteps, batchId?: string) => {
    setCurrentStep(step);
    if (batchId) {
      setDetailedBatchId(batchId);
    }
  };

  return {
    onChangeStep,
    currentStep,
    detailedBatchId,
  };
};
