import { useEffect, useState } from 'react';

import { useCardsServiceCardsControllerGetExpirationDate } from '~/api/queries';

import cardSchema from './quantity.schema';

interface CardState {
  quantity: string;
  date: string;
}

interface ErrorCardState {
  quantity?: string;
}

interface INewCardsQuantity {
  card: CardState;
  error: ErrorCardState;
  year?: number;
  isLoadingExpirationDate: boolean;
  isButtonDisabled: boolean;
  expirationDate?: string;
  handleData: (name: string, value: string) => void;
  onSubmit: () => void;
}

interface INewCards {
  card: CardState;
  handleData: (name: string, value: string) => void;
  onChangeTab: (tab: number) => void;
}

export const useNewCardsQuantityHook = ({ onChangeTab, card, handleData }: INewCards): INewCardsQuantity => {
  const [error, setError] = useState<ErrorCardState>({});

  const getExpirationDate = useCardsServiceCardsControllerGetExpirationDate();

  const isButtonDisabled = () => {
    return (
      Object.values(card).some((value) => !value) ||
      Object.values(error).some((value) => value) ||
      getExpirationDate.isPending
    );
  };

  const onSubmit = () => {
    onChangeTab(1);
  };

  useEffect(() => {
    const validation = cardSchema.safeParse({
      quantity: Number(card.quantity),
    });

    if (!validation.success) {
      const { quantity } = validation.error.flatten().fieldErrors;
      setError({
        quantity: card?.quantity && quantity?.[0],
      });
    } else {
      setError({});
    }
  }, [card]);

  useEffect(() => {
    if (getExpirationDate.data) {
      handleData('date', getExpirationDate.data.expirationDate);
    }
  }, [getExpirationDate.data]);

  return {
    card,
    error,
    year: getExpirationDate.data?.expirationYears,
    isButtonDisabled: isButtonDisabled(),
    isLoadingExpirationDate: getExpirationDate.isPending,
    expirationDate: new Date(String(getExpirationDate.data?.expirationDate)).toLocaleDateString(),
    handleData,
    onSubmit,
  };
};
