import {
  Icon as BaseIcon,
  Skeleton as BaseSkeleton,
  SkeletonVariants,
  Table as BaseTable,
  TableCell as BaseTableCell,
  TableHeadCell as BaseTableHeadCell,
  TableRow as BaseTableRow,
  TableTagCell as BaseTableTagCell,
} from '@vizir-banking/design-system/web';
import styled from 'styled-components';

import { BatchProposalStatusEnum } from '~/api/constants';
import { BatchProposalStatusEnum as BatchProposalStatusEnumType } from '~/api/requests/types.gen';
import { getColor } from '~/styles/theme/get-theme';

const getCursorProp = ({ status }: { status?: BatchProposalStatusEnumType }) =>
  status === BatchProposalStatusEnum.PROCESSING ? 'not-allowed' : 'pointer';

export const TableContainer = styled(BaseTable)`
  width: 100%;
  overflow-y: visible;
  height: auto;
  margin-top: 24px;
`;

export const TableHeadRow = styled(BaseTableRow)`
  background-color: ${getColor('neutral', 'lighter')};
`;

export const TableHeadCell = styled(BaseTableHeadCell)`
  background-color: ${getColor('neutral', 'lighter')};
  text-align: start;
`;

export const TableRow = styled(BaseTableRow)`
  border-bottom: 1px solid ${getColor('neutral', 'light')};
  border-top: 1px solid ${getColor('neutral', 'light')};
`;

export const TableCell = styled(BaseTableCell)<{ status?: BatchProposalStatusEnumType }>`
  border: 1px solid ${getColor('neutral', 'lighter')};
  cursor: ${({ status }) => getCursorProp({ status })};
`;

export const TableTagCell = styled(BaseTableTagCell)<{
  status: BatchProposalStatusEnumType;
}>`
  cursor: ${({ status }) => getCursorProp({ status })};
`;

export const Skeleton = styled(BaseSkeleton).attrs({
  variant: SkeletonVariants.custom,
})`
  height: 40px;
  width: 100%;
`;

export const InnerSkeleton = styled.div`
  height: 40px;
  width: 100%;
`;

export const DateWrapper = styled.div`
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
`;

export const CalendarIcon = styled(BaseIcon).attrs(({ theme }) => ({
  iconName: 'calendar',
  color: theme.designSystem.palette.neutral.darkest,
}))`
  height: 24px;
  width: 24px;
`;
