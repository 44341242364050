import { CardBatchStatusEnum } from '~/api/constants';

export const ptBrComponentTranslations = {
  components: {
    sideBar: {
      home: 'Início',
      collaborators: 'Colaboradores',
      listCollaborators: 'Lista de Colaboradores',
      listBatches: 'Cadastros em Lote',
      recharge: 'Recarga de saldo',
      permissions: 'Permissões',
      cards: 'Solicitar cartões',
      settings: 'Configurações',
      address: 'Endereços',
    },
    topBar: {
      help: 'Ajuda',
      dockPaymentSolutions: 'Dock Soluções de Pagamento',
      admin: 'Administrador',
      operator: 'Operador',
      viewer: 'Visualizador',
    },
    verificationCode: {
      title: 'Insira o código que enviamos para seu e-mail cadastrado',
      resendCode: 'Reenviar código em {{time}} segundos',
      resend: 'Reenviar',
      error: 'Falha ao enviar, ',
      tryAgain: 'tentar novamente!',
    },
    table: {
      collaborator: {
        table: {
          name: 'Nome completo',
          document: 'CPF',
          email: 'E-mail',
        },
      },
      permission: {
        table: {
          status: 'Status',
          name: 'Nome completo',
          document: 'CPF',
          email: 'E-mail',
          role: 'Permissão',
          actions: 'Ações',
        },
        status: {
          active: 'Ativo',
          pending: 'Pendente',
        },
        action: {
          removeAccess: 'Remover acesso',
          resendInvitation: 'Reenviar convite',
        },
        role: {
          admin: 'Administrador',
          operator: 'Operador',
          viewer: 'Visualizador',
        },
        modal: {
          confirmDelete: 'Tem certeza que deseja remover o acesso de',
          confirmDeleteSelf:
            'Tem certeza que deseja remover seu próprio acesso? Você será deslogado do portal imediatamente.',
          cancel: 'Cancelar',
          accessRemoved: 'Acesso removido com sucesso!',
          invitationSent: 'Convite enviado com sucesso para',
        },
      },
      batches: {
        table: {
          status: 'Status',
          statusCodes: {
            partial_processed: 'Parcialmente processado',
            processing: 'Processando',
            processed: 'Processado',
            failed: 'Falha',
          },
          batch: 'Lote',
          date: 'Data de envio',
          dateAt: 'às',
          actions: 'Ações',
          actionsCodes: {
            viewDetails: 'Ver detalhes',
          },
        },
      },
      cards: {
        table: {
          status: 'Status',
          quantity: 'Quantidade',
          date: 'Data de solicitação',
          id: 'Identificador',
        },
        status: {
          [CardBatchStatusEnum.ERROR]: 'Erro',
          [CardBatchStatusEnum.PROCESSED]: 'Processado',
          [CardBatchStatusEnum.PROCESSING]: 'Processando',
          [CardBatchStatusEnum.ABORTED]: 'Cancelado',
          [CardBatchStatusEnum.PENDING]: 'Pendente',
        },
      },
      recharge: {
        table: {
          status: 'Status',
          registerDate: 'Data de cadastro',
          amount: 'Valor',
          actions: {
            title: 'Ações',
            viewDetails: 'Ver detalhes',
            payOrder: 'Pagar pedido',
            releaseOrder: 'Distribuir pedido',
          },
        },
        status: {
          approved: 'Distribuído completo',
          partial: 'Distribuído em parte',
          ready: 'Pronta para distribuir',
          pending: 'Pagamento pendente',
          canceled: 'Cancelada',
          failed: 'Falha na distribuição',
          processing: 'Pedido em processamento',
          processingSpreadsheet: 'Processando planilha',
          spreadsheetFailure: 'Falha no processamento',
          unknown: 'Erro desconhecido',
        },
      },
    },
    screen: {
      toLogin: 'Para logar na plataforma,',
      useDesktop: 'procure um dispositivo de desktop.',
      goBack: 'Voltar',
    },
    proposalsTable: {
      line: 'Linha',
      status: 'Status',
      name: 'Nome',
      document: 'CPF',
      email: 'E-mail',
      birthDate: 'Data de nascimento',
      phone: 'Telefone',
      postalCode: 'CEP',
      street: 'Rua',
      number: 'Número',
      addressComplement: 'Complemento',
      neighborhood: 'Bairro',
      city: 'Cidade',
      state: 'Estado',
      country: 'País',
      motherName: 'Nome da mãe',
      preferredName: 'Nome social',
      gender: 'Gênero',
      emancipatedMinor: 'Menor emancipado',
      maritalStatus: 'Estado civil',
      statusCodes: {
        processing: 'Processando',
        approved: 'Aprovado',
        declined: 'Recusado',
        not_processed: 'Não processado',
      },
    },
    pageHeader: {
      goBack: 'Voltar',
    },
    version: {
      qa: 'Portal Benefícios QA - {{front}}/v{{back}}',
      prod: 'Portal Benefícios  - {{front}}/v{{back}}',
    },
  },
};
