import { ReactElement, useEffect, useState } from 'react';

import { useSecurityServiceSecurityControllerCheck } from '~/api/queries';
import { useTranslation } from '~/translates/use-translate';
import { maskEmail } from '~/utils/mask-email';

import {
  BottomWrapper,
  Button,
  CloseButton,
  CloseIcon,
  Content,
  Dialog,
  Emphasys,
  Link,
  Notification,
  Paragraph,
  PinInput,
  PinWrapper,
  Wrapper,
} from './verification-code-dialog.styles';

const COOLDOWN_TIME = 30;

interface Props {
  email: string;
  path: string;
  onComplete: (code: string, validationId?: string) => void;
  onClose: (validationId?: string) => void;
}

export function VerificatonCodeDialog({ email, path, onComplete, onClose }: Props): ReactElement {
  const [time, setTime] = useState(COOLDOWN_TIME);
  const [validationId, setValidationId] = useState<string>();
  const translate = useTranslation('components.verificationCode');

  const resendEmail = useSecurityServiceSecurityControllerCheck({
    onSuccess: (resp) => {
      setValidationId(resp.validationId);
      setTime(COOLDOWN_TIME);
    },
  });

  const onResend = () => {
    resendEmail.mutate({
      requestBody: { method: 'post', path, document: email },
    });
  };

  const renderBottom = (): ReactElement => {
    if (resendEmail.isError) {
      return (
        <Paragraph>
          {translate('error')}
          <Link onClick={onResend}>{translate('tryAgain')}</Link>
        </Paragraph>
      );
    }

    if (time === 0) {
      return <Button label={translate('resend')} onClick={onResend} isLoading={resendEmail.isPending} />;
    }

    return (
      <Paragraph>
        {translate('resendCode', {
          time: String(time),
        })}
      </Paragraph>
    );
  };

  const handleOnComplete = (value: string): void => {
    onComplete(value, validationId);
  };

  const handleOnClose = () => onClose(validationId);

  useEffect(() => {
    const interval = setInterval(() => {
      if (time > 0) setTime((prev) => prev - 1);
    }, 1000);

    return () => clearInterval(interval);
  });

  return (
    <Dialog>
      <Wrapper>
        <CloseButton onClick={handleOnClose}>
          <CloseIcon />
        </CloseButton>
        <Content>
          <Emphasys>{translate('title')}</Emphasys>

          <Notification
            leadingText={{
              label: maskEmail(email),
            }}
          />
          <PinWrapper>
            <PinInput onComplete={handleOnComplete} />
          </PinWrapper>

          <BottomWrapper>{renderBottom()}</BottomWrapper>
        </Content>
      </Wrapper>
    </Dialog>
  );
}
