import { IconographyNames } from '@vizir-banking/design-system';
import {
  Icon,
  Skeleton as BaseSkeleton,
  SkeletonVariants,
  Table as BaseTable,
  TableCell as BaseTableCell,
  TableHeadCell as BaseTableHeadCell,
  TableRow as BaseTableRow,
  TableTagCell as BaseTableTagCell,
} from '@vizir-banking/design-system/web';
import styled from 'styled-components';

import { getColor } from '~/styles/theme/get-theme';

const getCellWidth = (cell: string) => {
  switch (cell) {
    case 'date':
      return '150px';
    case 'value':
      return '100px';
    case 'actions':
      return '100px';
    default:
      return 'auto';
  }
};

const BaseIcon = styled(Icon).attrs({
  color: getColor('neutral', 'darkest'),
})`
  width: 24px;
  height: 24px;
`;

export const TableContainer = styled(BaseTable)`
  overflow-y: visible;
  height: auto;
  margin-top: 24px;
`;

export const TableHeadRow = styled(BaseTableRow)`
  background-color: ${getColor('neutral', 'lighter')};
`;

export const TableBodyRow = styled(BaseTableRow)``;

export const TableHeadCell = styled(BaseTableHeadCell)`
  border: 1px solid ${getColor('neutral', 'light')};
  text-align: left;
`;

export const TableBodyTagCell = styled(BaseTableTagCell).attrs({
  trailingIconColor: getColor('error', 'medium'),
})<{ cell?: string }>`
  border: 1px solid ${getColor('neutral', 'lighter')};
  width: ${({ cell }) => getCellWidth(cell ?? '')};
`;

export const TableBodyCell = styled(BaseTableCell)<{ cell?: string }>`
  border: 1px solid ${getColor('neutral', 'lighter')};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: ${({ cell }) => getCellWidth(cell ?? '')};
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

export const CalendarIcon = styled(BaseIcon).attrs({
  iconName: IconographyNames.calendar,
})``;

export const Skeleton = styled(BaseSkeleton).attrs({
  variant: SkeletonVariants.tag,
})<{ cell?: string }>`
  height: 32px;
  width: ${({ cell }) => getCellWidth(cell ?? '')};
`;
