import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { GetEmployerBasicInfoResponseDto } from '~/api/requests';

import { BenefitsState } from '../types';

const initialState: GetEmployerBasicInfoResponseDto = {
  personId: '',
  fullName: '',
  cnpj: '',
};

const employerSlice = createSlice({
  name: 'employer',
  initialState,
  reducers: {
    setEmployer: (state, action: PayloadAction<GetEmployerBasicInfoResponseDto>) => {
      state.personId = action.payload.personId;
      state.fullName = action.payload.fullName;
      state.cnpj = action.payload.cnpj;
    },
    clearEmployer: (state) => {
      state.personId = '';
      state.fullName = '';
      state.cnpj = '';
    },
  },
});

export const { setEmployer, clearEmployer } = employerSlice.actions;

export const selectEmployer = (state: BenefitsState) => state.employer;

export default employerSlice.reducer;
