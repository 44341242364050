import { ErrorsEnum } from './types';

export const enUsErrorTranslations = {
  errors: {
    generic_error: {
      title: 'Oops, something went wrong!',
      description: 'We had a problem! Please try again.',
      button: 'Close',
    },
    [ErrorsEnum.AUTHENTICATION_FAILED]: {
      title: 'Oops, something went wrong!',
      description: 'Email or password do not match.',
      button: 'Got it',
    },
    [ErrorsEnum.VALIDATION_INVALID]: {
      title: 'Oops, something went wrong!',
      description: 'Incorrect verification code.',
      button: 'Close',
    },
    [ErrorsEnum.EMAIL_NOT_SENT]: {
      title: 'Oops, something went wrong!',
      description: 'Failed to send the email.',
      button: 'Close',
    },
    [ErrorsEnum.EXPIRED_ACCESS_CODE]: {
      title: 'Oops, something went wrong!',
      description: 'Sorry, your link has expired.',
      button: 'Close',
    },
    [ErrorsEnum.INVALID_PASSWORD_POLICY]: {
      title: 'Oops, something went wrong!',
      description: 'The password provided does not meet the minimum security requirements.',
      button: 'Close',
    },
    [ErrorsEnum.TOKEN_WITHOUT_SCOPES]: {
      title: 'Oops, something went wrong!',
      description: 'Oops, you do not have permission to do this.',
      button: 'Close',
    },
    [ErrorsEnum.INVALID_FILE]: {
      title: 'Oops, something went wrong!',
      description: 'The selected file is not a compatible format.',
      button: 'Close',
    },
    [ErrorsEnum.BATCH_PROPOSAL_NO_FILE]: {
      title: 'Oops, something went wrong!',
      description: 'No file found.',
      button: 'Close',
    },
    [ErrorsEnum.BATCH_PROPOSAL_EMPTY_FILE]: {
      title: 'Oops, something went wrong!',
      description: 'The selected file is empty.',
      button: 'Close',
    },
    [ErrorsEnum.BATCH_PROPOSAL_EMPTY_COLUMNS]: {
      title: 'Oops, something went wrong!',
      description: 'The selected file has no columns.',
      button: 'Close',
    },
    [ErrorsEnum.BATCH_PROPOSAL_INVALID_COLUMNS]: {
      title: 'Oops, something went wrong!',
      description: 'The selected file has column errors.',
      button: 'Close',
    },
    [ErrorsEnum.BATCH_PROPOSAL_MAX_BATCH_SIZE]: {
      title: 'Oops, something went wrong!',
      description: 'The selected file is too large.',
      button: 'Got it',
    },
    [ErrorsEnum.BATCH_PROPOSAL_MIN_BATCH_SIZE]: {
      title: 'Oops, something went wrong!',
      description: 'The selected file does not meet the minimum size requirement.',
      button: 'Got it',
    },
    [ErrorsEnum.EXPIRED_TOKEN]: {
      title: 'Session expired',
      description: 'Your session has expired. Please log in again.',
      button: 'Got it',
    },
    [ErrorsEnum.SPLIT_ORDER]: {
      title: 'Oops, something went wrong!',
      description: 'Failed to split the order. Please try again.',
      button: 'Got it',
    },
    [ErrorsEnum.ADDRESS_LIMIT_REACHED]: {
      title: 'Oops, something went wrong!',
      description: 'You have reached the maximum number of addresses.',
      button: 'Got it',
    },
    [ErrorsEnum.LOAD_ORDER_MIN_ITEMS]: {
      title: 'Oops, something went wrong!',
      description: 'The selected file does not meet the minimum size requirement.',
      button: 'Got it',
    },
    [ErrorsEnum.LOAD_ORDER_MAX_ITEMS]: {
      title: 'Oops, something went wrong!',
      description: 'The selected file exceeded the maximum number of items (40).',
      button: 'Got it',
    },
    [ErrorsEnum.DOCK_ONE_BAD_REQUEST]: {
      title: 'Oops, something went wrong!',
      description: 'Invalid data on spreadsheet.',
      button: 'Got it',
    },
  },
};
