import { ICollaboratorHookProps } from '../types';
import { useCollaboratorsViewHook } from './collaborators-view.hook';
import { CollaboratorsViewLayout } from './collaborators-view.layout';

export const CollaboratorsViewPage = ({
  onChangeStep,
  allCollaborators,
  updateCollaborators,
  document,
  setDocument,
  documentError,
  setDocumentError,
  enabled,
  setEnabled,
  page,
  updatePage,
  USERS_PER_PAGE,
  getCollaborators,
}: ICollaboratorHookProps) => {
  const props = useCollaboratorsViewHook({
    onChangeStep,
    allCollaborators,
    updateCollaborators,
    document,
    setDocument,
    documentError,
    setDocumentError,
    enabled,
    setEnabled,
    page,
    updatePage,
    USERS_PER_PAGE,
    getCollaborators,
  });

  return <CollaboratorsViewLayout {...props} onChangeStep={onChangeStep} />;
};
