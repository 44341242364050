import { DesignSystemContext } from '@vizir-banking/design-system';
import { ReactElement } from 'react';
import { ThemeProvider as StyledComponentsThemeProvider } from 'styled-components';

import { designSystemGlobalVariants } from '~/styles/theme/design-system-global-variants';
import { designSystemIcons } from '~/styles/theme/design-system-icons';
import { designSystemTheme } from '~/styles/theme/design-system-theme';

import { Props } from './types';

export function ThemeProvider({ children }: Props): ReactElement {
  return (
    <DesignSystemContext.Provider
      value={{
        theme: designSystemTheme,
        globalVariants: designSystemGlobalVariants,
        icons: designSystemIcons,
      }}
    >
      <StyledComponentsThemeProvider theme={designSystemTheme}>{children}</StyledComponentsThemeProvider>
    </DesignSystemContext.Provider>
  );
}
