import { z } from 'zod';

const quantitySchema = z
  .number()
  .min(1, 'A quantidade mínima é 1 unidade')
  .max(5000, 'A quantidade máxima é 5000 unidades');

const cardSchema = z.object({
  quantity: quantitySchema,
});

export default cardSchema;
