import { useSelector } from 'react-redux';
import { createBrowserRouter, Navigate } from 'react-router-dom';

import { BatchCollaboratorsPage } from '~/pages/batch-collaborators/batch-collaborators';
import { CardsPage } from '~/pages/cards/cards';
import { CollaboratorsPage } from '~/pages/collaborators/collaborators';
import { CreatePasswordPage } from '~/pages/create-password/create-password';
import { EnterpriseAddressPage } from '~/pages/enterprise-address/enterprise-address';
import { ForgotPasswordPage } from '~/pages/forgot-password/forgot-password';
import { HomePage } from '~/pages/home/home';
import { ListBatchesPage } from '~/pages/list-batches/list-batches';
import { LoginPage } from '~/pages/login/login';
import { Permissions } from '~/pages/permissions/permissions';
import { RechargePage } from '~/pages/recharge/recharge';
import { selectToken } from '~/redux/reducers/user';

import { ProtectedRoute } from './protected-route';
import { SCREENS } from './screen-definitions';

interface RequireAuth {
  children: React.ReactNode;
}

const RequireAuth = () => {
  const token = useSelector(selectToken);

  if (token) return <Navigate to={SCREENS.home} replace />;

  return <Navigate to={SCREENS.login} replace />;
};

export const routes = createBrowserRouter([
  {
    path: SCREENS.root,
    element: <RequireAuth />,
  },
  {
    path: SCREENS.login,
    element: <LoginPage />,
  },
  {
    path: SCREENS.createPassword,
    element: <CreatePasswordPage />,
  },
  {
    path: SCREENS.forgotPassword,
    element: <ForgotPasswordPage />,
  },
  {
    element: <ProtectedRoute />,
    children: [
      {
        path: SCREENS.home,
        element: <HomePage />,
      },
      {
        path: SCREENS.listCollaborators,
        element: <CollaboratorsPage />,
      },
      {
        path: SCREENS.addBatchCollaborators,
        element: <BatchCollaboratorsPage />,
      },
      {
        path: SCREENS.listBatches,
        element: <ListBatchesPage />,
      },
      {
        path: SCREENS.recharge,
        element: <RechargePage />,
      },
      {
        path: SCREENS.permissions,
        element: <Permissions />,
      },
      {
        path: SCREENS.cards,
        element: <CardsPage />,
      },
      {
        path: SCREENS.address,
        element: <EnterpriseAddressPage />,
      },
    ],
  },
  {
    path: '*',
    element: <div>Ops, rota não encontrada</div>,
  },
]);
