import { TagTypes } from '@vizir-banking/design-system/web';
import { useEffect, useState } from 'react';

import { BatchProposalStatusEnum } from '~/api/constants';
import {
  useBatchProposalServiceBatchProposalControllerGetSpecificBatchDetails,
  useBatchProposalServiceBatchProposalControllerGetSpecificBatchStatus,
  useBatchProposalServiceBatchProposalControllerReprocess,
} from '~/api/queries';
import {
  BatchProposalStatusEnum as BatchProposalStatusEnumType,
  GetSpecificBatchDetailsResponseDto,
} from '~/api/requests';
import { useTranslation } from '~/translates/use-translate';

export type BatchDetailsProps = {
  detailedBatch: GetSpecificBatchDetailsResponseDto | undefined;
  batchStatus: BatchProposalStatusEnumType | undefined;
  translateParent: (key: string) => string;
  isBatchLoading: boolean;
  isBatchStatusLoading: boolean;
  csvWithDeclinedProposals: string;
  tagType: TagTypes;
  notFound: boolean;
};

export const useBatchDetails = (batchId: string): BatchDetailsProps => {
  const [selectedBatch, setSelectedBatch] = useState<GetSpecificBatchDetailsResponseDto | undefined>();

  const getBatch = useBatchProposalServiceBatchProposalControllerGetSpecificBatchDetails(
    {
      id: batchId,
    },
    undefined,
    {
      enabled: !!batchId,
    }
  );

  const declinedProposals = useBatchProposalServiceBatchProposalControllerReprocess({
    id: batchId,
  });

  const detailedBatchStatus = useBatchProposalServiceBatchProposalControllerGetSpecificBatchStatus({
    id: batchId,
  });

  const getTagType = (status: BatchProposalStatusEnumType | '') => {
    switch (status) {
      case BatchProposalStatusEnum.PROCESSED:
        return TagTypes.success;
      case BatchProposalStatusEnum.FAILED:
        return TagTypes.negative;
      case BatchProposalStatusEnum.PARTIAL_PROCESSED || BatchProposalStatusEnum.PROCESSING:
        return TagTypes.warning;
      default:
        return TagTypes.neutral;
    }
  };

  useEffect(() => {
    setSelectedBatch(getBatch.data);
  }, [getBatch.data]);

  return {
    detailedBatch: selectedBatch,
    batchStatus: detailedBatchStatus.data?.status,
    isBatchLoading: getBatch.isLoading,
    isBatchStatusLoading: detailedBatchStatus.isLoading,
    csvWithDeclinedProposals: String(declinedProposals.data) ?? '',
    translateParent: useTranslation('components.table.batches.table.statusCodes'),
    tagType: getTagType(detailedBatchStatus.data?.status ?? ''),
    notFound: getBatch.isError,
  };
};
