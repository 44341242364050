import { IconographyNames } from '@vizir-banking/design-system';
import {
  Button as BaseButton,
  ButtonSizes,
  ButtonTypes,
  Skeleton as BaseSkeleton,
  SkeletonVariants,
  Tag as BaseTags,
  TagSizes,
  TagTypes,
  TagVariants,
  Typography,
  TypographyVariants,
} from '@vizir-banking/design-system/web';
import styled from 'styled-components';

import { getColor } from '~/styles/theme/get-theme';

export const Container = styled.div`
  flex: 1;
  margin-top: 24px;
  flex-direction: column;
  justify-content: space-between;
`;

export const Section = styled.div`
  width: 100%;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const SectionTitle = styled(Typography).attrs({
  variant: TypographyVariants.SUBTITLE,
})`
  color: ${getColor('neutral', 'darkest')};
  font-weight: 800;
`;

export const AddressInfo = styled.div`
  width: 100%;
  display: flex;
  border: 2px solid ${getColor('neutral', 'light')};
  background-color: ${getColor('neutral', 'lighter')};
  padding: 16px 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 8px;
  gap: 24px;
  cursor: pointer;
`;

export const AddressTitle = styled(Typography).attrs({
  variant: TypographyVariants.DIALOG,
})`
  color: ${getColor('neutral', 'darkest')};
`;

export const PrimaryButton = styled(BaseButton).attrs(() => ({
  buttonType: ButtonTypes.primary,
  buttonSize: ButtonSizes.medium,
  trailingIconName: IconographyNames.chevronRight,
  trailingIconColor: getColor('neutral', 'darkest'),
}))``;

export const TertiaryButton = styled(BaseButton).attrs(() => ({
  buttonType: ButtonTypes.tertiary,
  buttonSize: ButtonSizes.small,
}))`
  color: ${getColor('error', 'medium')};
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
`;

export const Skeleton = styled(BaseSkeleton).attrs({
  variant: SkeletonVariants.textField,
})`
  width: 100%;
`;

export const MainTag = styled(BaseTags).attrs({
  size: TagSizes.medium,
  type: TagTypes.neutral,
  variant: TagVariants.primary,
  label: 'Principal',
})``;
