import { IconographyNames } from '@vizir-banking/design-system';
import { Link } from '@vizir-banking/design-system/web';
import { MouseEvent as ReactMouseEvent, ReactElement, ReactNode, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import { Fragment } from '~/styles/global-styles';

import { Container, Icon, MenuIcon, Option, OptionLabel } from './dropdown.styles';
import { useDropdown } from './dropdown-context';

export interface IOptions {
  label: string;
  hasLeadingIcon?: boolean;
  leadingIconName?: IconographyNames;
  color?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onAction: (value?: any) => void;
}

export interface Position {
  top?: number;
  left?: number;
  right?: number;
  bottom?: number;
}

interface IDropdown {
  options: IOptions[];
  position?: Position;
  dropdownId: string;
}

export const Dropdown = ({ options, position, dropdownId }: IDropdown): ReactElement => {
  const { openDropdownId, setOpenDropdownId } = useDropdown();
  const dropdownRef = useRef<HTMLDivElement>(null);

  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });

  const calculatePosition = (eventTarget: HTMLElement) => {
    const rect = eventTarget.getBoundingClientRect();
    setDropdownPosition({ top: rect.bottom + window.scrollY, left: rect.left + window.scrollX - 120 });
  };

  const handleMenuClick = (event: ReactMouseEvent<HTMLAnchorElement>) => {
    calculatePosition(event.currentTarget);
    setOpenDropdownId(openDropdownId === dropdownId ? null : dropdownId);
  };

  const handleOutsideClick = (event: MouseEvent) => {
    const target = event.target as HTMLElement;
    const isDropdownOption = target.classList.contains('dropdown-option');
    const isSvgElement = target instanceof SVGElement;
    const isSpanElement = target instanceof HTMLSpanElement;

    if (dropdownRef.current && !isDropdownOption && !isSvgElement && !isSpanElement) {
      setOpenDropdownId(null);
    }
  };

  const handleResize = () => {
    setOpenDropdownId(null);
  };

  useEffect(() => {
    if (openDropdownId === dropdownId) {
      document.addEventListener('mousedown', handleOutsideClick);
      window.addEventListener('resize', handleResize);
    }
  }, [openDropdownId, dropdownId]);

  const renderOption = ({ label, hasLeadingIcon = false, leadingIconName, onAction, color }: IOptions) => (
    <Option
      onClick={() => {
        setOpenDropdownId(null);
        onAction();
      }}
      className='dropdown-option'
    >
      {hasLeadingIcon && <Icon iconName={leadingIconName} color={color} />}
      <OptionLabel color={color} className='dropdown-option'>
        {label}
      </OptionLabel>
    </Option>
  );

  const PortalDropdown = ({ children, position }: { children: ReactNode; position: Position }) => {
    return createPortal(
      <div
        ref={dropdownRef}
        style={{ position: 'absolute', top: position.top, left: position.left, zIndex: 1000 }}
      >
        {children}
      </div>,
      document.body
    );
  };

  return (
    <Fragment>
      <Link
        // @ts-expect-error - it exists
        onClick={handleMenuClick}
      >
        <MenuIcon />
      </Link>
      {openDropdownId === dropdownId && (
        <PortalDropdown position={dropdownPosition}>
          <Container position={position}>{options.map((option) => renderOption(option))}</Container>
        </PortalDropdown>
      )}
    </Fragment>
  );
};
