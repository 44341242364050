import { useState } from 'react';

import { CardsSteps } from './types';

interface ICardsHook {
  step: CardsSteps;
  onChangeStep: (step: CardsSteps) => void;
}

export const useCardsHook = (): ICardsHook => {
  const [step, setStep] = useState(CardsSteps.CARD_LIST);

  const onChangeStep = (step: CardsSteps) => {
    setStep(step);
  };

  return {
    step,
    onChangeStep,
  };
};
