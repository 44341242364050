import { ReactElement, ReactNode } from 'react';

import { useScreenHook } from './screen.hook';
import { ScreenLayout } from './screen.layout';
import { ScreenTypes } from './types';

interface IScreen {
  children: ReactNode;
  type?: ScreenTypes;
  goBack?: () => void;
}

export const Screen = ({ children, type = ScreenTypes.SIMPLE, goBack }: IScreen): ReactElement => {
  const properties = useScreenHook();

  return <ScreenLayout children={children} type={type} goBack={goBack} {...properties} />;
};
