/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement } from 'react';
import styled from 'styled-components';

const Text = styled.span`
  font-family: normal;
`;

const Bold = styled.span`
  font-family: bold;
`;

export const Paragraphy = styled.p`
  display: inline;
`;

export const autoSingleStyle = (
  text: string,
  separator = '*',
  Regular: React.ComponentType<any> = Text,
  Stylized: React.ComponentType<any> = Bold,
  stylizedProps: any = {}
): ReactElement => {
  const pieces = text.split(separator).map((piece, index) => {
    if (index % 2 === 0) {
      return <Regular key={index}>{piece}</Regular>;
    }

    return (
      <Stylized key={index} {...stylizedProps}>
        {piece}
      </Stylized>
    );
  });

  return <Paragraphy>{pieces}</Paragraphy>;
};
