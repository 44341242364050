import { IconographyNames } from '@vizir-banking/design-system';
import { ButtonProps as BaseButtonProps } from '@vizir-banking/design-system/web';
import { ChangeEvent, DragEvent, MouseEvent, ReactElement, useRef } from 'react';

import { Recharge } from '~/assets/sheets-mapping';
import { ErrorDialog } from '~/components/dialogs/error-dialog/error-dialog';
import { FragmentCol } from '~/styles/global-styles';
import { useTranslation } from '~/translates/use-translate';
import { autoSingleStyle } from '~/utils/auto-single-style';

import { RechargeSteps } from '../../types';
import {
  Banner,
  ButtonWrapper,
  Column,
  Container,
  Dialog,
  DialogLink,
  Divider,
  DividerText,
  DividerWrapper,
  ErrorIcon,
  ErrorNotification,
  FileContainer,
  FileDragArea,
  FileWrapper,
  Icon,
  Link,
  Paragraph,
  PrimaryButton,
  RedirectDialog,
  Row,
  Title,
  Top,
} from './send.styles';
import { RechargeSendStep } from './types';

type ButtonProps = Omit<BaseButtonProps, 'buttonType' | 'buttonSize'>;

interface IRechargeSend {
  file: File | null;
  error: string;
  step: RechargeSendStep;
  onChangeTab: (tab: number) => void;
  onChangeStep: (step: RechargeSendStep) => void;
  onChangeNewRechargeStep: (step: RechargeSteps) => void;
  onFileChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onDrop: (event: DragEvent<HTMLFormElement>) => void;
  goToNextStep: () => void;
  onCloseError: () => void;
  isRedirectionModalVisible: boolean;
  toggleRedirectionModal: () => void;
  handleCreateOrder: () => Promise<void>;
  isCreateOrderLoading: boolean;
}
export const RechargeSendLayout = ({
  error,
  file,
  step,
  onDrop,
  onFileChange,
  goToNextStep,
  onChangeTab,
  onCloseError,
  onChangeStep,
  onChangeNewRechargeStep,
  isRedirectionModalVisible,
  toggleRedirectionModal,
  handleCreateOrder,
  isCreateOrderLoading,
}: IRechargeSend): ReactElement => {
  const downloadLinkRef = useRef<HTMLAnchorElement>(null);
  const translate = useTranslation('pages.recharge.newRecharge.tabs.send');

  const cancelAction = () => {
    const actions = {
      [RechargeSendStep.SEND]: () => onChangeNewRechargeStep(RechargeSteps.BALANCE),
      [RechargeSendStep.LOAD]: () => onChangeStep(RechargeSendStep.SEND),
    };

    return actions[step]();
  };

  const nextAction = () => {
    const actions = {
      [RechargeSendStep.SEND]: () => goToNextStep(),
      [RechargeSendStep.LOAD]: () => onChangeTab(1),
    };

    return actions[step]();
  };

  const renderSheetModel = () => {
    const handleDownloadClick = () => {
      if (downloadLinkRef.current) {
        downloadLinkRef.current.click();
      }
    };

    return (
      <Top>
        <p>
          {autoSingleStyle(translate('sheetModel.title'), '*', Dialog, DialogLink, {
            onClick: handleDownloadClick,
          })}
        </p>

        <a
          href={Recharge}
          download='planilha_de_pedidos.xlsx'
          ref={downloadLinkRef}
          style={{ display: 'none' }}
        />
      </Top>
    );
  };

  const renderDivider = () => {
    return (
      <DividerWrapper>
        <Divider />
        <DividerText>{translate('divider')}</DividerText>
        <Divider />
      </DividerWrapper>
    );
  };

  const renderFileArea = () => {
    const onFileClick = (event: MouseEvent<HTMLFormElement>) => {
      event.preventDefault();
      event.stopPropagation();
      //@ts-expect-error - The input is hidden
      document.querySelector('.input-file')?.click();
    };

    const onDragOver = (event: DragEvent<HTMLFormElement>) => {
      event.preventDefault();
    };

    return (
      <FileDragArea action='' onClick={onFileClick} onDrop={onDrop} onDragOver={onDragOver}>
        <input
          type='file'
          className='input-file'
          hidden
          onClick={(event) => event.stopPropagation()}
          onChange={onFileChange}
        />
        <Row>
          <Icon iconName={IconographyNames.upload} />
          <Title>{translate('fileArea.title')}</Title>
        </Row>
        <Dialog>{translate('fileArea.formats')}</Dialog>

        <PrimaryButton label={translate('fileArea.button')} onClick={onFileClick} />
        {file && <Dialog>{file.name}</Dialog>}

        <Paragraph>{translate('fileArea.paragraph')}</Paragraph>

        {error && (
          <ErrorNotification
            leadingText={{
              label: error,
            }}
            trailingOnClose={onCloseError}
          />
        )}
      </FileDragArea>
    );
  };

  const getFileSize = (): string => {
    if (file)
      return file.size > 1_000_000
        ? `${(Number(file?.size) / 1_000_000).toFixed(2)}MB`
        : `${(Number(file?.size) / 1_000).toFixed(2)}KB`;
    return '0KB';
  };

  const renderButtons = () => {
    return (
      <ButtonWrapper>
        <Link onClick={cancelAction}>{translate('buttons.cancel')}</Link>
        <PrimaryButton
          label={step == RechargeSendStep.SEND ? translate('buttons.next') : translate('buttons.createOrder')}
          onClick={() => {
            if (step === RechargeSendStep.LOAD) {
              handleCreateOrder();

              return;
            }
            nextAction();
          }}
          trailingIconName={IconographyNames.chevronRight}
          withTrailingIcon
          isDisabled={!file}
          isLoading={isCreateOrderLoading}
        />
      </ButtonWrapper>
    );
  };

  const renderFile = () => {
    return (
      <FragmentCol>
        <FileContainer>
          <FileWrapper>
            <Row>
              <Icon iconName={IconographyNames.file} />

              <Column>
                <Dialog>{file?.name}</Dialog>
                <Paragraph>{getFileSize()}</Paragraph>
              </Column>
            </Row>

            <Link onClick={cancelAction}>
              <ErrorIcon iconName={IconographyNames.trash} />
            </Link>
          </FileWrapper>
        </FileContainer>
        <Banner title={translate('banners.mayTakeAWhile')} />
        <Banner title={translate('banners.maxItems')} />
      </FragmentCol>
    );
  };

  const redirectButtonProps: ButtonProps = {
    label: translate('redirectDialog.toList'),
    onClick: () => onChangeNewRechargeStep(RechargeSteps.BALANCE),
    containerProps: {
      style: {
        width: '80%',
      },
    },
  };

  const renderContent = () => {
    return step === RechargeSendStep.LOAD ? (
      <FragmentCol style={{ flex: 1 }}>
        {renderFile()}
        {renderButtons()}
      </FragmentCol>
    ) : (
      <FragmentCol style={{ flex: 1 }}>
        {renderSheetModel()}
        {renderDivider()}
        {renderFileArea()}
        {renderButtons()}
      </FragmentCol>
    );
  };

  return (
    <Container>
      {renderContent()}

      <RedirectDialog
        title={translate('redirectDialog.title')}
        isVisible={isRedirectionModalVisible}
        primaryButtonProps={redirectButtonProps}
        onOutsideClick={toggleRedirectionModal}
      ></RedirectDialog>

      <ErrorDialog />
    </Container>
  );
};
