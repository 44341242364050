import { Theme } from '@vizir-banking/design-system';
import {
  Button as BaseButton,
  ButtonSizes,
  ButtonTypes,
  Icon as BaseIcon,
  Skeleton as BaseSkeleton,
  SkeletonVariants,
  Table as BaseTable,
  TableCell as BaseTableCell,
  TableHeadCell as BaseTableHeadCell,
  TableRow as BaseTableRow,
  TableTagCell as BaseTableTagCell,
  Typography,
  TypographyVariants,
} from '@vizir-banking/design-system/web';
import styled from 'styled-components';

import { BatchProposalStatusEnum } from '~/api/constants';
import { BatchProposalStatusEnum as BatchProposalStatusEnumType } from '~/api/requests/types.gen';
import { Screen as BaseScreen } from '~/components/screen/screen';
import { ScreenTypes } from '~/components/screen/types';
import { getColor } from '~/styles/theme/get-theme';

export const Screen = styled(BaseScreen).attrs({
  type: ScreenTypes.LOGGED,
})``;

export const Wrapper = styled.div`
  justify-content: space-between;
  align-items: center;

  margin-bottom: 48px;
`;

export const Title = styled(Typography).attrs({
  variant: TypographyVariants.HEADING_XL,
})``;

export const AddCollaboratorsButton = styled(BaseButton).attrs((props: { theme: Theme }) => ({
  buttonType: ButtonTypes.primary,
  buttonSize: ButtonSizes.medium,
  withLeadingIcon: true,
  leadingIconName: 'add',
  leadingIconColor: props.theme.designSystem.palette.neutral.darkest,
}))`
  color: ${({ theme }) => theme.designSystem.palette.neutral.darkest};
`;

export const TableContainer = styled(BaseTable)`
  overflow-y: visible;
  height: auto;
  margin-top: 24px;
`;

export const TableHeadRow = styled(BaseTableRow)`
  background-color: ${getColor('neutral', 'lighter')};
`;

export const TableHeadCell = styled(BaseTableHeadCell)`
  background-color: ${getColor('neutral', 'lighter')};
`;

export const TableBodyRow = styled(BaseTableRow)<{ status: string }>``;

export const TableBodyCell = styled(BaseTableCell)`
  border: 1px solid ${getColor('neutral', 'lighter')};
`;

export const TableBodyTagCell = styled(BaseTableTagCell)<{ status: BatchProposalStatusEnumType }>`
  border: 1px solid ${getColor('neutral', 'lighter')};

  & > div {
    & > div {
      background-color: ${({ status, theme }) => {
        switch (status) {
          case BatchProposalStatusEnum.PARTIAL_PROCESSED:
            return theme.designSystem.palette.warning.light;
          case BatchProposalStatusEnum.PROCESSING:
            return theme.designSystem.palette.warning.light;
          case BatchProposalStatusEnum.PROCESSED:
            return theme.designSystem.palette.success.light;
          case BatchProposalStatusEnum.FAILED:
            return theme.designSystem.palette.error.light;
          default:
            return theme.designSystem.palette.primary.regular;
        }
      }};
    }
  }
`;

export const StatusSkeleton = styled(BaseSkeleton).attrs({
  variant: SkeletonVariants.tag,
})`
  height: 32px;
  width: 70px;
`;

export const Skeleton = styled(BaseSkeleton).attrs({
  variant: SkeletonVariants.custom,
})``;

export const BatchSkeleton = styled.div`
  height: 40px;
  width: 250px;
`;

export const DateSkeleton = styled.div`
  height: 40px;
  width: 250px;
`;

export const DateWrapper = styled.div`
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
`;

export const CalendarIcon = styled(BaseIcon).attrs(({ theme }) => ({
  iconName: 'calendar',
  color: theme.designSystem.palette.neutral.darkest,
}))`
  height: 24px;
  width: 24px;
`;
