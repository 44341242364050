import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';

import { useAuthServiceAuthControllerLoginByDocumentPassword } from '~/api/queries';
import { LoginUserDto, OpenAPI } from '~/api/requests';
import { SCREENS } from '~/app/routes/screen-definitions';
import { setToken } from '~/redux/reducers/user';

export type LoginProps = {
  user: LoginUserDto;
  handleUser: (name: string, value: string) => void;
  isEmailError: boolean;
  isPasswordError: boolean;
  isLoading: boolean;
  goToForgotPassword: () => void;
  goToRequestAccess: () => void;
  onLogin: () => Promise<void>;
};

const INITIAL_STATE: LoginUserDto = {
  document: '',
  password: '',
};

export const useLogin = (): LoginProps => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [user, setUser] = useState<LoginUserDto>(INITIAL_STATE);

  const login = useAuthServiceAuthControllerLoginByDocumentPassword({
    onSuccess: (session) => {
      dispatch(setToken(session.token));
      OpenAPI.HEADERS = {
        ...OpenAPI.HEADERS,
        authorization: `Bearer ${session.token}`,
      };

      navigate(SCREENS.home);
    },
  });

  const handleUser = (name: string, value: string) => {
    setUser({ ...user, [name]: value });
  };

  const isEmailError = (): boolean => {
    const schema = z.string().email();

    if (user.document.length === 0 || schema.safeParse(user.document).success) return false;
    return true;
  };

  const isPasswordError = (): boolean => {
    if (user.password.length >= 8) return false;
    return true;
  };

  const onLogin = async (): Promise<void> => {
    OpenAPI.HEADERS = {
      'x-document': user.document,
    };

    login.mutate({ requestBody: user });
  };

  const goToForgotPassword = () => {
    navigate(SCREENS.forgotPassword);
  };

  const goToRequestAccess = () => {
    navigate(SCREENS.forgotPassword, {
      state: { isFirstAccess: true },
    });
  };

  return {
    user,
    handleUser,
    isEmailError: isEmailError(),
    isPasswordError: isPasswordError(),
    isLoading: login.isPending,
    goToForgotPassword,
    goToRequestAccess,
    onLogin,
  };
};
