export const maskEmail = (email: string) => {
  const [localPart, domainPart] = email.split('@');
  if (!localPart || !domainPart) {
    return '';
  }

  const maskedLocalPart = localPart.slice(0, 2) + '*'.repeat(localPart.length - 4) + localPart.slice(-2);
  const maskedDomainPart =
    domainPart.slice(0, 2) + '*'.repeat(domainPart.indexOf('.')) + domainPart.slice(domainPart.indexOf('.'));

  return `${maskedLocalPart}@${maskedDomainPart}`;
};
