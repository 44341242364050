import { Typography, TypographyVariants } from '@vizir-banking/design-system/web';
import styled from 'styled-components';

export const Container = styled.div<{ size: number }>`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  background-color: ${({ theme }) => theme.designSystem.palette.primary.regular};
  border-radius: 50%;
  align-items: center;
  justify-content: center;
`;

export const Initial = styled(Typography).attrs({
  variant: TypographyVariants.HEADING_XL,
})`
  color: ${({ theme }) => theme.designSystem.palette.neutral.darkest};
`;
