import { useNavigate } from 'react-router-dom';

import { SCREENS } from '~/app/routes/screen-definitions';
import { ErrorDialog } from '~/components/dialogs/error-dialog/error-dialog';
import { SuccessDialog } from '~/components/dialogs/success-dialog/success-dialog';
import { LeadingContentType, PageHeader } from '~/components/page-header/page-header';
import { useTranslation } from '~/translates/use-translate';

import { BatchCollaboratorsProps, BatchCollaboratorsSteps } from './batch-collaborators.hook';
import { Screen } from './batch-collaborators.styles';
import { Review } from './review/review';
import { SelectFile } from './select-file/select-file';

export const BatchCollaboratorsLayout = ({
  step,
  onFileChange,
  onDrop,
  file,
  onDeleteFile,
  isLoading,
  onSubmitFile,
  onSuccess,
}: BatchCollaboratorsProps) => {
  const translate = useTranslation('pages.batchCollaborators');
  const navigate = useNavigate();

  const successButton = {
    label: translate('success'),
    onClick: onSuccess,
  };

  const steps = {
    [BatchCollaboratorsSteps.SELECT_FILE]: <SelectFile onDrop={onDrop} onFileChange={onFileChange} />,
    [BatchCollaboratorsSteps.REVIEW]: (
      <Review file={file} onDelete={onDeleteFile} isLoading={isLoading} onSubmit={onSubmitFile} />
    ),
  };

  return (
    <Screen>
      <PageHeader
        leadingContentType={LeadingContentType.GO_BACK}
        leadingGoBack={() => {
          navigate(SCREENS.listBatches);
        }}
        title={translate('title')}
      />
      {steps[step]}

      <SuccessDialog closeOnClickOutside={false} secondaryButtonProps={successButton} />
      <ErrorDialog />
    </Screen>
  );
};
