import { ErrorsEnum } from './types';

export const ptBrErrorTranslations = {
  errors: {
    generic_error: {
      title: 'Ops, algo deu errado!',
      description: 'Tivemos um problema! Por favor, tente novamente.',
      button: 'Fechar',
    },
    [ErrorsEnum.AUTHENTICATION_FAILED]: {
      title: 'Ops, algo deu errado!',
      description: 'E-mail ou senha não correspondem.',
      button: 'Entendi',
    },
    [ErrorsEnum.VALIDATION_INVALID]: {
      title: 'Ops, algo deu errado!',
      description: 'Código de verificação incorreto.',
      button: 'Fechar',
    },
    [ErrorsEnum.EMAIL_NOT_SENT]: {
      title: 'Ops, algo deu errado!',
      description: 'Falha ao enviar o e-mail.',
      button: 'Fechar',
    },
    [ErrorsEnum.EXPIRED_ACCESS_CODE]: {
      title: 'Ops, algo deu errado!',
      description: 'Que pena, seu link expirou.',
      button: 'Fechar',
    },
    [ErrorsEnum.INVALID_PASSWORD_POLICY]: {
      title: 'Ops, algo deu errado!',
      description: 'A senha informada não cumpre os requisistos mínimos de segurança.',
      button: 'Fechar',
    },
    [ErrorsEnum.TOKEN_WITHOUT_SCOPES]: {
      title: 'Ops, algo deu errado!',
      description: 'Puxa, você não tem permissão para fazer isso.',
      button: 'Fechar',
    },
    [ErrorsEnum.INVALID_FILE]: {
      title: 'Ops, algo deu errado!',
      description: 'O arquivo selecionado não é um formato compatível.',
      button: 'Fechar',
    },
    [ErrorsEnum.BATCH_PROPOSAL_NO_FILE]: {
      title: 'Ops, algo deu errado!',
      description: 'Nenhum arquivo encontrado.',
      button: 'Fechar',
    },
    [ErrorsEnum.BATCH_PROPOSAL_EMPTY_FILE]: {
      title: 'Ops, algo deu errado!',
      description: 'O arquivo selecionado está vazio.',
      button: 'Fechar',
    },
    [ErrorsEnum.BATCH_PROPOSAL_EMPTY_COLUMNS]: {
      title: 'Ops, algo deu errado!',
      description: 'O arquivo selecionado não possui colunas.',
      button: 'Fechar',
    },
    [ErrorsEnum.BATCH_PROPOSAL_INVALID_COLUMNS]: {
      title: 'Ops, algo deu errado!',
      description: 'O arquivo selecionado possui erro nas colunas.',
      button: 'Fechar',
    },
    [ErrorsEnum.BATCH_PROPOSAL_MAX_BATCH_SIZE]: {
      title: 'Ops, algo deu errado!',
      description: 'O arquivo selecionado é muito grande.',
      button: 'Entendi',
    },
    [ErrorsEnum.BATCH_PROPOSAL_MIN_BATCH_SIZE]: {
      title: 'Ops, algo deu errado!',
      description: 'O arquivo selecionado não possui o tamanho mínimo.',
      button: 'Entendi',
    },
    [ErrorsEnum.EXPIRED_TOKEN]: {
      title: 'Sessão Expirada',
      description: 'Sua sessão expirou. Clique no botão abaixo para fazer login novamente.',
      button: 'Entendi',
    },
    [ErrorsEnum.SPLIT_ORDER]: {
      title: 'Ops, algo deu errado!',
      description: 'Falha ao distribuir o pedido. Por favor, tente novamente.',
      button: 'Fechar',
    },
    [ErrorsEnum.ADDRESS_LIMIT_REACHED]: {
      title: 'Ops, algo deu errado!',
      description: 'Limite de endereços atingido.',
      button: 'Fechar',
    },
    [ErrorsEnum.LOAD_ORDER_MIN_ITEMS]: {
      title: 'Ops, algo deu errado!',
      description: 'O arquivo selecionado não possui o número mínimo de itens.',
      button: 'Fechar',
    },
    [ErrorsEnum.LOAD_ORDER_MAX_ITEMS]: {
      title: 'Ops, algo deu errado!',
      description: 'O arquivo selecionado excedeu o número máximo de itens (40).',
      button: 'Fechar',
    },
    [ErrorsEnum.DOCK_ONE_BAD_REQUEST]: {
      title: 'Ops, algo deu errado!',
      description: 'Dados inválidos na planilha.',
      button: 'Fechar',
    },
  },
};
