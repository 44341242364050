import { AddressDto } from '~/api/requests';

import { useNewCardsAddressHook } from './address.hook';
import { NewCardsAddressSection } from './address.layout';

interface INewCards {
  address: AddressDto;
  onChangeSelectAddress: (address: AddressDto) => void;
  onCancel: () => void;
  onChangeTab: (tab: number) => void;
}

export const NewCardsAddress = ({ address, onCancel, onChangeTab, onChangeSelectAddress }: INewCards) => {
  const props = useNewCardsAddressHook({ address, onChangeTab });

  return (
    <NewCardsAddressSection
      {...props}
      onCancel={onCancel}
      selectAddress={address}
      onChangeSelectAddress={onChangeSelectAddress}
    />
  );
};
