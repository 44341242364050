import { Icon as BaseIcon, Typography, TypographyVariants } from '@vizir-banking/design-system/web';
import styled from 'styled-components';

import { getColor } from '~/styles/theme/get-theme';

export const Wrapper = styled.div`
  width: min-content;
  padding: 8px 16px;
  gap: 8px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.designSystem.palette.neutral.lighter};
`;

export const Label = styled(Typography).attrs(({ variant }) => ({
  variant: variant ?? TypographyVariants.PARAGRAPH_XS,
}))<{ color?: string; variant: TypographyVariants }>`
  flex: 1;
  display: inline;
  color: ${({ color }) => color || getColor('neutral', 'darkest')};
`;

export const Icon = styled(BaseIcon)<{ size: number }>`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
`;
