import { ChangeEvent, DragEvent, MouseEvent } from 'react';

import { Collaborators } from '~/assets/sheets-mapping';
import { useTranslation } from '~/translates/use-translate';

import {
  Container,
  Dialog,
  Divider,
  DividerText,
  DividerWrapper,
  Emphasis,
  FileDragArea,
  Paragraph,
  SecondaryButton,
  SheetModelWrapper,
  Title,
  TitleWrapper,
  UploadIcon,
} from './select-file.styles';

type SelectFileProps = {
  onFileChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onDrop: (event: DragEvent<HTMLFormElement>) => void;
};

export const SelectFile = ({ onFileChange, onDrop }: SelectFileProps) => {
  const translate = useTranslation('pages.batchCollaborators.selectFile');

  const onFileClick = (event: MouseEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();
    //@ts-expect-error - The input is hidden
    document.querySelector('.input-file')?.click();
  };

  const onDragOver = (event: DragEvent<HTMLFormElement>) => {
    event.preventDefault();
  };

  const renderSheetModel = () => {
    return (
      <SheetModelWrapper>
        <Dialog>
          {`${translate('sheetModel.description')} `}
          <a
            href={Collaborators}
            download='planilha_de_colaboradores.xlsx'
            style={{
              textDecoration: 'none',
            }}
          >
            <Emphasis>{translate('sheetModel.button')}</Emphasis>
          </a>
        </Dialog>
      </SheetModelWrapper>
    );
  };

  const renderDivider = () => {
    return (
      <DividerWrapper>
        <Divider />
        <DividerText>{translate('divider')}</DividerText>
        <Divider />
      </DividerWrapper>
    );
  };

  const renderFileArea = () => {
    return (
      <FileDragArea action='' onDrop={onDrop} onDragOver={onDragOver}>
        <input
          type='file'
          className='input-file'
          hidden
          onClick={(event) => event.stopPropagation()}
          onChange={onFileChange}
        />
        <TitleWrapper>
          <UploadIcon />
          <Title>{translate('fileArea.title')}</Title>
        </TitleWrapper>
        <Dialog>{translate('fileArea.formats')}</Dialog>

        <SecondaryButton
          label={translate('fileArea.button')}
          // @ts-expect-error - The input is hidden
          onClick={onFileClick}
        />

        <Paragraph>{translate('fileArea.paragraph')}</Paragraph>
      </FileDragArea>
    );
  };

  return (
    <Container>
      {renderSheetModel()}
      {renderDivider()}
      {renderFileArea()}
      <Dialog>{translate('below')}</Dialog>
    </Container>
  );
};
