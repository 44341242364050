import { IconographyNames } from '@vizir-banking/design-system';
import {
  Banner as BaseBanner,
  BannerTypes,
  Button as BaseButton,
  ButtonSizes,
  ButtonTypes,
  Icon as BaseIcon,
  Link as BaseLink,
  Typography,
  TypographyVariants,
} from '@vizir-banking/design-system/web';
import styled from 'styled-components';

import { getColor } from '~/styles/theme/get-theme';

export const Container = styled.div`
  flex-direction: column;
  align-items: end;
`;

export const Link = styled(BaseLink).attrs({})``;

export const DeleteIcon = styled(BaseIcon).attrs({
  color: getColor('error', 'medium'),
  iconName: IconographyNames.trash,
})`
  width: 24px;
  height: 24px;
`;

export const Banner = styled(BaseBanner).attrs({
  type: BannerTypes.info,
})`
  margin: 24px 0px;
`;

export const FileContainer = styled.div`
  width: 100%;
  margin-top: 24px;
  background-color: ${getColor('neutral', 'lighter')};
  border: 2px solid ${getColor('neutral', 'light')};
  border-radius: 8px;
  padding: 16px 24px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const FileWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;

export const FileIcon = styled(BaseIcon).attrs({
  color: getColor('neutral', 'darkest'),
  iconName: IconographyNames.file,
})`
  width: 24px;
  height: 24px;
`;

export const FileInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FileName = styled(Typography).attrs({
  variant: TypographyVariants.DIALOG,
})`
  color: ${getColor('neutral', 'darkest')};
`;

export const FileSize = styled(Typography).attrs({
  variant: TypographyVariants.PARAGRAPH_XS,
})`
  margin-top: 8px;
  color: ${getColor('neutral', 'dark')};
`;

export const PrimaryButton = styled(BaseButton).attrs(() => ({
  buttonType: ButtonTypes.primary,
  buttonSize: ButtonSizes.medium,
}))`
  width: fit-content;
  padding: 0px 60px;
`;
