import { createContext, ReactElement, useContext, useState } from 'react';

import { VerificatonCodeDialog } from '~/components/dialogs/verification-code-dialog/verification-code-dialog';

import { DialogProps, Props, ShowDialogResponse, VerificationCodeContextType } from './types';

const VerificationCodeContext = createContext<VerificationCodeContextType | undefined>(undefined);

export const useVerificationCodeContext = () => {
  const context = useContext(VerificationCodeContext);

  if (!context) {
    throw new Error('useVerificationCodeContext must be used within a VerificationCodeProvider');
  }

  return context;
};

export function VerificationCodeProvider({ children }: Props): ReactElement {
  const [dialogProps, setDialogProps] = useState<DialogProps | null>(null);

  const showDialog = (email: string, path: string): Promise<ShowDialogResponse | null> => {
    return new Promise((resolve) => {
      setDialogProps({ email, path, resolve });
    });
  };

  const onComplete = (code: string, validationId?: string) => {
    if (dialogProps) {
      dialogProps.resolve({ code, validationId });
      setDialogProps(null);
    }
  };

  const onClose = (validationId?: string) => {
    dialogProps?.resolve({ code: '', validationId });
    setDialogProps(null);
  };

  return (
    <VerificationCodeContext.Provider value={{ showDialog }}>
      {children}
      {dialogProps && (
        <VerificatonCodeDialog
          email={dialogProps.email}
          path={dialogProps.path}
          onComplete={onComplete}
          onClose={onClose}
        />
      )}
    </VerificationCodeContext.Provider>
  );
}
