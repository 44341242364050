import { TableBody, TableHead } from '@vizir-banking/design-system/web';
import { formatToCPF } from 'brazilian-values';
import { ReactElement } from 'react';

import { SimplifiedEmployees } from '~/api/requests';
import { useTranslation } from '~/translates/use-translate';

import {
  Skeleton,
  TableBodyCell,
  TableBodyRow,
  TableContainer,
  TableHeadCell,
  TableHeadRow,
} from './collaborator-table.styles';

interface ICollaboratorTable {
  isLoading: boolean;
  onReachEndLoading: boolean;
  collaborators?: SimplifiedEmployees[];
  onClick?: (id: string) => void;
}

export const CollaboratorTable = ({
  isLoading,
  onReachEndLoading,
  collaborators,
  onClick,
}: ICollaboratorTable): ReactElement => {
  const translate = useTranslation('components.table.collaborator');

  const renderTableHeadRow = () => {
    return (
      <TableHeadRow>
        <TableHeadCell>{translate('table.name')}</TableHeadCell>
        <TableHeadCell>{translate('table.document')}</TableHeadCell>
        <TableHeadCell>{translate('table.email')}</TableHeadCell>
      </TableHeadRow>
    );
  };

  const renderTableBodyRows = ({ document, name, email, personId }: SimplifiedEmployees) => {
    return (
      <TableBodyRow onClick={onClick ? () => onClick(personId) : undefined}>
        <TableBodyCell>{name}</TableBodyCell>
        <TableBodyCell>{formatToCPF(document ?? '')}</TableBodyCell>
        <TableBodyCell>{email}</TableBodyCell>
      </TableBodyRow>
    );
  };

  const renderSkeletonLoadingRows = () => {
    const renderSkeletonCell = () => {
      return (
        <TableBodyCell>
          <Skeleton />
        </TableBodyCell>
      );
    };

    const renderSkeletonRow = () => {
      return (
        <TableBodyRow>
          {renderSkeletonCell()}
          {renderSkeletonCell()}
          {renderSkeletonCell()}
        </TableBodyRow>
      );
    };

    return Array.from({ length: 3 }).map((_) => renderSkeletonRow());
  };

  return (
    <TableContainer>
      <TableHead>{renderTableHeadRow()}</TableHead>
      <TableBody>
        {isLoading
          ? renderSkeletonLoadingRows()
          : collaborators?.map((collaborator) => renderTableBodyRows(collaborator))}
        {onReachEndLoading && renderSkeletonLoadingRows()}
      </TableBody>
    </TableContainer>
  );
};
