import { ListBatchesSteps } from '../types';
import { useBatchesView } from './batches-view.hook';
import { BatchesViewLayout } from './batches-view.layout';

interface IBatchesView {
  onChangeStep: (step: ListBatchesSteps) => void;
}

export const BatchesView = ({ onChangeStep }: IBatchesView) => {
  const { goToAddCollaborators, batches, scopes, isLoading, handleBatchClick, onReachEndLoading } =
    useBatchesView(onChangeStep);

  return (
    <BatchesViewLayout
      goToAddCollaborators={goToAddCollaborators}
      scopes={scopes}
      batches={batches}
      isLoading={isLoading}
      handleBatchClick={handleBatchClick}
      onReachEndLoading={onReachEndLoading}
    />
  );
};
