// generated with @7nohe/openapi-react-query-codegen@undefined 

import { UseQueryResult } from "@tanstack/react-query";
import { ApplicationService, AuthService, BatchProposalService, CardsService, CommonService, DefaultService, EmployeeService, EmployerService, LoadOrderService, SecurityService, UserService } from "../requests/services.gen";
import { CardBatchSortEnum, CardBatchStatusEnum, OrderEnum, OrderStatusEnum, RoleEnum } from "../requests/types.gen";
export type UserServiceUserControllerGetAllUsersDefaultResponse = Awaited<ReturnType<typeof UserService.userControllerGetAllUsers>>;
export type UserServiceUserControllerGetAllUsersQueryResult<TData = UserServiceUserControllerGetAllUsersDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useUserServiceUserControllerGetAllUsersKey = "UserServiceUserControllerGetAllUsers";
export const UseUserServiceUserControllerGetAllUsersKeyFn = ({ page, perPage, role }: {
  page?: number;
  perPage?: number;
  role?: RoleEnum;
} = {}, queryKey?: Array<unknown>) => [useUserServiceUserControllerGetAllUsersKey, ...(queryKey ?? [{ page, perPage, role }])];
export type UserServiceUserControllerGetUserDefaultResponse = Awaited<ReturnType<typeof UserService.userControllerGetUser>>;
export type UserServiceUserControllerGetUserQueryResult<TData = UserServiceUserControllerGetUserDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useUserServiceUserControllerGetUserKey = "UserServiceUserControllerGetUser";
export const UseUserServiceUserControllerGetUserKeyFn = (queryKey?: Array<unknown>) => [useUserServiceUserControllerGetUserKey, ...(queryKey ?? [])];
export type BatchProposalServiceBatchProposalControllerDownloadExampleDefaultResponse = Awaited<ReturnType<typeof BatchProposalService.batchProposalControllerDownloadExample>>;
export type BatchProposalServiceBatchProposalControllerDownloadExampleQueryResult<TData = BatchProposalServiceBatchProposalControllerDownloadExampleDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useBatchProposalServiceBatchProposalControllerDownloadExampleKey = "BatchProposalServiceBatchProposalControllerDownloadExample";
export const UseBatchProposalServiceBatchProposalControllerDownloadExampleKeyFn = (queryKey?: Array<unknown>) => [useBatchProposalServiceBatchProposalControllerDownloadExampleKey, ...(queryKey ?? [])];
export type BatchProposalServiceBatchProposalControllerGetProposalBatchesDefaultResponse = Awaited<ReturnType<typeof BatchProposalService.batchProposalControllerGetProposalBatches>>;
export type BatchProposalServiceBatchProposalControllerGetProposalBatchesQueryResult<TData = BatchProposalServiceBatchProposalControllerGetProposalBatchesDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useBatchProposalServiceBatchProposalControllerGetProposalBatchesKey = "BatchProposalServiceBatchProposalControllerGetProposalBatches";
export const UseBatchProposalServiceBatchProposalControllerGetProposalBatchesKeyFn = ({ createdAt, employerPersonId, page, perPage, proposalBatchId, statusId }: {
  createdAt?: string;
  employerPersonId?: string;
  page?: number;
  perPage?: number;
  proposalBatchId?: string;
  statusId?: number;
} = {}, queryKey?: Array<unknown>) => [useBatchProposalServiceBatchProposalControllerGetProposalBatchesKey, ...(queryKey ?? [{ createdAt, employerPersonId, page, perPage, proposalBatchId, statusId }])];
export type BatchProposalServiceBatchProposalControllerGetSpecificBatchDefaultResponse = Awaited<ReturnType<typeof BatchProposalService.batchProposalControllerGetSpecificBatch>>;
export type BatchProposalServiceBatchProposalControllerGetSpecificBatchQueryResult<TData = BatchProposalServiceBatchProposalControllerGetSpecificBatchDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useBatchProposalServiceBatchProposalControllerGetSpecificBatchKey = "BatchProposalServiceBatchProposalControllerGetSpecificBatch";
export const UseBatchProposalServiceBatchProposalControllerGetSpecificBatchKeyFn = ({ document, id, page, perPage, proposalId, proposalOnboardingId, statusId }: {
  document?: string;
  id: string;
  page?: number;
  perPage?: number;
  proposalId?: string;
  proposalOnboardingId?: string;
  statusId?: number;
}, queryKey?: Array<unknown>) => [useBatchProposalServiceBatchProposalControllerGetSpecificBatchKey, ...(queryKey ?? [{ document, id, page, perPage, proposalId, proposalOnboardingId, statusId }])];
export type BatchProposalServiceBatchProposalControllerGetSpecificBatchStatusDefaultResponse = Awaited<ReturnType<typeof BatchProposalService.batchProposalControllerGetSpecificBatchStatus>>;
export type BatchProposalServiceBatchProposalControllerGetSpecificBatchStatusQueryResult<TData = BatchProposalServiceBatchProposalControllerGetSpecificBatchStatusDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useBatchProposalServiceBatchProposalControllerGetSpecificBatchStatusKey = "BatchProposalServiceBatchProposalControllerGetSpecificBatchStatus";
export const UseBatchProposalServiceBatchProposalControllerGetSpecificBatchStatusKeyFn = ({ id }: {
  id: string;
}, queryKey?: Array<unknown>) => [useBatchProposalServiceBatchProposalControllerGetSpecificBatchStatusKey, ...(queryKey ?? [{ id }])];
export type BatchProposalServiceBatchProposalControllerFileErrorsDefaultResponse = Awaited<ReturnType<typeof BatchProposalService.batchProposalControllerFileErrors>>;
export type BatchProposalServiceBatchProposalControllerFileErrorsQueryResult<TData = BatchProposalServiceBatchProposalControllerFileErrorsDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useBatchProposalServiceBatchProposalControllerFileErrorsKey = "BatchProposalServiceBatchProposalControllerFileErrors";
export const UseBatchProposalServiceBatchProposalControllerFileErrorsKeyFn = ({ id }: {
  id: string;
}, queryKey?: Array<unknown>) => [useBatchProposalServiceBatchProposalControllerFileErrorsKey, ...(queryKey ?? [{ id }])];
export type BatchProposalServiceBatchProposalControllerGetSpecificBatchDetailsDefaultResponse = Awaited<ReturnType<typeof BatchProposalService.batchProposalControllerGetSpecificBatchDetails>>;
export type BatchProposalServiceBatchProposalControllerGetSpecificBatchDetailsQueryResult<TData = BatchProposalServiceBatchProposalControllerGetSpecificBatchDetailsDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useBatchProposalServiceBatchProposalControllerGetSpecificBatchDetailsKey = "BatchProposalServiceBatchProposalControllerGetSpecificBatchDetails";
export const UseBatchProposalServiceBatchProposalControllerGetSpecificBatchDetailsKeyFn = ({ id }: {
  id: string;
}, queryKey?: Array<unknown>) => [useBatchProposalServiceBatchProposalControllerGetSpecificBatchDetailsKey, ...(queryKey ?? [{ id }])];
export type BatchProposalServiceBatchProposalControllerReprocessDefaultResponse = Awaited<ReturnType<typeof BatchProposalService.batchProposalControllerReprocess>>;
export type BatchProposalServiceBatchProposalControllerReprocessQueryResult<TData = BatchProposalServiceBatchProposalControllerReprocessDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useBatchProposalServiceBatchProposalControllerReprocessKey = "BatchProposalServiceBatchProposalControllerReprocess";
export const UseBatchProposalServiceBatchProposalControllerReprocessKeyFn = ({ id, onlyDeclined, onlyErrors }: {
  id: string;
  onlyDeclined?: boolean;
  onlyErrors?: boolean;
}, queryKey?: Array<unknown>) => [useBatchProposalServiceBatchProposalControllerReprocessKey, ...(queryKey ?? [{ id, onlyDeclined, onlyErrors }])];
export type SecurityServiceSecurityControllerGetRequestListDefaultResponse = Awaited<ReturnType<typeof SecurityService.securityControllerGetRequestList>>;
export type SecurityServiceSecurityControllerGetRequestListQueryResult<TData = SecurityServiceSecurityControllerGetRequestListDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useSecurityServiceSecurityControllerGetRequestListKey = "SecurityServiceSecurityControllerGetRequestList";
export const UseSecurityServiceSecurityControllerGetRequestListKeyFn = ({ method, path }: {
  method?: string;
  path?: string;
} = {}, queryKey?: Array<unknown>) => [useSecurityServiceSecurityControllerGetRequestListKey, ...(queryKey ?? [{ method, path }])];
export type EmployeeServiceEmployeeControllerListEmployeesDefaultResponse = Awaited<ReturnType<typeof EmployeeService.employeeControllerListEmployees>>;
export type EmployeeServiceEmployeeControllerListEmployeesQueryResult<TData = EmployeeServiceEmployeeControllerListEmployeesDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useEmployeeServiceEmployeeControllerListEmployeesKey = "EmployeeServiceEmployeeControllerListEmployees";
export const UseEmployeeServiceEmployeeControllerListEmployeesKeyFn = ({ document, name, order, page, perPage, sort, status }: {
  document?: string;
  name?: string;
  order?: OrderEnum;
  page?: number;
  perPage?: number;
  sort?: string;
  status?: string;
} = {}, queryKey?: Array<unknown>) => [useEmployeeServiceEmployeeControllerListEmployeesKey, ...(queryKey ?? [{ document, name, order, page, perPage, sort, status }])];
export type EmployeeServiceEmployeeControllerGetEmployeeDefaultResponse = Awaited<ReturnType<typeof EmployeeService.employeeControllerGetEmployee>>;
export type EmployeeServiceEmployeeControllerGetEmployeeQueryResult<TData = EmployeeServiceEmployeeControllerGetEmployeeDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useEmployeeServiceEmployeeControllerGetEmployeeKey = "EmployeeServiceEmployeeControllerGetEmployee";
export const UseEmployeeServiceEmployeeControllerGetEmployeeKeyFn = ({ id }: {
  id: string;
}, queryKey?: Array<unknown>) => [useEmployeeServiceEmployeeControllerGetEmployeeKey, ...(queryKey ?? [{ id }])];
export type DefaultServiceFileControllerGetSignedUrlDefaultResponse = Awaited<ReturnType<typeof DefaultService.fileControllerGetSignedUrl>>;
export type DefaultServiceFileControllerGetSignedUrlQueryResult<TData = DefaultServiceFileControllerGetSignedUrlDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useDefaultServiceFileControllerGetSignedUrlKey = "DefaultServiceFileControllerGetSignedUrl";
export const UseDefaultServiceFileControllerGetSignedUrlKeyFn = ({ key }: {
  key: string;
}, queryKey?: Array<unknown>) => [useDefaultServiceFileControllerGetSignedUrlKey, ...(queryKey ?? [{ key }])];
export type DefaultServiceFileControllerDownloadFileDefaultResponse = Awaited<ReturnType<typeof DefaultService.fileControllerDownloadFile>>;
export type DefaultServiceFileControllerDownloadFileQueryResult<TData = DefaultServiceFileControllerDownloadFileDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useDefaultServiceFileControllerDownloadFileKey = "DefaultServiceFileControllerDownloadFile";
export const UseDefaultServiceFileControllerDownloadFileKeyFn = ({ key }: {
  key: string;
}, queryKey?: Array<unknown>) => [useDefaultServiceFileControllerDownloadFileKey, ...(queryKey ?? [{ key }])];
export type LoadOrderServiceOrderControllerListOrdersDefaultResponse = Awaited<ReturnType<typeof LoadOrderService.orderControllerListOrders>>;
export type LoadOrderServiceOrderControllerListOrdersQueryResult<TData = LoadOrderServiceOrderControllerListOrdersDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useLoadOrderServiceOrderControllerListOrdersKey = "LoadOrderServiceOrderControllerListOrders";
export const UseLoadOrderServiceOrderControllerListOrdersKeyFn = ({ createdAt, employerPersonId, loadOrderId, page, perPage, status }: {
  createdAt?: string;
  employerPersonId?: string;
  loadOrderId?: string;
  page?: number;
  perPage?: number;
  status?: OrderStatusEnum;
} = {}, queryKey?: Array<unknown>) => [useLoadOrderServiceOrderControllerListOrdersKey, ...(queryKey ?? [{ createdAt, employerPersonId, loadOrderId, page, perPage, status }])];
export type LoadOrderServiceOrderControllerOrderDetailsDefaultResponse = Awaited<ReturnType<typeof LoadOrderService.orderControllerOrderDetails>>;
export type LoadOrderServiceOrderControllerOrderDetailsQueryResult<TData = LoadOrderServiceOrderControllerOrderDetailsDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useLoadOrderServiceOrderControllerOrderDetailsKey = "LoadOrderServiceOrderControllerOrderDetails";
export const UseLoadOrderServiceOrderControllerOrderDetailsKeyFn = ({ id }: {
  id: string;
}, queryKey?: Array<unknown>) => [useLoadOrderServiceOrderControllerOrderDetailsKey, ...(queryKey ?? [{ id }])];
export type LoadOrderServiceOrderControllerReprocessDefaultResponse = Awaited<ReturnType<typeof LoadOrderService.orderControllerReprocess>>;
export type LoadOrderServiceOrderControllerReprocessQueryResult<TData = LoadOrderServiceOrderControllerReprocessDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useLoadOrderServiceOrderControllerReprocessKey = "LoadOrderServiceOrderControllerReprocess";
export const UseLoadOrderServiceOrderControllerReprocessKeyFn = ({ id }: {
  id: string;
}, queryKey?: Array<unknown>) => [useLoadOrderServiceOrderControllerReprocessKey, ...(queryKey ?? [{ id }])];
export type LoadOrderServiceOrderControllerGetOrderDetailsViaEmailDefaultResponse = Awaited<ReturnType<typeof LoadOrderService.orderControllerGetOrderDetailsViaEmail>>;
export type LoadOrderServiceOrderControllerGetOrderDetailsViaEmailQueryResult<TData = LoadOrderServiceOrderControllerGetOrderDetailsViaEmailDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useLoadOrderServiceOrderControllerGetOrderDetailsViaEmailKey = "LoadOrderServiceOrderControllerGetOrderDetailsViaEmail";
export const UseLoadOrderServiceOrderControllerGetOrderDetailsViaEmailKeyFn = ({ id }: {
  id: string;
}, queryKey?: Array<unknown>) => [useLoadOrderServiceOrderControllerGetOrderDetailsViaEmailKey, ...(queryKey ?? [{ id }])];
export type EmployerServiceEmployerControllerGetAccountThatWillReceivePaymentsDefaultResponse = Awaited<ReturnType<typeof EmployerService.employerControllerGetAccountThatWillReceivePayments>>;
export type EmployerServiceEmployerControllerGetAccountThatWillReceivePaymentsQueryResult<TData = EmployerServiceEmployerControllerGetAccountThatWillReceivePaymentsDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useEmployerServiceEmployerControllerGetAccountThatWillReceivePaymentsKey = "EmployerServiceEmployerControllerGetAccountThatWillReceivePayments";
export const UseEmployerServiceEmployerControllerGetAccountThatWillReceivePaymentsKeyFn = (queryKey?: Array<unknown>) => [useEmployerServiceEmployerControllerGetAccountThatWillReceivePaymentsKey, ...(queryKey ?? [])];
export type EmployerServiceEmployerControllerGetEmployerAddressesDefaultResponse = Awaited<ReturnType<typeof EmployerService.employerControllerGetEmployerAddresses>>;
export type EmployerServiceEmployerControllerGetEmployerAddressesQueryResult<TData = EmployerServiceEmployerControllerGetEmployerAddressesDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useEmployerServiceEmployerControllerGetEmployerAddressesKey = "EmployerServiceEmployerControllerGetEmployerAddresses";
export const UseEmployerServiceEmployerControllerGetEmployerAddressesKeyFn = (queryKey?: Array<unknown>) => [useEmployerServiceEmployerControllerGetEmployerAddressesKey, ...(queryKey ?? [])];
export type EmployerServiceEmployerControllerGetEmployerBasicInfoDefaultResponse = Awaited<ReturnType<typeof EmployerService.employerControllerGetEmployerBasicInfo>>;
export type EmployerServiceEmployerControllerGetEmployerBasicInfoQueryResult<TData = EmployerServiceEmployerControllerGetEmployerBasicInfoDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useEmployerServiceEmployerControllerGetEmployerBasicInfoKey = "EmployerServiceEmployerControllerGetEmployerBasicInfo";
export const UseEmployerServiceEmployerControllerGetEmployerBasicInfoKeyFn = (queryKey?: Array<unknown>) => [useEmployerServiceEmployerControllerGetEmployerBasicInfoKey, ...(queryKey ?? [])];
export type EmployerServiceEmployerControllerGetEmployerAccountBalanceDefaultResponse = Awaited<ReturnType<typeof EmployerService.employerControllerGetEmployerAccountBalance>>;
export type EmployerServiceEmployerControllerGetEmployerAccountBalanceQueryResult<TData = EmployerServiceEmployerControllerGetEmployerAccountBalanceDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useEmployerServiceEmployerControllerGetEmployerAccountBalanceKey = "EmployerServiceEmployerControllerGetEmployerAccountBalance";
export const UseEmployerServiceEmployerControllerGetEmployerAccountBalanceKeyFn = (queryKey?: Array<unknown>) => [useEmployerServiceEmployerControllerGetEmployerAccountBalanceKey, ...(queryKey ?? [])];
export type CardsServiceCardsControllerListBatchesDefaultResponse = Awaited<ReturnType<typeof CardsService.cardsControllerListBatches>>;
export type CardsServiceCardsControllerListBatchesQueryResult<TData = CardsServiceCardsControllerListBatchesDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useCardsServiceCardsControllerListBatchesKey = "CardsServiceCardsControllerListBatches";
export const UseCardsServiceCardsControllerListBatchesKeyFn = ({ order, page, perPage, quantity, sort, status }: {
  order?: OrderEnum;
  page?: number;
  perPage?: number;
  quantity?: number;
  sort?: CardBatchSortEnum;
  status?: CardBatchStatusEnum;
} = {}, queryKey?: Array<unknown>) => [useCardsServiceCardsControllerListBatchesKey, ...(queryKey ?? [{ order, page, perPage, quantity, sort, status }])];
export type CardsServiceCardsControllerGetExpirationDateDefaultResponse = Awaited<ReturnType<typeof CardsService.cardsControllerGetExpirationDate>>;
export type CardsServiceCardsControllerGetExpirationDateQueryResult<TData = CardsServiceCardsControllerGetExpirationDateDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useCardsServiceCardsControllerGetExpirationDateKey = "CardsServiceCardsControllerGetExpirationDate";
export const UseCardsServiceCardsControllerGetExpirationDateKeyFn = (queryKey?: Array<unknown>) => [useCardsServiceCardsControllerGetExpirationDateKey, ...(queryKey ?? [])];
export type CommonServiceCommonControllerGetAddressByZipCodeDefaultResponse = Awaited<ReturnType<typeof CommonService.commonControllerGetAddressByZipCode>>;
export type CommonServiceCommonControllerGetAddressByZipCodeQueryResult<TData = CommonServiceCommonControllerGetAddressByZipCodeDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useCommonServiceCommonControllerGetAddressByZipCodeKey = "CommonServiceCommonControllerGetAddressByZipCode";
export const UseCommonServiceCommonControllerGetAddressByZipCodeKeyFn = ({ zipCode }: {
  zipCode: string;
}, queryKey?: Array<unknown>) => [useCommonServiceCommonControllerGetAddressByZipCodeKey, ...(queryKey ?? [{ zipCode }])];
export type ApplicationServiceApplicationControllerGetVersionsDefaultResponse = Awaited<ReturnType<typeof ApplicationService.applicationControllerGetVersions>>;
export type ApplicationServiceApplicationControllerGetVersionsQueryResult<TData = ApplicationServiceApplicationControllerGetVersionsDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useApplicationServiceApplicationControllerGetVersionsKey = "ApplicationServiceApplicationControllerGetVersions";
export const UseApplicationServiceApplicationControllerGetVersionsKeyFn = (queryKey?: Array<unknown>) => [useApplicationServiceApplicationControllerGetVersionsKey, ...(queryKey ?? [])];
export type UserServiceUserControllerCreateMutationResult = Awaited<ReturnType<typeof UserService.userControllerCreate>>;
export type UserServiceUserControllerResendEmailMutationResult = Awaited<ReturnType<typeof UserService.userControllerResendEmail>>;
export type AuthServiceAuthControllerLoginByDocumentPasswordMutationResult = Awaited<ReturnType<typeof AuthService.authControllerLoginByDocumentPassword>>;
export type AuthServiceAuthControllerExistsMutationResult = Awaited<ReturnType<typeof AuthService.authControllerExists>>;
export type AuthServiceAuthControllerChangePasswordMutationResult = Awaited<ReturnType<typeof AuthService.authControllerChangePassword>>;
export type BatchProposalServiceBatchProposalControllerCreateBatchProposalMutationResult = Awaited<ReturnType<typeof BatchProposalService.batchProposalControllerCreateBatchProposal>>;
export type SecurityServiceSecurityControllerCheckMutationResult = Awaited<ReturnType<typeof SecurityService.securityControllerCheck>>;
export type DefaultServiceFileControllerUploadFileMutationResult = Awaited<ReturnType<typeof DefaultService.fileControllerUploadFile>>;
export type LoadOrderServiceOrderControllerCreateOrderMutationResult = Awaited<ReturnType<typeof LoadOrderService.orderControllerCreateOrder>>;
export type LoadOrderServiceOrderControllerCancelOrderMutationResult = Awaited<ReturnType<typeof LoadOrderService.orderControllerCancelOrder>>;
export type LoadOrderServiceOrderControllerSplitMutationResult = Awaited<ReturnType<typeof LoadOrderService.orderControllerSplit>>;
export type EmployerServiceEmployerControllerCreateEmployerAddressMutationResult = Awaited<ReturnType<typeof EmployerService.employerControllerCreateEmployerAddress>>;
export type CardsServiceCardsControllerCreateBatchMutationResult = Awaited<ReturnType<typeof CardsService.cardsControllerCreateBatch>>;
export type UserServiceUserControllerUpdateMutationResult = Awaited<ReturnType<typeof UserService.userControllerUpdate>>;
export type SecurityServiceSecurityControllerValidateMutationResult = Awaited<ReturnType<typeof SecurityService.securityControllerValidate>>;
export type EmployeeServiceEmployeeControllerUpdateEmployeeMutationResult = Awaited<ReturnType<typeof EmployeeService.employeeControllerUpdateEmployee>>;
export type EmployerServiceEmployerControllerUpdateEmployerAddressMutationResult = Awaited<ReturnType<typeof EmployerService.employerControllerUpdateEmployerAddress>>;
export type UserServiceUserControllerDeleteMutationResult = Awaited<ReturnType<typeof UserService.userControllerDelete>>;
