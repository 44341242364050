import { Tabs as BaseTabs } from '@vizir-banking/design-system/web';
import styled from 'styled-components';

import { LeadingContentType, PageHeader as BasePageHeader } from '~/components/page-header/page-header';

export const Tabs = styled(BaseTabs)`
  margin-top: 24px;
  height: 100%;
`;

export const PageHeader = styled(BasePageHeader).attrs({
  leadingContentType: LeadingContentType.GO_BACK,
})``;
