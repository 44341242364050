import { IconographyNames } from '@vizir-banking/design-system';
import {
  Icon as BaseIcon,
  Skeleton as BaseSkeleton,
  SkeletonVariants,
  Typography,
  TypographyVariants,
} from '@vizir-banking/design-system/web';
import styled from 'styled-components';

import { getColor } from '~/styles/theme/get-theme';

export const Container = styled.div`
  width: 100%;
  height: min-content;
  padding: 20px 24px;
  background-color: ${({ theme }) => theme.designSystem.palette.neutral.lightest};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const RightWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Icon = styled(BaseIcon).attrs({
  iconName: IconographyNames.logout,
  color: getColor('error', 'medium'),
})`
  width: 24px;
  height: 24px;
  margin-left: 12px;
  cursor: pointer;
`;

export const Name = styled(Typography).attrs({
  variant: TypographyVariants.SUBTITLE,
})``;

export const Role = styled(Typography).attrs({
  variant: TypographyVariants.CAPTION,
})``;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 12px;
  margin-right: 12px;
`;

export const Skeleton = styled(BaseSkeleton).attrs({
  variant: SkeletonVariants.custom,
})``;

export const CustomSkeleton = styled.div`
  height: 48px;
  width: 204px;
  border-radius: 8px;
`;
