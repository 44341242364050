import { Masks } from '@vizir-banking/design-system/web/components/text-field/types';
import { ReactElement } from 'react';

import { ScopeEnum } from '~/api/constants';
import { CreateUserRequestDto, ScopeEnum as ScopesType, UserResponseDto } from '~/api/requests';
import { ErrorDialog } from '~/components/dialogs/error-dialog/error-dialog';
import { SuccessDialog } from '~/components/dialogs/success-dialog/success-dialog';
import { PageHeader, TrailingContentType } from '~/components/page-header/page-header';
import { PermissionsTable } from '~/components/tables/permissions-table/permissions-table';
import { FragmentCol } from '~/styles/global-styles';
import { useTranslation } from '~/translates/use-translate';

import {
  ButtonsWrapper,
  CancelButton,
  InputWrapper,
  NewUserInput,
  OutlinedInput,
  Screen,
  SelectRoleInput,
  SelectRoleWrapper,
  SendInviteButton,
} from './permissions.styles';

type UserType = 'name' | 'document' | 'email' | 'role';

type ActionType = 'resend' | 'delete';

interface UserError {
  name?: string;
  document?: string;
  email?: string;
}

interface IPermissionsLayout {
  scopes: ScopesType[];
  addUserMode: boolean;
  user: CreateUserRequestDto;
  errors: UserError;
  data?: UserResponseDto[];
  isButtonDisabled: boolean;
  isLoading: boolean;
  onReachEndLoading: boolean;
  onCreateUserLoading: boolean;
  handleSubmit: () => void;
  handleData: (name: string, value: string) => void;
  toggleAddUser: () => void;
  onAction: (type: ActionType, value: string) => void;
  isDeleting: boolean;
  isUserDeleted: boolean;
}

export const PermissionsLayout = ({
  addUserMode,
  errors,
  isButtonDisabled,
  handleData,
  handleSubmit,
  isDeleting,
  isLoading,
  isUserDeleted,
  onAction,
  onCreateUserLoading,
  onReachEndLoading,
  toggleAddUser,
  user,
  data,
}: IPermissionsLayout): ReactElement => {
  const translate = useTranslation('pages.permissions');

  const renderTextField = (type: UserType) => {
    const isMasked = type === 'document';

    return (
      <InputWrapper>
        <OutlinedInput
          label={translate(`fields.${type}`)}
          onChange={(value) => handleData(type, value)}
          onBlur={() => handleData(type, user[type])}
          value={user[type]}
          onClearTextField={() => handleData(type, '')}
          // @ts-expect-error - dont have the role type
          error={errors[type]}
          isMasked={isMasked}
          mask={isMasked ? Masks.cpf : undefined}
        />
      </InputWrapper>
    );
  };

  const renderSelectField = () => {
    return (
      <SelectRoleWrapper>
        <SelectRoleInput
          label={translate('fields.role.label')}
          options={[
            { label: translate('fields.role.options.viewer'), value: 'viewer' },
            { label: translate('fields.role.options.operator'), value: 'operator' },
            { label: translate('fields.role.options.admin'), value: 'admin' },
          ]}
          value={{ label: translate(`fields.role.options.${user.role}`), value: translate(`${user.role}`) }}
          onChange={(role) => {
            if (role && role.value) handleData('role', role.value as string);
          }}
          key={user.role === undefined ? undefined : String(user.role)}
          placeholder={translate('fields.role.options.viewer')}
          defaultValue={{ label: translate('fields.role.options.viewer'), value: 'viewer' }}
        />
      </SelectRoleWrapper>
    );
  };

  const renderButtonForm = () => {
    return (
      <ButtonsWrapper>
        <CancelButton
          label={translate('button.cancel')}
          onClick={toggleAddUser}
          isDisabled={onCreateUserLoading}
        />

        <SendInviteButton
          label={translate('button.send')}
          onClick={handleSubmit}
          isLoading={onCreateUserLoading}
          isDisabled={isButtonDisabled}
        />
      </ButtonsWrapper>
    );
  };

  const renderAddUserForm = () => {
    return (
      <FragmentCol>
        <NewUserInput>
          {renderTextField('name')}
          {renderTextField('document')}
          {renderTextField('email')}
          {renderSelectField()}
        </NewUserInput>
        {renderButtonForm()}
      </FragmentCol>
    );
  };

  return (
    <Screen>
      <PageHeader
        title={translate('pageHeader.title')}
        verifyScope={{
          scope: ScopeEnum.CREATE_USER,
        }}
        trailingButton={{
          label: translate('pageHeader.button'),
          onClick: toggleAddUser,
        }}
        trailingContentType={addUserMode ? undefined : TrailingContentType.BUTTON}
      />

      {addUserMode && renderAddUserForm()}

      <PermissionsTable
        permissions={data}
        isDeleting={isDeleting}
        isUserDeleted={isUserDeleted}
        isLoading={isLoading}
        onReachEndLoading={onReachEndLoading}
        onRemoveAccessHook={(id: string) => onAction('delete', id)}
        onResendInvitationHook={(email: string) => onAction('resend', email)}
      />

      <ErrorDialog />
      <SuccessDialog />
    </Screen>
  );
};
