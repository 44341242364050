import {
  Button as BaseButton,
  ButtonSizes,
  ButtonTypes,
  Link as BaseLink,
  LinkVariants,
  TextField,
  Typography,
  TypographyVariants,
} from '@vizir-banking/design-system/web';
import styled from 'styled-components';

import { Screen as BaseScreen } from '~/components/screen/screen';
import { ScreenTypes } from '~/components/screen/types';

export const Screen = styled(BaseScreen).attrs({
  type: ScreenTypes.LOGIN,
})``;

export const Emphasys = styled(Typography).attrs({
  variant: TypographyVariants.HEADING_XL,
})`
  font-weight: 400;
  margin-top: 48px;
`;

export const Logo = styled.img`
  height: 40px;
  width: 140px;
  object-fit: cover;
`;

export const Highlight = styled(BaseLink).attrs({
  variant: TypographyVariants.HEADING_M,
})`
  font-weight: 700;
  margin-top: 12px;
  margin-left: 16px;
  text-decoration: none;
  color: ${({ theme }) => theme.designSystem.palette.neutral.darkest};
`;

export const Bold = styled.strong``;

export const TextInput = styled(TextField)`
  margin-top: 24px;
`;

export const Button = styled(BaseButton).attrs({
  buttonType: ButtonTypes.primary,
  buttonSize: ButtonSizes.large,
})`
  margin-top: 48px;
`;

export const Dialog = styled(Typography).attrs({
  variant: TypographyVariants.DIALOG,
})`
  display: inline;
  margin-top: 48px;
  color: ${({ theme }) => theme.designSystem.palette.neutral.darkest};
`;

export const Link = styled(BaseLink).attrs({
  linkVariant: LinkVariants.s,
})`
  display: inline;
  font-weight: 500;
`;
