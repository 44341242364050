import { ReactElement } from 'react';

import { ListBatchesSteps } from '../types';
import { useBatchDetails } from './batch-details.hook';
import { BatchDetailsLayout } from './batch-details.layout';

interface IBatchDetails {
  onChangeStep: (step: ListBatchesSteps) => void;
  batchId: string;
}

export const BatchDetails = ({ onChangeStep, batchId }: IBatchDetails): ReactElement => {
  const {
    detailedBatch,
    batchStatus,
    translateParent,
    isBatchLoading,
    isBatchStatusLoading,
    csvWithDeclinedProposals,
    tagType,
    notFound,
  } = useBatchDetails(batchId);

  return (
    <BatchDetailsLayout
      batch={detailedBatch}
      batchStatus={batchStatus}
      translateParent={translateParent}
      isBatchLoading={isBatchLoading}
      isBatchStatusLoading={isBatchStatusLoading}
      csvWithDeclinedProposals={csvWithDeclinedProposals}
      tagType={tagType}
      onChangeStep={onChangeStep}
      notFound={notFound}
    />
  );
};
