import { ReactElement } from 'react';

import { FragmentRow } from '~/styles/global-styles';

import { Container, Dots, Emphasys, SimpleText } from './dot-leader.styles';

export const DotLeader = ({
  leadingText,
  trailingText,
  hasEmphasys = false,
}: {
  leadingText: string;
  trailingText: string | number | undefined;
  hasEmphasys?: boolean;
}): ReactElement => {
  return (
    <Container>
      {hasEmphasys ? (
        <FragmentRow>
          <Emphasys>{leadingText}</Emphasys>
          <Dots />
          <Emphasys>{trailingText}</Emphasys>
        </FragmentRow>
      ) : (
        <FragmentRow>
          <SimpleText>{leadingText}</SimpleText>
          <Dots />
          <SimpleText>{trailingText}</SimpleText>
        </FragmentRow>
      )}
    </Container>
  );
};
