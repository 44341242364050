import { IconographyNames } from '@vizir-banking/design-system';
import {
  Button as BaseButton,
  ButtonSizes,
  ButtonTypes,
  Icon as BaseIcon,
  Typography,
  TypographyVariants,
} from '@vizir-banking/design-system/web';
import styled from 'styled-components';

import { Screen as BaseScreen } from '~/components/screen/screen';
import { ScreenTypes } from '~/components/screen/types';
import { getColor } from '~/styles/theme/get-theme';

export const Screen = styled(BaseScreen).attrs({
  type: ScreenTypes.LOGGED,
})``;

export const Container = styled.div`
  width: 100%;
  gap: 32px;
  display: flex;
  flex-wrap: wrap;
  margin: 24px auto;
  justify-content: space-between;
`;

export const BoxTitle = styled(Typography).attrs({
  variant: TypographyVariants.HEADING_M,
})``;

export const Quantity = styled(Typography).attrs({
  variant: TypographyVariants.HEADING_XXXL,
})``;

export const HalfBox = styled.div`
  width: calc(50% - 24px);
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 24px;
  border-radius: 8px;
  border: 1px solid ${getColor('neutral', 'medium')};
  justify-content: space-between;
`;

export const FullBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 24px;
  border-radius: 8px;
  border: 1px solid ${getColor('neutral', 'medium')};
`;

export const Paragraph = styled(Typography).attrs({
  variant: TypographyVariants.PARAGRAPH_XS,
})`
  color: ${({ theme }) => theme.designSystem.palette.primary.regular};
  font-weight: 700;
`;

export const PrimaryButton = styled(BaseButton).attrs({
  buttonType: ButtonTypes.primary,
  buttonSize: ButtonSizes.medium,
  leadingIconColor: '#000',
  leadingIconName: IconographyNames.add,
  withLeadingIcon: true,
})``;

export const Dialog = styled(Typography).attrs({
  variant: TypographyVariants.DIALOG,
})`
  color: ${getColor('neutral', 'darkest')};
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 14px;
  border-radius: 50%;
  background-color: ${getColor('neutral', 'lighter')};
`;

const Icon = styled(BaseIcon).attrs(({ theme }) => ({
  color: theme.designSystem.palette.neutral.darkest,
}))`
  width: 24px;
  height: 24px;
`;

export const UserIcon = styled(Icon).attrs({
  iconName: IconographyNames.user,
})``;

export const CoinIcon = styled(Icon).attrs({
  iconName: IconographyNames.coin,
})``;

export const DownloadIcon = styled(Icon).attrs({
  iconName: IconographyNames.download,
})``;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
