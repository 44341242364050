import { GlobalVariants } from '@vizir-banking/design-system';
import {
  PinInputGlobalVariants,
  SelectFieldGlobalVariants,
  TextFieldGlobalVariants,
} from '@vizir-banking/design-system/web';

export const designSystemGlobalVariants: GlobalVariants = {
  web: {
    textField: TextFieldGlobalVariants.OUTLINED,
    pinInput: PinInputGlobalVariants.OUTLINED,
    selectField: SelectFieldGlobalVariants.OUTLINED,
  },
};
