import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { UserMeResponseDto } from '~/api/requests';

import { BenefitsState } from '../types';

type UserState = {
  currentUser?: UserMeResponseDto;
  token?: string;
};

const initialState: UserState = {};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    setCurrentUser: (state, action: PayloadAction<UserMeResponseDto>) => {
      state.currentUser = action.payload;
    },
    clearCurrentUser: (state) => {
      state.currentUser = undefined;
      state.token = undefined;
    },
  },
});

export const { setToken, setCurrentUser, clearCurrentUser } = userSlice.actions;

export const selectToken = (state: BenefitsState) => state.user.token;
export const selectCurrentUser = (state: BenefitsState) => state.user.currentUser;

export default userSlice.reducer;
