export enum SideBarTabs {
  HOME = 'home',
  COLLABORATORS = 'listCollaborators',
  LIST_BATCHES = 'listBatches',
  RECHARGE = 'recharge',
  PERMISSIONS = 'permissions',
  CARDS = 'cards',
  BUSINESS_ADDRESS = 'address',
}

export enum SideBarDropdown {
  COLLABORATORS = 'collaborators',
  SETTINGS = 'settings',
}
