import { useState } from 'react';

import { RechargeSteps } from '../types';
import { PaymentOptions } from './types';

interface IPaymentType {
  paymentType?: PaymentOptions;
  onChangePaymentType: (value: PaymentOptions) => void;
  onContinue: () => void;
  onGoBack: () => void;
}

interface IRechargePayment {
  onChangeStep: (step: RechargeSteps) => void;
}

export const useRechargePaymentType = ({ onChangeStep }: IRechargePayment): IPaymentType => {
  const [paymentType, setPaymentType] = useState<PaymentOptions>();

  const onChangePaymentType = (value: PaymentOptions) => {
    setPaymentType(value);
  };

  const onContinue = () => onChangeStep(RechargeSteps.PAYMENT);
  const onGoBack = () => onChangeStep(RechargeSteps.RECHARGE_DETAILS);

  return {
    paymentType,
    onChangePaymentType,
    onContinue,
    onGoBack,
  };
};
