import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { BenefitsState } from '../types';

type ApplicationState = {
  error: string;
  success: string;
};

const initialState: ApplicationState = {
  error: '',
  success: '',
};

const applicationSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {
    setError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    clearError: (state) => {
      state.error = '';
    },
    setSuccess: (state, action: PayloadAction<string>) => {
      state.success = action.payload;
    },
    clearSuccess: (state) => {
      state.success = '';
    },
  },
});

export const { setError, clearError, setSuccess, clearSuccess } = applicationSlice.actions;

export const error = (state: BenefitsState) => state.application.error;
export const success = (state: BenefitsState) => state.application.success;

export default applicationSlice.reducer;
