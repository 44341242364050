/* eslint-disable @typescript-eslint/no-explicit-any */
import { IconographyNames } from '@vizir-banking/design-system';
import { Accordion, TableBody, TableHead } from '@vizir-banking/design-system/web';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';

import { useOnReachEnd } from '~/hooks/use-reach-end';
import { designSystemTheme } from '~/styles/theme/design-system-theme';
import { useTranslation } from '~/translates/use-translate';
import { autoSingleStyle } from '~/utils/auto-single-style';
import { currencyFormatter } from '~/utils/format-currency';

import { RechargeSteps } from '../../types';
import {
  ButtonWrapper,
  ErrorDescription,
  ErrorLine,
  ErrorsContainer,
  ErrorWrapper,
  PageWrapper,
  PrimaryButton,
  SecondaryButton,
  TableBodyCell,
  TableBodyErrorCell,
  TableBodyRow,
  TableContainer,
  TableHeadCell,
  TableHeadRow,
  TableWrapper,
} from './review.styles';

interface IRechargeReview {
  validateData: (columnIndex: number, rowIndex: number) => boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any[][];
  fileErrors: { line: number; column: string; message: string }[];
  onChangeTab: (tab: number) => void;
  onSetStepHasError: (hasError: boolean) => void;
  onChangeNewRechargeStep: (step: RechargeSteps) => void;
}

const PAGE_SIZE = 10;

export const RechargeReviewLayout = ({
  data,
  fileErrors,
  onChangeTab,
  validateData,
  onSetStepHasError,
  onChangeNewRechargeStep,
}: IRechargeReview) => {
  const translate = useTranslation('pages.recharge.newRecharge.tabs.review');
  const [currentPage, setCurrentPage] = useState(1);
  const [currentData, setCurrentData] = useState<any[][]>(data.slice(0, PAGE_SIZE));

  useOnReachEnd(() => {
    if (currentPage * PAGE_SIZE > data.length) return;
    setCurrentPage((prev) => prev + 1);
  });

  useEffect(() => {
    if (data) {
      const newData = data.slice((currentPage - 1) * PAGE_SIZE, currentPage * PAGE_SIZE);
      setCurrentData((prev) => [...prev, ...newData]);
    }
  }, [currentPage, data]);

  const renderTableHead = () => {
    if (data.length === 0) return null;
    return (
      <TableHeadRow>
        <TableHeadCell>{translate('errors.line')}</TableHeadCell>
        {data[0].map((header, index) => (
          <TableHeadCell key={index}>{header}</TableHeadCell>
        ))}
      </TableHeadRow>
    );
  };

  const renderTableBodyRows = () => {
    if (isEmpty(data)) return null;

    const formatBalance = (value: string) => {
      if (value[value.length - 3] == '.') return currencyFormatter.format(Number(value));
      return value;
    };

    return currentData.slice(1).map(
      (row, index) =>
        row.length !== 1 && (
          <TableBodyRow key={index}>
            <TableHeadCell key={index}>{index + 1}</TableHeadCell>
            {row.map((cell, cellIndex) =>
              validateData(cellIndex, index) ? (
                <TableBodyCell key={cellIndex}>{formatBalance(cell)}</TableBodyCell>
              ) : (
                <TableBodyErrorCell key={cellIndex}>{formatBalance(cell)}</TableBodyErrorCell>
              )
            )}
          </TableBodyRow>
        )
    );
  };

  const renderTable = () => {
    return (
      <TableWrapper>
        <TableContainer>
          <TableHead>{renderTableHead()}</TableHead>
          <TableBody>{renderTableBodyRows()}</TableBody>
        </TableContainer>
      </TableWrapper>
    );
  };

  const renderButtons = () => {
    return (
      <ButtonWrapper>
        <SecondaryButton
          label={translate('buttons.cancel')}
          onClick={() => {
            onChangeNewRechargeStep(RechargeSteps.BALANCE);
            onSetStepHasError(false);
          }}
        />
        <PrimaryButton
          label={translate('buttons.resend')}
          onClick={() => {
            onChangeTab(0);
            onSetStepHasError(false);
          }}
        />
      </ButtonWrapper>
    );
  };

  const renderErrors = () => {
    return (
      <ErrorsContainer>
        {fileErrors.map((err, index) => {
          return (
            <ErrorWrapper key={index}>
              {autoSingleStyle(
                `*${translate('errors.line')} ${err.line}:* ${err.column} -> ` +
                  translate(`errors.${err.message}`),
                '*',
                ErrorDescription,
                ErrorLine
              )}
            </ErrorWrapper>
          );
        })}
      </ErrorsContainer>
    );
  };

  const renderAccordionErrors = () => {
    if (fileErrors.length === 0) {
      onSetStepHasError(false);

      return null;
    }

    onSetStepHasError(true);
    return (
      <Accordion
        containerProps={{
          style: {
            width: '100%',
            padding: '0',
            zIndex: 0,
            flexDirection: 'column',
          },
        }}
        headerContainerProps={{
          style: {
            borderBottom: `1px solid ${designSystemTheme.designSystem.palette.error.medium}`,
            width: '100%',
          },
        }}
        accordionContentProps={{
          accordionHeaderProps: {
            iconColor: designSystemTheme.designSystem.palette.neutral.darkest,
            summaryLabel: `${fileErrors.length} ${translate('errors.errorsFound')}`,
            summaryProps: {
              // @ts-expect-error - The type of summaryProps is not defined
              style: {
                fontSize: 14,
                fontWeight: 400,
                color: designSystemTheme.designSystem.palette.neutral.darkest,
              },
            },
            hasLeadingIcon: true,
            leadingIconName: IconographyNames.errorCircle,
            summaryContainerProps: {
              style: {
                padding: '4px 0',
                justifyContent: 'flex-start',
              },
            },
            containerProps: {
              style: {
                width: '100%',
                padding: '0',
                borderTop: `1px solid ${designSystemTheme.designSystem.palette.error.medium}`,
                borderLeft: `1px solid ${designSystemTheme.designSystem.palette.error.medium}`,
                borderRight: `1px solid ${designSystemTheme.designSystem.palette.error.medium}`,
                borderTopLeftRadius: 4,
                borderTopRightRadius: 4,
              },
            },
            // customBorderColor: designSystemTheme.designSystem.palette.error.medium,
            // @ts-expect-error - The type of customBackgroundColor is not defined
            customBackgroundColor: () => {
              const color = designSystemTheme.designSystem.palette.error.medium;
              return `rgba(${parseInt(color.slice(1, 3), 16)}, ${parseInt(color.slice(3, 5), 16)}, ${parseInt(
                color.slice(5, 7),
                16
              )}, 0.30)`;
            },
            iconContainer: {
              style: {
                padding: '0 16px',
                color: designSystemTheme.designSystem.palette.neutral.darkest,
              },
            },
          },

          accordionDetailsProps: [
            {
              customBorderColor: designSystemTheme.designSystem.palette.error.medium,
              // @ts-expect-error - The type of customBackgroundColor is not defined
              customBackgroundColor: () => {
                const color = designSystemTheme.designSystem.palette.error.medium;
                return `rgba(${parseInt(color.slice(1, 3), 16)}, ${parseInt(
                  color.slice(3, 5),
                  16
                )}, ${parseInt(color.slice(5, 7), 16)}, 0.30)`;
              },
              containerProps: {
                style: {
                  width: '100%',
                  padding: '0',
                  flexDirection: 'column',
                  borderLeft: `1px solid ${designSystemTheme.designSystem.palette.error.medium}`,
                  borderRight: `1px solid ${designSystemTheme.designSystem.palette.error.medium}`,
                  borderRadius: 4,
                },
              },
              renderContent: () => renderErrors(),
            },
          ],
        }}
        detailsPositionalContainer={{
          style: {
            width: '100%',
            flexDirection: 'column',
            zIndex: 1,
          },
        }}
      />
    );
  };

  return (
    <PageWrapper>
      {renderAccordionErrors()}
      {renderTable()}
      {renderButtons()}
    </PageWrapper>
  );
};
