import Papa from 'papaparse';
import { useEffect, useState } from 'react';
import * as XLSX from 'xlsx';

import { identifyDelimiter } from '~/utils/identify-delimiter';

import { CreateOrderErrorMessageEnum } from '../send/send.hook';

interface IRechargeReview {
  file: File | null;
  fileErrors: { line: number; column: string; message: CreateOrderErrorMessageEnum }[];
}

interface IRechargeReviewReturn {
  validateData: (columnIndex: number, rowIndex: number) => boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any[][];
}

enum OrderColumns {
  CPF = 'CPF',
  FOOD_MEAL = 'refeição/alimentação',
  FOOD = 'alimentação',
  MEAL = 'refeição',
  CULTURE = 'cultura',
  FUEL = 'combustível',
  TRANSPORT = 'transporte',
  MOBILITY = 'mobilidade',
  TRAVEL = 'viagens',
  ENTERTAINMENT = 'lazer',
  EDUCATION = 'educação',
  HEALTH = 'saúde',
  HOME_OFFICE = 'home_office',
  GENERAL = 'geral',
}

export const useRechargeReview = ({ file, fileErrors }: IRechargeReview): IRechargeReviewReturn => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [data, setData] = useState<any[][]>([]);

  useEffect(() => {
    if (file) {
      const readFile = async () => {
        const fileExtension = file.name.split('.').pop()?.toLowerCase();
        const fileText = await file.text();
        if (fileExtension === 'xlsx') {
          readXLSX();
        } else if (fileExtension === 'csv' || fileExtension === 'tsv') {
          readCSVOrTSV(file, fileExtension === 'csv' ? identifyDelimiter(fileText) : '\t');
        }
      };
      readFile();
    }
  }, [file]);

  const readXLSX = () => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const binaryStr = event.target?.result;
      const workbook = XLSX.read(binaryStr, { type: 'binary' });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
      // @ts-expect-error - The type of jsonData is any[][]
      setData(jsonData);
    };
  };

  const readCSVOrTSV = (file: File, delimiter: string) => {
    Papa.parse(file, {
      delimiter,
      complete: (results) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        setData(results.data as any[][]);
      },
    });
  };

  const getColumnName = (index: number): string => {
    const columnMap: { [key: number]: OrderColumns } = {
      0: OrderColumns.CPF,
      1: OrderColumns.FOOD_MEAL,
      2: OrderColumns.FOOD,
      3: OrderColumns.MEAL,
      4: OrderColumns.CULTURE,
      5: OrderColumns.FUEL,
      6: OrderColumns.TRANSPORT,
      7: OrderColumns.MOBILITY,
      8: OrderColumns.TRAVEL,
      9: OrderColumns.ENTERTAINMENT,
      10: OrderColumns.EDUCATION,
      11: OrderColumns.HEALTH,
      12: OrderColumns.HOME_OFFICE,
      13: OrderColumns.GENERAL,
    };
    return columnMap[index];
  };

  const validateData = (columnIndex: number, rowIndex: number): boolean => {
    let isValid = true;
    fileErrors.map((error) => {
      if (error.column === getColumnName(columnIndex) && error.line === rowIndex + 1) {
        isValid = false;
        return;
      }
    });

    return isValid;
  };

  return { validateData, data };
};
