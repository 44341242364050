import { Divider } from '@vizir-banking/design-system/web';
import { ReactElement } from 'react';

import { ScopeEnum } from '~/api/constants';
import { Order, UserMeResponseDto } from '~/api/requests';
import { Collaborators, Recharge } from '~/assets/sheets-mapping';
import { ErrorDialog } from '~/components//dialogs/error-dialog/error-dialog';
import { LeadingContentType, PageHeader, TrailingContentType } from '~/components/page-header/page-header';
import { RechargeTable } from '~/components/tables/recharge-table/recharge-table';
import { useTranslation } from '~/translates/use-translate';

import {
  BoxTitle,
  CoinIcon,
  Container,
  Dialog,
  DownloadIcon,
  FullBox,
  HalfBox,
  IconWrapper,
  InfoWrapper,
  Row,
  Screen,
  UserIcon,
} from './home.styles';

interface IHome {
  user?: UserMeResponseDto;
  orders: Order[];
  isLoading: boolean;
  shouldCreateCollaborator: boolean;
  goToLogin: () => void;
  goToCollaborators: () => void;
  goToRecharge: () => void;
}

export const HomeLayout = ({ goToRecharge, orders, isLoading, goToLogin }: IHome): ReactElement => {
  const translate = useTranslation('pages.home');

  const secondaryButtonProps = {
    label: translate('close'),
    onClick: goToLogin,
  };

  // TDOD show total collaborators
  // const renderCollaboratorsContentBox = () => {
  //   return (
  //     <>
  //       <BoxTitle>{translate(`collaborators.title`)}</BoxTitle>
  //       <Quantity>250</Quantity>
  //       <PrimaryButton
  //         label={translate(`collaborators.add`)}
  //         onClick={goToCollaborators}
  //         isDisabled={!shouldCreateCollaborator}
  //       />
  //     </>
  //   );
  // };

  const renderSheetContentBox = () => {
    const renderItem = (type: string) => {
      const Icon = type === 'collaborators' ? UserIcon : CoinIcon;
      const Sheet = type === 'collaborators' ? Collaborators : Recharge;
      const SheetName =
        type === 'collaborators' ? 'planilha_de_colaboradores.xlsx' : 'planilha_de_pedidos.xlsx';

      return (
        <Row>
          <InfoWrapper>
            <IconWrapper>
              <Icon />
            </IconWrapper>
            <Dialog>{translate(`sheets.${type}`)}</Dialog>
          </InfoWrapper>
          <a
            href={Sheet}
            download={SheetName}
            style={{
              textDecoration: 'none',
              color: 'inherit',
            }}
          >
            <DownloadIcon />
          </a>
        </Row>
      );
    };

    return (
      <HalfBox>
        <BoxTitle>{translate(`sheets.title`)}</BoxTitle>
        {renderItem('collaborators')}
        <Divider />
        {renderItem('orders')}
      </HalfBox>
    );
  };

  const renderRechargeHistory = () => {
    return (
      <FullBox>
        <PageHeader
          leadingContentType={LeadingContentType.TEXT}
          trailingContentType={TrailingContentType.BUTTON}
          title={translate('rechargeHistory.title')}
          trailingButton={{
            label: translate(`rechargeHistory.add`),
            onClick: goToRecharge,
          }}
          verifyScope={{
            scope: ScopeEnum.CREATE_ORDER,
          }}
        />
        <RechargeTable recharges={orders} isOnEndLoading={false} isLoading={isLoading} />
      </FullBox>
    );
  };

  return (
    <Screen>
      <PageHeader
        leadingContentType={LeadingContentType.TEXT}
        trailingContentType={TrailingContentType.BUTTON}
        leadingText={translate('pageHeader.currentStep')}
        title={translate('pageHeader.subtitle')}
      />

      <Container>
        {/* <HalfBox>{renderCollaboratorsContentBox()}</HalfBox> */}
        {renderSheetContentBox()}
        {renderRechargeHistory()}
      </Container>
      <ErrorDialog secondaryButtonProps={secondaryButtonProps} />
    </Screen>
  );
};
