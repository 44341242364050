import { Typography, TypographyVariants } from '@vizir-banking/design-system/web';
import styled from 'styled-components';

import { getColor } from '~/styles/theme/get-theme';

export const Container = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

export const Dots = styled.div`
  content: '';
  flex: 1;
  border-bottom: 1px dotted ${getColor('neutral', 'darker')};
  margin: 0 16px;
`;

export const SimpleText = styled(Typography).attrs({
  variant: TypographyVariants.PARAGRAPH_M,
})`
  color: ${getColor('neutral', 'darker')};
`;

export const Emphasys = styled(Typography).attrs({
  variant: TypographyVariants.HEADING_L,
})`
  color: ${getColor('neutral', 'darkest')};
`;
