import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { useCardsServiceCardsControllerCreateBatch } from '~/api/queries';
import { AddressDto, ApiError } from '~/api/requests';
import { setError, setSuccess } from '~/redux/reducers/application';
import { SuccessEnum } from '~/translates/success/types';

interface CardState {
  quantity: string;
  date: string;
}

interface INewCardsReview {
  card: CardState;
  address: AddressDto;
}

interface INewCardsReviewHook {
  card: CardState;
  address: AddressDto;
  isLoading: boolean;
  onSubmit: () => Promise<void>;
}

export const useNewCardsReviewHook = ({ address, card }: INewCardsReview): INewCardsReviewHook => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const requestCard = useCardsServiceCardsControllerCreateBatch();

  const onSuccess = () => {
    setIsLoading(false);
    dispatch(setSuccess(SuccessEnum.CARD_REQUEST));
  };

  const onError = (err: unknown) => {
    const error = err as ApiError;
    setIsLoading(false);
    dispatch(setError(error.body.code));
  };

  const onSubmit = async () => {
    setIsLoading(true);
    requestCard.mutate(
      {
        requestBody: {
          employerAddressId: String(address?.id),
          quantity: Number(card.quantity),
          expirationDate: card.date,
        },
      },
      { onSuccess, onError }
    );
  };

  return {
    card,
    address,
    isLoading,
    onSubmit,
  };
};
