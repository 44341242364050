import {
  Skeleton as BaseSkeleton,
  SkeletonVariants,
  Table as BaseTable,
  TableCell as BaseTableCell,
  TableHeadCell as BaseTableHeadCell,
  TableRow as BaseTableRow,
} from '@vizir-banking/design-system/web';
import styled from 'styled-components';

import { getColor } from '~/styles/theme/get-theme';

export const TableContainer = styled(BaseTable)`
  overflow-y: visible;
  height: auto;
`;

export const TableHeadRow = styled(BaseTableRow)`
  background-color: ${getColor('neutral', 'lighter')};
`;

export const TableBodyRow = styled(BaseTableRow)``;

export const TableHeadCell = styled(BaseTableHeadCell)`
  border: 1px solid ${getColor('neutral', 'light')};
  text-align: start;
`;

export const TableBodyCell = styled(BaseTableCell)`
  border: 1px solid ${getColor('neutral', 'lighter')};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Skeleton = styled(BaseSkeleton).attrs({
  variant: SkeletonVariants.tag,
})`
  height: 32px;
  width: 120px;
`;
