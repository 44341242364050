import { BannerTypes } from '@vizir-banking/design-system/web';
import { ReactElement, ReactNode } from 'react';

import { FragmentRow } from '~/styles/global-styles';

import {
  Banner,
  Container,
  FeedbackBar,
  FullBanner,
  FullBannerContent,
  InfoCircle,
  Line,
  Skeleton,
  Subtitle,
} from './feedback-banner.styles';

export enum FeedbackBannerEnum {
  SIMPLE = 'simple',
  FULL = 'full',
}

interface IFeedbackBanner {
  type?: BannerTypes;
  feedbackType?: FeedbackBannerEnum;
  title?: string;
  isSkeleton?: boolean;
  children?: ReactNode;
}

export const FeedbackBanner = ({
  type,
  feedbackType = FeedbackBannerEnum.SIMPLE,
  title,
  isSkeleton = false,
  children,
}: IFeedbackBanner): ReactElement => {
  const renderSimpleBanner = () => <Banner type={type ?? BannerTypes.info} title={title} />;

  const renderFullBanner = () => (
    <FragmentRow>
      <FullBanner>
        <Line />
        <FullBannerContent>
          <InfoCircle />
          <Subtitle>{children}</Subtitle>
        </FullBannerContent>
      </FullBanner>
    </FragmentRow>
  );

  const renderFeedbackType = () => {
    const feedback: { [key: string]: ReactElement } = {
      [FeedbackBannerEnum.SIMPLE]: renderSimpleBanner(),
      [FeedbackBannerEnum.FULL]: renderFullBanner(),
    };

    return feedback[feedbackType];
  };

  const renderSkeleton = () => (
    <Skeleton>
      <FeedbackBar />
    </Skeleton>
  );

  const renderContent = () => {
    return isSkeleton ? renderSkeleton() : renderFeedbackType();
  };

  return <Container>{renderContent()}</Container>;
};
