import { AccordionOverrides } from '@vizir-banking/design-system/web/components/accordion/types';

export const accordionOverrides: AccordionOverrides = {
  accordion: {
    details: {
      container: {
        width: '100%',
        flexDirection: 'column',
      },
      positionalContainer: {
        width: '100%',
      },
    },
  },
};
