import * as web from '@vizir-banking/design-system/web';
import styled from 'styled-components';

import { OnboardingStatusEnum } from '~/api/requests';

export const Container = styled.div`
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const TableContainer = styled(web.Table)`
  width: 100%;
  border-radius: 8px;
`;

export const TableHead = styled(web.TableHead)`
  width: 100%;
`;

export const BaseTableHeadCell = styled(web.TableHeadCell)`
  background-color: ${({ theme }) => theme.designSystem.palette.neutral.lighter};
  border-color: ${({ theme }) => theme.designSystem.palette.neutral.light};
  border: 1px solid ${({ theme }) => theme.designSystem.palette.neutral.light};
  text-align: left;
`;

export const TableBody = styled(web.TableBody)`
  width: 100%;
`;

export const TableRow = styled(web.TableRow)`
  width: 100%;
`;

export const BaseTableCell = styled(web.TableCell)<{ hasError?: boolean }>`
  text-align: left;
  border: 1px solid ${({ theme }) => theme.designSystem.palette.neutral.light};
  background-color: ${({ hasError, theme }) =>
    hasError ? theme.designSystem.palette.neutral.medium : 'inherit'};
  color: ${({ hasError, theme }) => (hasError ? theme.designSystem.palette.error.dark : 'inherit')};
`;

export const TableStatusCell = styled(web.TableTagCell)<{ currentStatus?: OnboardingStatusEnum }>`
  text-align: left;
  border: 1px solid ${({ theme }) => theme.designSystem.palette.neutral.light};

  & > div {
    & > div {
      & > div {
        background-color: ${({ currentStatus, theme }) => {
          switch (currentStatus) {
            case 'approved':
              return theme.designSystem.palette.success.light;
            case 'declined':
              return theme.designSystem.palette.error.light;
            case 'processing':
              return theme.designSystem.palette.warning.light;
            case 'not_processed':
              return theme.designSystem.palette.warning.light;
            default:
              return theme.designSystem.palette.neutral.medium;
          }
        }};
      }
    }
  }
`;

export const StatusSkeleton = styled(web.Skeleton).attrs({
  variant: web.SkeletonVariants.tag,
})`
  height: 32px;
  width: 70px;
`;

export const Skeleton = styled(web.Skeleton).attrs({
  variant: web.SkeletonVariants.custom,
})``;

export const SkeletonDiv = styled.div`
  height: 40px;
  width: 150px;
`;
