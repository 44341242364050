import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { ScopeEnum } from '~/api/constants';
import { OpenAPI, SimplifiedEmployees } from '~/api/requests';
import { useOnReachEnd } from '~/hooks/use-reach-end';
import { useUserScopes } from '~/hooks/use-user-scopes';
import { selectToken } from '~/redux/reducers/user';
import { useTranslation } from '~/translates/use-translate';

import { CollaboratorsSteps, ICollaboratorHookProps } from '../types';

interface ICollaboratorHook {
  document: string;
  error: string;
  isLoading: boolean;
  onReachEndLoading: boolean;
  collaborators?: SimplifiedEmployees[];
  shouldViewCollaboratorProfile: boolean;
  onSelectCollaborator: (id: string) => void;
  onChangeDocument: (value: string) => void;
}

export const useCollaboratorsViewHook = ({
  onChangeStep,
  allCollaborators,
  updateCollaborators,
  document,
  setDocument,
  documentError,
  setDocumentError,
  enabled,
  setEnabled,
  page,
  updatePage,
  USERS_PER_PAGE,
  getCollaborators,
}: ICollaboratorHookProps): ICollaboratorHook => {
  const token = useSelector(selectToken);
  const { hasScope } = useUserScopes();
  const translate = useTranslation('pages.collaborators.view');

  useOnReachEnd(() => {
    if (!onReachEndLoading && !getCollaborators.isPending && (allCollaborators ?? []).length > 0) {
      setOnReachEndLoading(true);
      updatePage();
    }
  });

  const [onReachEndLoading, setOnReachEndLoading] = useState(false);

  useEffect(() => {
    OpenAPI.HEADERS = {
      ...OpenAPI.HEADERS,
      authorization: `Bearer ${token}`,
    };
  }, [token]);

  const onSelectCollaborator = (id: string) => {
    const collaboratorIndex = allCollaborators.findIndex((collaborator) => collaborator.personId === id);
    const collaboratorPage = Math.floor(collaboratorIndex / USERS_PER_PAGE) + 1;

    window.scrollTo({ top: 0, behavior: 'smooth' });
    onChangeStep(CollaboratorsSteps.PROFILE, id, { index: collaboratorIndex, page: collaboratorPage });
  };

  const onChangeDocument = useCallback((value: string) => {
    setDocumentError('');
    setDocument(value);
  }, []);

  useEffect(() => {
    if (getCollaborators.data) {
      const newCollaborators = getCollaborators.data.data;

      if (updateCollaborators) updateCollaborators(newCollaborators, page);

      setOnReachEndLoading(false);

      if (newCollaborators.length === 0 && document.length === 14)
        setDocumentError(translate('error.notFound'));

      if (newCollaborators.length < USERS_PER_PAGE) setEnabled(false);
    }
  }, [getCollaborators.data, page]);

  useEffect(() => {
    const { isPending } = getCollaborators;

    setOnReachEndLoading(isPending && page > 1 && enabled);
  }, [getCollaborators.isPending, page]);

  return {
    document,
    shouldViewCollaboratorProfile: hasScope(ScopeEnum.VIEW_PROFILE_EMPLOYEE),
    error: documentError,
    isLoading: (getCollaborators.isPending || getCollaborators.isFetching) && page === 1 && enabled,
    onReachEndLoading,
    collaborators: allCollaborators,
    onSelectCollaborator,
    onChangeDocument,
  };
};
