import { ReactElement } from 'react';

import { LogoWithName } from '~/assets/images-mapping';
import { ErrorDialog } from '~/components/dialogs/error-dialog/error-dialog';
import { useTranslation } from '~/translates/use-translate';

import { LoginProps } from './login.hook';
import { Bold, Button, Dialog, Emphasys, Highlight, Link, Logo, Screen, TextInput } from './login.styles';

export const LoginLayout = ({
  user,
  handleUser,
  isEmailError,
  isPasswordError,
  isLoading,
  goToForgotPassword,
  goToRequestAccess,
  onLogin,
}: LoginProps): ReactElement => {
  const translate = useTranslation('pages.login');

  return (
    <Screen>
      <Logo src={LogoWithName} />
      <Emphasys>
        {translate('greeting')}
        <Bold>{translate('name')}</Bold>
      </Emphasys>

      <TextInput
        label={translate('email')}
        value={user.document}
        onChange={(value: string) => handleUser('document', value)}
        onClearTextField={() => handleUser('document', '')}
        error={isEmailError ? translate('invalidEmail') : ''}
      />
      <TextInput
        isPassword={true}
        label={translate('password')}
        value={user.password}
        onChange={(value: string) => handleUser('password', value)}
        onClearTextField={() => handleUser('password', '')}
      />

      <Highlight onClick={goToForgotPassword}>{translate('forgotPassword')}</Highlight>

      <Button
        label={translate('button')}
        onClick={onLogin}
        isDisabled={isEmailError || isPasswordError}
        isLoading={isLoading}
      />

      <Dialog>
        {translate('withoutAccount')}
        <Link onClick={goToRequestAccess}>{translate('createPassword')}</Link>
      </Dialog>

      <ErrorDialog />
    </Screen>
  );
};
