export const identifyDelimiter = (csv: string): string => {
  const possibleDelimiters = [',', ';'];
  return possibleDelimiters.filter((delimiter) => {
    let cache = -1;
    return csv.split(/\r\n|\r|\n/).every((row) => {
      if (!row) {
        return true;
      }
      const length = row.split(delimiter).length;
      if (cache === -1) {
        cache = length;
      }
      return cache === length && length > 1;
    });
  })[0];
};
