import { useTranslation } from '~/translates/use-translate';

import { Container, Icon, Label, Wrapper } from './password-tick.styles';
import { PasswordStatus } from './types';

interface IPasswordTick {
  status: { [key: string]: PasswordStatus };
}

export const PasswordTick = ({ status }: IPasswordTick) => {
  const translate = useTranslation('pages.createPassword.requests');

  const getStatus = (key: string): PasswordStatus => {
    return status[key] ?? PasswordStatus.DEFAULT;
  };

  const isCorrect = (): boolean => {
    return Object.values(status).every((value) => value === PasswordStatus.CORRECT);
  };

  const renderRule = (key: string) => {
    return (
      <Wrapper>
        <Icon status={getStatus(key)} />
        <Label status={getStatus(key)}>{translate(key)}</Label>
      </Wrapper>
    );
  };

  return (
    <Container isCorrect={isCorrect()}>
      {renderRule('uppercase')}
      {renderRule('lowercase')}
      {renderRule('number')}
      {renderRule('specialChar')}
      {renderRule('minChar')}
    </Container>
  );
};
