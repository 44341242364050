export enum SuccessEnum {
  EMAIL_SENT = 'email_sent',
  PASSWORD_CREATED = 'password_created',
  ACCESS_REMOVED = 'access_removed',
  ACCESS_CREATED = 'access_created',
  RESEND_INVITE = 'resend_invite',
  BATCH_PROPOSAL_UPLOADED = 'batch_proposal_uploaded',
  COLLABORATOR_UPDATED = 'collaborator_updated',
  CARD_REQUEST = 'card_request',
  UPDATED_ENTERPRISE_ADDRESS = 'updated_enterprise_address',
  CREATED_ENTERPRISE_ADDRESS = 'created_enterprise_address',
  DELETED_ENTERPRISE_ADDRESS = 'deleted_enterprise_address',
  SPLIT_ORDER = 'split_order',
}
