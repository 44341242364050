import { ErrorsEnum as ErrosEnumApi } from '~/api/constants';

export enum ErrorsEnumFront {
  INVALID_FILE = 'invalid_file',
  SPLIT_ORDER = 'split_order',
  CANCELED_REQUEST = 'canceled_request',
}

export const ErrorsEnum = {
  ...ErrosEnumApi,
  ...ErrorsEnumFront,
};
