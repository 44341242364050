import { ReactElement } from 'react';

import { useHome } from './home.hook';
import { HomeLayout } from './home.layout';

export const HomePage = (): ReactElement => {
  const props = useHome();

  return <HomeLayout {...props} />;
};
