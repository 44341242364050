import { useSelector } from 'react-redux';

import { RoleEnum, ScopeEnum } from '~/api/requests';
import { selectCurrentUser } from '~/redux/reducers/user';

interface IUserScopes {
  scopes: ScopeEnum[];
  hasScope: (scope: ScopeEnum | ScopeEnum[]) => boolean;
  isRole: (role: RoleEnum) => boolean;
}

export const useUserScopes = (): IUserScopes => {
  const user = useSelector(selectCurrentUser);
  const scopes = user?.scopes ?? [];
  const userRole = user?.role;

  const hasScope = (scope: ScopeEnum | ScopeEnum[]) => {
    if (Array.isArray(scope)) {
      return scope.every((s) => scopes?.includes(s));
    }
    return scopes?.includes(scope) ?? false;
  };

  const isRole = (role: RoleEnum) => {
    return role === userRole;
  };

  return {
    scopes,
    hasScope,
    isRole,
  };
};
