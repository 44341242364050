import { isCPF } from 'brazilian-values';
import { z } from 'zod';

const nameSchema = z.string({ message: 'Campo obrigatório' }).min(2, { message: 'Campo obrigatório' });
const emailSchema = z.string().email({ message: 'Email inválido' });
const documentSchema = z.string().refine(isCPF, { message: 'CPF inválido' });

const userSchema = z.object({
  name: nameSchema,
  email: emailSchema,
  document: documentSchema,
});

export default userSchema;
