import { ReactElement } from 'react';

import { Screen } from './cards.styles';
import { CardsListPage } from './cards-list/cards-list';
import { NewCardsPage } from './new-cards/new-cards';
import { CardsSteps } from './types';

interface ICard {
  step: CardsSteps;
  onChangeStep: (step: CardsSteps) => void;
}

export const CardsLayout = ({ step, onChangeStep }: ICard): ReactElement => {
  const renderCardsListPage = () => {
    return <CardsListPage onChangeStep={onChangeStep} />;
  };

  const renderNewCardsPage = () => {
    return <NewCardsPage onChangeStep={onChangeStep} />;
  };

  const renderContent = () => {
    const steps = {
      [CardsSteps.CARD_LIST]: renderCardsListPage(),
      [CardsSteps.NEW_CARDS]: renderNewCardsPage(),
    };

    return steps[step];
  };

  return <Screen>{renderContent()}</Screen>;
};
