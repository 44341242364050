import { UseQueryResult } from '@tanstack/react-query';

import { ListEmployeesResponseDto, SimplifiedEmployees } from '~/api/requests';

export interface ICollaboratorsSteps {
  onChangeStep: (step: CollaboratorsSteps, id?: string) => void;
  personId?: string;
}

export interface ICollaboratorProfile {
  onChangeStep: (step: CollaboratorsSteps, personId?: string) => void;
  personId: string;
  collaboratorPosition: {
    index: number;
    page: number;
  };
}

export interface ICollaboratorHookProps {
  onChangeStep: (
    step: CollaboratorsSteps,
    id?: string,
    collaboratorPosition?: {
      index: number;
      page: number;
    }
  ) => void;
  allCollaborators: SimplifiedEmployees[];
  updateCollaborators: (collaborators: SimplifiedEmployees[], page: number) => void;
  getCollaborators: UseQueryResult<ListEmployeesResponseDto, unknown>;
  page: number;
  updatePage: () => void;
  document: string;
  setDocument: (document: string) => void;
  documentError: string;
  setDocumentError: (error: string) => void;
  enabled: boolean;
  setEnabled: (enabled: boolean) => void;
  USERS_PER_PAGE: number;
}

export enum CollaboratorsSteps {
  VIEW = 'VIEW',
  PROFILE = 'PROFILE',
}
