import {
  Divider as BaseDivider,
  Skeleton as BaseSkeleton,
  SkeletonVariants,
} from '@vizir-banking/design-system/web';
import styled from 'styled-components';

export const Skeleton = styled(BaseSkeleton).attrs({
  variant: SkeletonVariants.custom,
})``;

export const InnerSkeleton = styled.div`
  width: 100%;
  height: 40px;
  border-radius: 8px;
`;

export const Divider = styled(BaseDivider)``;
